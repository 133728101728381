import { useEffect, useState } from 'react';
import axios from '../../../shared/custom-axios';
import { Dialog, Classes, Button, Callout, Code, H5, H4, Icon, Intent } from '@blueprintjs/core';

import { Account, ApiKeys, Bot, ExchangeType } from '../../../shared/interfaces/bot';
import { Link } from 'react-router-dom';
import React from 'react';

const showKeyValue = (value: string | null) => {
  return value === null ? '<missing_key>' : value;
};

interface Props {
  item: Account | null;
  bot: Bot | null | undefined;
}

export function TradingPairEditorApiKeysCheck({ bot, item }: Props) {
  const [apiKeys, setApiKeys] = useState<ApiKeys | null>(null);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleCheck = React.useCallback(() => {
    if (!item || !bot) {
      return;
    }

    setLoading(true);

    axios
      .get<ApiKeys>(`/api/accounts/${item.id}/check_api_keys?bot_id=${bot.id}`)
      .then((response) => {
        setApiKeys(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [item, bot]);

  useEffect(() => {
    handleCheck();
  }, [item, handleCheck]);

  if (!item || !apiKeys) {
    return <></>;
  }

  return (
    <>
      <span className="mr-1">
        {apiKeys?.is_present && (
          <Button
            intent={Intent.NONE}
            rightIcon={<Icon icon="small-tick" />}
            small={true}
            onClick={() => {
              handleCheck();
              setIsOpen(true);
            }}
          >
            {item.name}
          </Button>
        )}

        {!apiKeys?.is_present && (
          <Button
            intent={Intent.WARNING}
            icon={<Icon icon="warning-sign" />}
            small={true}
            onClick={() => {
              handleCheck();
              setIsOpen(true);
            }}
          >
            {item.name}
          </Button>
        )}
      </span>

      <Dialog
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          {loading && <H4 className="text-1xl">API keys checking...</H4>}

          {!loading && <H4 className="text-1xl">API keys check</H4>}

          {!loading && (
            <div className="mt-3">
              <p>
                <span>Bot: </span>
                <Link to={`/bots/${bot?.id}`}>
                  <span className="font-bold text-black">{bot?.name}</span>
                </Link>
              </p>

              <p>
                <span>Account: </span>
                <span className="font-bold">{item?.name}</span>
              </p>

              {apiKeys?.is_present && (
                <Callout intent="success" className="mt-5">
                  <H5>Looks good</H5>
                  <p>For those who configures the keys, please roughly check if the following (masked) keys are correct:</p>

                  {item.exchange_type === ExchangeType.CEX && (
                    <ul className="list-inside">
                      <li className="my-1">
                        <Code>{`${item.secret_identifier}_API_KEY=${showKeyValue(apiKeys.api_key)}`}</Code>
                      </li>

                      <li className="my-2">
                        <Code>{`${item.secret_identifier}_SECRET_KEY=${showKeyValue(apiKeys.secret_key)}`}</Code>
                      </li>

                      {item.main_exchange === 'okex' && (
                        <li className="my-2">
                          <Code>{`${item.secret_identifier}_PASSPHRASE=${showKeyValue(apiKeys.passphrase)}`}</Code>
                        </li>
                      )}
                    </ul>
                  )}
                  {item.exchange_type === ExchangeType.DEX && (
                    <ul className="list-inside">
                      <li className="my-1">
                        <Code>{`${item.secret_identifier}_PRIVATE_KEY=${showKeyValue(apiKeys.private_key)}`}</Code>
                      </li>
                    </ul>
                  )}
                </Callout>
              )}

              {!apiKeys.is_present && (
                <Callout intent="warning" className="mt-5">
                  <H5>Missing keys</H5>

                  <p>Some keys appear to be missing or empty, please make sure every following keys are presented:</p>

                  {item.exchange_type === ExchangeType.CEX && (
                    <ul className="list-inside">
                      <li className="my-1">
                        <Code>{`${item.secret_identifier}_API_KEY=${showKeyValue(apiKeys.api_key)}`}</Code>
                      </li>

                      <li className="my-2">
                        <Code>{`${item.secret_identifier}_SECRET_KEY=${showKeyValue(apiKeys.secret_key)}`}</Code>
                      </li>

                      {item.main_exchange === 'okex' && (
                        <li className="my-2">
                          <Code>{`${item.secret_identifier}_PASSPHRASE=${showKeyValue(apiKeys.passphrase)}`}</Code>
                        </li>
                      )}
                    </ul>
                  )}

                  {item.exchange_type === ExchangeType.DEX && (
                    <ul className="list-inside">
                      <li className="my-1">
                        <Code>{`${item.secret_identifier}_PRIVATE_KEY=${showKeyValue(apiKeys.private_key)}`}</Code>
                      </li>
                    </ul>
                  )}
                </Callout>
              )}
            </div>
          )}
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={() => {
                setIsOpen(false);
              }}
              small={true}
            >
              Close
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
