import {
  ControlGroup,
  HTMLSelect,
  ButtonGroup,
  Button,
  Intent,
  Classes,
  Menu,
  MenuItem,
  Position,
  Callout,
  Dialog,
  InputGroup,
  Icon,
  Alert,
} from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import axios from '../../../shared/custom-axios';

import {
  WickCatcher,
  WickCatcherExitSettings,
  WickCatcherMarketTrackingSettings,
  WickCatcherSettingsTemplate,
  WickCatcherSettingsTemplateListResponse,
  WickCatcherTierSettings,
} from '../../../shared/interfaces/bot';
import { AppToaster } from '../../../shared/app-toaster';
import { WickCatcherFormState } from './form';

interface SettingsTemplateFormState {
  id: number | undefined;
  name: string;
  market_tracking_settings: WickCatcherMarketTrackingSettings;
  exit_settings: WickCatcherExitSettings;
  overall_volume_tiers: WickCatcherTierSettings[];
  specific_volume_tiers: WickCatcherTierSettings[];
  overall_movement_tiers: WickCatcherTierSettings[];
  specific_movement_tiers: WickCatcherTierSettings[];
  bot_id: number | undefined;
}

interface Props {
  item: WickCatcher | undefined | null;
  wickCatcherFormData: WickCatcherFormState;
  onSelectTemplate: (template: WickCatcherSettingsTemplate | undefined) => void;
}

export function WickCatcherSettingsTemplateForm({ item, wickCatcherFormData, onSelectTemplate }: Props) {
  let [templates, setTemplates] = useState<WickCatcherSettingsTemplate[]>([]);
  let [selectedTemplate, setSelectedTemplate] = useState<WickCatcherSettingsTemplate | undefined>();
  let [isTemplateProcess, setIsTemplateProcessing] = useState(false);
  let [openNewTemplateDialog, setOpenNewTemplateDialog] = useState(false);
  let [openUpdateConfirmDialog, setOpenUpdateConfirmDialog] = useState(false);
  let [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  let [templateFormData, setTemplateFormData] = useState<SettingsTemplateFormState>({} as SettingsTemplateFormState);

  const handleClickSaveTemplate = () => {
    if (selectedTemplate) return setOpenUpdateConfirmDialog(true);

    setOpenNewTemplateDialog(true);
  };

  const handleSelectTemplate = (e: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    let templateId = e.currentTarget.value !== '' && Number(e.currentTarget.value);
    let template = templates.find((t) => t.id === templateId);

    setSelectedTemplate(template);

    if (template) {
      onSelectTemplate(template);
    }

    setTemplateFormData((prevState) => {
      return {
        ...prevState,
        id: template?.id,
      };
    });
  };

  const handleCreateTemplate = () => {
    setIsTemplateProcessing(true);

    const formData = {
      id: undefined,
      name: templateFormData.name,
      market_tracking_settings: wickCatcherFormData.market_tracking_settings,
      exit_settings: wickCatcherFormData.exit_settings,
      overall_volume_tiers: wickCatcherFormData.overall_volume_tiers,
      specific_volume_tiers: wickCatcherFormData.specific_volume_tiers,
      overall_movement_tiers: wickCatcherFormData.overall_movement_tiers,
      specific_movement_tiers: wickCatcherFormData.specific_movement_tiers,
    };

    axios
      .post<WickCatcher>(`/api/wick_catcher/settings_templates`, { settings_template: formData })
      .then((res: any) => {
        let newTemplate: WickCatcherSettingsTemplate = res.data.data;
        setOpenNewTemplateDialog(false);

        setSelectedTemplate(newTemplate);

        setTemplateFormData((prevState) => {
          return {
            ...prevState,
            id: newTemplate.id,
            name: '',
          };
        });

        const message = 'Template created successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      })
      .finally(() => {
        setIsTemplateProcessing(false);
        reloadTemplates();
      });
  };

  const handleUpdateTemplate = () => {
    setIsTemplateProcessing(true);

    if (!selectedTemplate) {
      const message = 'No template is selected yet';
      AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });

      return;
    }

    const formData = {
      id: selectedTemplate.id,
      name: selectedTemplate.name,
      market_tracking_settings: wickCatcherFormData.market_tracking_settings,
      exit_settings: wickCatcherFormData.exit_settings,
      overall_volume_tiers: wickCatcherFormData.overall_volume_tiers,
      specific_volume_tiers: wickCatcherFormData.specific_volume_tiers,
      overall_movement_tiers: wickCatcherFormData.overall_movement_tiers,
      specific_movement_tiers: wickCatcherFormData.specific_movement_tiers,
    };

    axios
      .put<WickCatcher>(`/api/wick_catcher/settings_templates/${formData.id}`, { settings_template: formData })
      .then((res) => {
        setOpenUpdateConfirmDialog(false);
        const message = 'Template updated successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 3000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3000 });
      })
      .finally(() => {
        setIsTemplateProcessing(false);
        reloadTemplates();
      });
  };

  const handleDeleteTemplate = () => {
    setIsTemplateProcessing(true);

    if (!selectedTemplate) {
      const message = 'No template is selected yet';
      AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });

      return;
    }

    const formData = {
      id: selectedTemplate.id,
    };

    axios
      .delete<WickCatcher>(`/api/wick_catcher/settings_templates/${formData.id}`)
      .then((res) => {
        setOpenDeleteConfirmDialog(false);

        setSelectedTemplate(undefined);

        const message = 'Template deleted!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 3000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3000 });
      })
      .finally(() => {
        setIsTemplateProcessing(false);
        reloadTemplates();
      });
  };

  const reloadTemplates = useCallback(() => {
    axios.get<WickCatcherSettingsTemplateListResponse>('/api/wick_catcher/settings_templates').then((response) => {
      console.log(response, 'response');
      setTemplates(response.data.data);
    });
  }, []);

  useEffect(() => {
    reloadTemplates();
  }, []);

  return (
    <>
      <label className="font-medium">Load settings from a template</label>

      <ControlGroup fill={true} className="mt-2 block">
        <HTMLSelect
          disabled={isRunning(item)}
          id="selected_template"
          name="selected_template"
          value={selectedTemplate?.id}
          onChange={handleSelectTemplate}
        >
          <option>Choose an template...</option>

          {templates.map(({ id, name, user, inserted_at }) => (
            <option value={id} key={id}>
              {name} | {inserted_at} | {user.name}
            </option>
          ))}
        </HTMLSelect>

        <ButtonGroup>
          <Button loading={isTemplateProcess} icon="floppy-disk" intent={Intent.NONE} onClick={() => handleClickSaveTemplate()}>
            Save template
          </Button>

          <Popover2
            popoverClassName={Classes.POPOVER_CONTENT_SIZING}
            enforceFocus={false}
            content={
              <Menu key="menu" large={false}>
                <MenuItem
                  disabled={!selectedTemplate}
                  className="font-semibold"
                  intent={Intent.DANGER}
                  onClick={() => setOpenDeleteConfirmDialog(true)}
                  text="Delete selected template"
                />
              </Menu>
            }
            position={Position.BOTTOM}
          >
            <Button intent={Intent.NONE} outlined={true} rightIcon="double-caret-vertical" />
          </Popover2>
        </ButtonGroup>
      </ControlGroup>

      <Callout intent={Intent.PRIMARY} className="mt-2">
        This only helps you to quickly fill out the settings below (and on <strong>Exit Setting</strong> tab). It is still required to click
        Create/Update button down below for the settings to be saved.
      </Callout>

      <Dialog
        portalContainer={document.getElementById('app') || undefined}
        title="New template"
        transitionDuration={500}
        isOpen={openNewTemplateDialog}
        onClose={() => {
          setOpenNewTemplateDialog(false);
        }}
        style={{ width: 500 }}
      >
        <div className={Classes.DIALOG_BODY}>
          <InputGroup
            disabled={isRunning(item)}
            leftElement={<Icon icon="document" />}
            onChange={(e) => {
              setTemplateFormData((prevState) => {
                return { ...prevState, name: e.currentTarget.value };
              });
            }}
            placeholder="Template name"
            value={templateFormData.name}
          />
        </div>

        <div className={`${Classes.DIALOG_FOOTER} text-right`}>
          <Button loading={isTemplateProcess} onClick={() => handleCreateTemplate()} intent={Intent.PRIMARY}>
            Create
          </Button>
        </div>
      </Dialog>

      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Yes, overwrite"
        icon="manually-entered-data"
        intent={Intent.WARNING}
        isOpen={openUpdateConfirmDialog}
        onCancel={() => {
          setOpenUpdateConfirmDialog(false);
        }}
        onConfirm={() => handleUpdateTemplate()}
        canOutsideClickCancel={true}
        canEscapeKeyCancel={true}
      >
        <p>
          This will overwrite following template: {/* <span className="font-bold whitespace-nowrap">"{selectedTemplate?.name}".</span> */}
        </p>

        <p className="font-bold whitespace-nowrap text-lg">{selectedTemplate?.name}</p>

        <p>Are you sure?</p>
      </Alert>

      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Yes, delete it"
        icon="manually-entered-data"
        intent={Intent.WARNING}
        isOpen={openDeleteConfirmDialog}
        onCancel={() => {
          setOpenDeleteConfirmDialog(false);
        }}
        onConfirm={() => handleDeleteTemplate()}
        canOutsideClickCancel={true}
        canEscapeKeyCancel={true}
      >
        <p>Are you sure to delete following template:</p>

        <p className="font-bold whitespace-nowrap text-lg">{selectedTemplate?.name}</p>
      </Alert>
    </>
  );
}

const isRunning = (wickCatcher: WickCatcher | null | undefined): boolean => {
  if (!wickCatcher) {
    return false;
  }
  return wickCatcher.long_mode !== 'stopped' || wickCatcher.short_mode !== 'stopped';
};
