import React from 'react';

import { ActiveOrder } from '../../../shared/interfaces/bot';
import { Classes, Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { copyToClipboard } from '../../../shared/utils';
import moment from 'moment';

interface Props {
  activeOrders: ActiveOrder[];
  children: any;
}

const DAY_IN_SECOND = 86400;
const HOUR_IN_SECOND = 3600;

export function DetailsPopover({ activeOrders, children }: Props) {
  const formattedDatetime = (datetime: string) => {
    return moment(datetime).add(8, 'hours').format('YYYY-MM-DD HH:mm:ss');
  };

  const getOrderAge = (datetime: string) => {
    const now_str = moment.utc().format('YYYY-MM-DD HH:mm:ss');
    const start_time = moment(datetime);
    const end_time = moment(now_str);
    const duration = moment.duration(end_time.diff(start_time));
    const durationInSecond = duration.asSeconds();
    if (durationInSecond > DAY_IN_SECOND) {
      return `${duration.asDays().toFixed(1)} days`;
    } else if (durationInSecond > HOUR_IN_SECOND) {
      return `${duration.asHours().toFixed(1)}h`;
    } else if (durationInSecond > 60) {
      return `${duration.asMinutes().toFixed(1)}m`;
    } else {
      return `${durationInSecond.toFixed(1)}s`;
    }
  };

  return (
    <>
      <Popover2
        interactionKind="hover"
        popoverClassName={Classes.POPOVER2_CONTENT_SIZING + ' active-order-details'}
        placement="bottom"
        hoverOpenDelay={50}
        content={
          <div className="overflow-auto" onDoubleClick={(e) => e.stopPropagation()}>
            <table className="bp4-html-table scroll overflow-scroll" style={{ fontSize: '13px' }}>
              <thead>
                <tr>
                  <th>Internal ID</th>
                  <th>External ID</th>
                  <th>Price</th>
                  <th>Volume</th>
                  <th>Total value</th>
                  <th>Submitted at</th>
                  <th>Last amendment</th>
                  <th>Order age</th>
                </tr>
              </thead>
              <tbody>
                {activeOrders.map((order) => (
                  <tr key={order.internal_id}>
                    <td>
                      <Tooltip2 content={order.internal_id} placement="top">
                        <span className="cursor-pointer" onClick={() => copyToClipboard(order.internal_id)}>
                          {order.internal_id?.substr(0, 6)}
                        </span>
                      </Tooltip2>
                    </td>
                    <td>
                      <Tooltip2 content={order.external_id} placement="top">
                        <span className="cursor-pointer" onClick={() => copyToClipboard(order.external_id)}>
                          {order.external_id?.substr(0, 6)}
                        </span>
                      </Tooltip2>
                    </td>
                    <td>
                      {order.price.amount}
                      {'\n'}({order.price.currency})
                    </td>
                    <td style={{ whiteSpace: 'pre-line' }}>
                      {order.volume.amount}
                      {'\n'}({order.volume.currency})
                    </td>
                    <td style={{ whiteSpace: 'pre-line' }}>
                      {order.total_value.amount}
                      {'\n'}({order.total_value.currency})
                    </td>
                    <td width={120}>{formattedDatetime(order.submission_time)}</td>
                    <td>{getOrderAge(order.last_submission_time)}</td>
                    <td>{getOrderAge(order.submission_time)}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={6}>
                    <span className="cursor-pointer font-bold" onClick={() => copyToClipboard(String(activeOrders[0].opportunity.id))}>
                      Total (Opp {activeOrders[0].opportunity.id})
                    </span>
                  </td>
                  <td className="font-bold">{activeOrders.length}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        }
      >
        {children}
      </Popover2>
    </>
  );
}
