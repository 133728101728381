import { createRef, useCallback, useContext, useEffect, useRef, useState } from 'react';
import axios from '../../../shared/custom-axios';
import _ from 'lodash';
import { Button, ButtonGroup, Card, Classes, Dialog, H5, H6, Intent, Position, Tag } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2/lib/esm/tooltip2';
import { Classes as Popover2Classes, Popover2 } from '@blueprintjs/popover2';
import { Channel } from 'phoenix';
import { useSearchParams } from 'react-router-dom';

import socket from '../../../socket';
import { AppToaster } from '../../../shared/app-toaster';
import { ActiveOrder, OpportunitiesListResponse, OrderBooksListResponse, TradingPair } from '../../../shared/interfaces/bot';
import { ActiveOrderContext } from '../../../contexts/active-order';
import { BotsManagerContext } from '../../../contexts/bots-manager';
import { TimezoneContext } from '../../../contexts/timezone';
import styles from './list.module.css';

import { OpportunitiesList } from '../../opportunity/list/list';
import { TradingPairEditDialog } from '../edit-dialog/edit-dialog';
import { TradingPairEditor } from '../editor/editor';
import { TradingPairAction } from '../action/action';
import orderService from '../../../shared/order-service';
import { RateLimitInfo } from '../rate-limit/rate-limit';
import { WickCatcherFundingJobs } from '../../wick-catcher/funding-job/funding-job';

interface TradingPairResponse {
  data: TradingPair;
}

export function TradingPairsList({ items, setItems, type, jumpingPair, setJumpingPair }: any) {
  const { currentBot } = useContext(BotsManagerContext);
  const { activeOrders } = useContext(ActiveOrderContext);
  const { dateTimeFormater } = useContext(TimezoneContext);
  const [activeOrdersByPair, setActiveOrdersByPair] = useState<{ [key: string]: Array<ActiveOrder> }>({});
  const [selectedItem, setSelectedItem] = useState<TradingPair | null>(null);
  const [lastSelectedItem, setLastSelectedItem] = useState<TradingPair | null>(null);
  const [jumpedItem, setJumpedItem] = useState<number | null>(null);
  const [recentlyViewedPair, setRecentlyViewedPair] = useState<number | null>(null);
  const [editingItem, setEditingItem] = useState<TradingPair | null>(null);
  const [elRefs, setElRefs] = useState<any>(Array.from({ length: items.length }, (_, i) => createRef()));
  const [searchParams, setSearchParams] = useSearchParams();
  const [processingPairs, setProcessingPairs] = useState<Set<string>>(new Set());
  const [selectedPairForFunding, setSelectedPairForFunding] = useState<TradingPair | null>(null);

  let tradingPairChannel = useRef<Channel | null>(null);

  useEffect(() => {
    const mappings = activeOrders.reduce((acc: { [key: string]: Array<ActiveOrder> }, order: ActiveOrder) => {
      const currentOrders = acc[order.trading_pair.id];

      if (currentOrders && currentOrders.length > 0) {
        acc[order.trading_pair.id] = [...currentOrders, order];
      } else {
        acc[order.trading_pair.id] = [order];
      }

      return acc;
    }, {});

    setActiveOrdersByPair(mappings);
  }, [activeOrders]);

  useEffect(() => {
    // add or remove refs
    setElRefs((elRefs: any[]) => Array.from({ length: items.length }, (_, i) => elRefs[i] || createRef()));
  }, [items]);

  useEffect(() => {
    const pairId = searchParams.get('pair_id');
    if (pairId && recentlyViewedPair !== Number(pairId)) {
      setTimeout(() => {
        handleViewTradingPair(Number(pairId));
      }, 100);
    }
  }, [elRefs, searchParams]);

  useEffect(() => {
    if (jumpingPair) {
      const index = items.findIndex((item: TradingPair) => item.id === Number(jumpingPair));
      if (index >= 0) {
        handleScroll(index);
        setJumpingPair(null);
        setJumpedItem(jumpingPair);
      }
    }
  }, [jumpingPair]);

  const handleViewTradingPair = (tradingPairId: number) => {
    const index = items.findIndex((item: TradingPair) => item.id === tradingPairId);
    if (index >= 0) {
      handleScroll(index);
      setRecentlyViewedPair(tradingPairId);
    }
  };

  useEffect(() => {
    tradingPairChannel.current = socket.channel(`trading_pair:lobby`, {});

    tradingPairChannel.current
      ?.join()
      .receive('ok', () => {
        console.log(`[TradingPair] Joined "trading_pair:lobby" channel for trading pairs real-time updates`);
      })
      .receive('error', (resp: any) => {
        console.log('Unable to join', resp);
      });

    // Reload data with updates from WebSocket
    tradingPairChannel.current?.on('trading_pair:update', ({ data }) => {
      data.id && handleReloadItem(data.id);
    });

    tradingPairChannel.current?.onClose(() => {
      console.log(`[WickCatcher] Left "trading_pair:lobby" channel`);
    });

    return () => {
      tradingPairChannel.current?.leave();
    };
  }, []);

  const handleReloadItem = useCallback(
    (tradingPairId: number) => {
      return axios
        .get<TradingPairResponse>(`/api/trading_pairs/${tradingPairId}`)
        .then((response) => {
          const newData = response.data.data;

          setItems((prevState: TradingPair[]) => {
            return prevState.map((tradingPair) => {
              if (tradingPair.id === newData.id) {
                return { ...tradingPair, ...newData };
              } else {
                return tradingPair;
              }
            });
          });

          if (selectedItem && selectedItem.id === newData.id) {
            setSelectedItem({ ...selectedItem, ...newData });
          }

          orderService.removeOpportunityExceedVolumeThreshold(newData.opportunities.map((oppo) => oppo.id));
        })
        .catch((error) => {
          const message = JSON.stringify(error.response.data);
          AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
        });
    },
    [setItems, selectedItem],
  );

  const handleDelete = (tradingPairId: number) => {
    axios
      .delete<OrderBooksListResponse>(`/api/trading_pairs/${tradingPairId}`)
      .then(() => {
        setItems((prevState: TradingPair[]) => {
          const newState = prevState.filter(({ id }) => id !== tradingPairId);
          return newState;
        });

        orderService.onUpdateActiveTradingPairs(tradingPairId);

        const message = 'Trading Pair deleted successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const handleClone = (tradingPairId: number) => {
    axios
      .post<TradingPairResponse>(`/api/trading_pairs/${tradingPairId}/clone`)
      .then((response) => {
        setItems((prevState: TradingPair[]) => {
          const newData = response.data.data;

          return [...prevState, newData];
        });

        const message = 'Trading Pair duplicated successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const handleStart = (tradingPairId: number, mode: object) => {
    const side = mode.hasOwnProperty('long_mode') ? 'long' : mode.hasOwnProperty('short_mode') ? 'short' : '';
    addProcessingPair(tradingPairId, side);
    axios
      .put<TradingPairResponse>(`/api/trading_pairs/${tradingPairId}/start`, mode)
      .then((response) => {
        const newData = response.data.data;

        setItems((prevState: TradingPair[]) => {
          return prevState.map((tradingPair) => {
            if (tradingPair.id === newData.id) {
              return { ...tradingPair, ...newData };
            } else {
              return tradingPair;
            }
          });
        });

        if (selectedItem && selectedItem.id === newData.id) {
          setSelectedItem({ ...selectedItem, ...newData });
        }

        orderService.onUpdateActiveTradingPairs(tradingPairId);

        const message = 'Trading Pair started';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const error_message = JSON.stringify(error.response.data);
        AppToaster.show({ message: error_message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 6000 });
      })
      .finally(() => removeProcessingPair(tradingPairId, side));
  };

  const handleStop = (tradingPairId: number, mode: object) => {
    const side = mode.hasOwnProperty('long_mode') ? 'long' : mode.hasOwnProperty('short_mode') ? 'short' : '';
    addProcessingPair(tradingPairId, side);
    axios
      .put<TradingPairResponse>(`/api/trading_pairs/${tradingPairId}/stop`, mode)
      .then((response) => {
        const newData = response.data.data;

        setItems((prevState: TradingPair[]) => {
          return prevState.map((tradingPair) => {
            if (tradingPair.id === newData.id) {
              return { ...tradingPair, ...newData };
            } else {
              return tradingPair;
            }
          });
        });

        if (selectedItem && selectedItem.id === newData.id) {
          setSelectedItem({ ...selectedItem, ...newData });
        }

        orderService.onUpdateActiveTradingPairs(tradingPairId);

        const message = 'Trading Pair stopped';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 6000 });
      })
      .finally(() => removeProcessingPair(tradingPairId, side));
  };

  const handleOpen = (tradingPair: TradingPair) => {
    setSelectedItem(tradingPair);
    setLastSelectedItem(tradingPair);
    window.history.replaceState({}, '', `/bots/${currentBot?.app_id}/trading_pairs/${tradingPair.id}`);
  };

  const handleClose = () => {
    setSelectedItem(null);
    window.history.replaceState({}, '', `/bots/${currentBot?.app_id}/trading_pairs`);
  };

  const handleEdit = (tradingPair: TradingPair) => {
    setEditingItem(tradingPair);
  };

  const handleOpportunitiesUpdate = (tradingPairId: number, payload: any) => {
    axios
      .post<OpportunitiesListResponse>(`/api/trading_pairs/${tradingPairId}/opportunities/update_all`, { opportunities: payload })
      .then(() => {
        handleReloadItem(tradingPairId);

        const message = 'Opportunities updated successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const handleCloseAction = (needToRefresh = false) => {
    setSelectedItem(null);
    if (needToRefresh) {
      window.history.replaceState({}, '', `/bots/${currentBot?.app_id}/trading_pairs`);
    }
  };

  const handleScroll = (index: number) => {
    if (elRefs && elRefs[index]) {
      elRefs[index].current.scrollIntoView(false);
    }
  };

  const addProcessingPair = (id: number, side: string) => {
    setProcessingPairs((prevState: Set<string>) => {
      let newSet = new Set(prevState);
      newSet.add(`${id}_${side}`);
      return newSet;
    });
  };

  const removeProcessingPair = (id: number, side: string) => {
    setProcessingPairs((prevState: Set<string>) => {
      let newSet = new Set(prevState);
      newSet.delete(`${id}_${side}`);
      return newSet;
    });
  };

  const isProcessing = (id: number, side: string) => {
    return processingPairs.has(`${id}_${side}`);
  };

  return (
    <>
      <div className="trading-pairs grid gap-10 grid-cols-2 mt-5">
        {items?.map((item: TradingPair, i: number) => {
          const shortActiveOrders = (activeOrdersByPair[item.id] || []).filter(({ opportunity }) => opportunity.side === 'short');
          const longActiveOrders = (activeOrdersByPair[item.id] || []).filter(({ opportunity }) => opportunity.side === 'long');
          let classNames = '';
          if (item.id === recentlyViewedPair || item.id === jumpedItem) {
            classNames = 'border border-blue-400';
          } else if (item === lastSelectedItem) {
            classNames = 'border border-gray-400';
          }

          let fundingIntervalInHours = item.primary_instrument.funding_interval / 60;

          return (
            <Card
              interactive={true}
              elevation={item === lastSelectedItem ? 2 : 1}
              key={item.id}
              onDoubleClick={(e) => {
                e.stopPropagation();
                handleOpen(item);
              }}
              className={classNames}
            >
              <div key={item.id} ref={elRefs[i]} className="trading-pair h-full flex flex-col">
                <div className="card-header relative">
                  <div className="mt-0.5 mb-5">
                    <h2 className="font-bold">{item.name}</h2>

                    {/* <span className="mt-2 block">
                      <Tag minimal={true} intent={Intent.NONE} className="text-center">{_.upperFirst(item.primary_instrument.main_exchange)} {_.upperFirst(item.primary_instrument.sub_exchange)} ({item.primary_instrument.symbol})</Tag>
                      <Icon icon="arrow-right" size={12} className="mx-1" />
                      <Tag minimal={true} intent={Intent.NONE} className="text-center">{_.upperFirst(item.secondary_instrument.main_exchange)} {_.upperFirst(item.secondary_instrument.sub_exchange)} ({item.secondary_instrument.symbol})</Tag>
                    </span> */}
                  </div>

                  <span className="absolute -top-5 -left-5">
                    <Tag minimal={true}>{item.id}</Tag>
                  </span>

                  {item.wick_catcher_id && (
                    <span className="absolute -top-5 -right-5">
                      <Tag minimal={true} intent={Intent.PRIMARY} className="ml-2">
                        Part of wick catcher #{item.wick_catcher_id}
                      </Tag>
                    </span>
                  )}

                  <span className="absolute top-0 right-0">
                    <TradingPairAction
                      tradingPair={item}
                      openPair={handleOpen}
                      editPair={handleEdit}
                      clonePair={handleClone}
                      deletePair={handleDelete}
                      onClose={handleCloseAction}
                    />
                  </span>
                </div>
                <div
                  className="card-body"
                  onDoubleClick={() => {
                    handleOpen(item);
                  }}
                >
                  <div className="grid gap-10 grid-cols-2">
                    <div className="col-span-1">
                      <p>
                        Active Orders: {shortActiveOrders.length} / {item.opportunities.filter(({ side }) => side === 'short').length}
                      </p>
                      <p>
                        Active Qty: {_.sum(shortActiveOrders.map((order) => order.total_value.amount)).toFixed(2)}{' '}
                        {shortActiveOrders[0] ? shortActiveOrders[0].total_value.currency : ''}
                      </p>
                    </div>

                    <div className="col-span-1">
                      <p>
                        Active Orders: {longActiveOrders.length} / {item.opportunities.filter(({ side }) => side === 'long').length}
                      </p>
                      <p>
                        Active Qty: {_.sum(longActiveOrders.map((order) => order.total_value.amount)).toFixed(2)}{' '}
                        {longActiveOrders[0] ? longActiveOrders[0].total_value.currency : ''}
                      </p>
                    </div>
                  </div>
                  <div className="mt-2">
                    <RateLimitInfo tradingPair={item} />
                  </div>
                  <div className="mt-2">
                    <ButtonGroup fill={true}>
                      {item.short_mode === 'stopped' && (
                        <Button
                          className="w-1/2"
                          large={false}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleStart(item.id, { short_mode: 'open_short' });
                          }}
                          loading={isProcessing(item.id, 'short')}
                          onDoubleClick={(e) => e.stopPropagation()}
                          intent={Intent.NONE}
                          text="Short"
                          disabled={!!item.wick_catcher_id}
                        />
                      )}

                      {item.short_mode === 'open_short' && (
                        <Button
                          className="w-1/2"
                          large={false}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleStop(item.id, { short_mode: 'stopped' });
                          }}
                          loading={isProcessing(item.id, 'short')}
                          onDoubleClick={(e) => e.stopPropagation()}
                          intent={Intent.DANGER}
                          text="Short"
                          disabled={!!item.wick_catcher_id}
                        />
                      )}

                      {item.long_mode === 'stopped' && (
                        <Button
                          className="w-1/2"
                          large={false}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleStart(item.id, { long_mode: 'open_long' });
                          }}
                          loading={isProcessing(item.id, 'long')}
                          onDoubleClick={(e) => e.stopPropagation()}
                          intent={Intent.NONE}
                          text="Long"
                          disabled={!!item.wick_catcher_id}
                        />
                      )}

                      {item.long_mode === 'open_long' && (
                        <Button
                          className="w-1/2"
                          large={false}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleStop(item.id, { long_mode: 'stopped' });
                          }}
                          loading={isProcessing(item.id, 'long')}
                          onDoubleClick={(e) => e.stopPropagation()}
                          intent={Intent.SUCCESS}
                          text="Long"
                          disabled={!!item.wick_catcher_id}
                        />
                      )}
                    </ButtonGroup>
                  </div>

                  <div
                    className="mt-5"
                    onDoubleClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div className="inline-block float-right">
                      <ButtonGroup className="ml-3">
                        {item.paused_at && (
                          <Popover2
                            interactionKind="hover"
                            popoverClassName={Popover2Classes.POPOVER2_CONTENT_SIZING}
                            enforceFocus={false}
                            content={
                              <div>
                                <H5>{item.paused_reason?.code}</H5>

                                <H6>Message</H6>
                                <p>{item.paused_reason?.message}</p>

                                <H6>Paused at</H6>
                                <p>{dateTimeFormater.format(new Date(item.paused_at + 'Z'))}</p>

                                {item.paused_reason?.action_needed && (
                                  <>
                                    <H6>Action Needed:</H6>
                                    <p>{item.paused_reason?.action_needed}</p>
                                  </>
                                )}
                              </div>
                            }
                            minimal={false}
                            position={Position.TOP}
                            hoverCloseDelay={1200}
                          >
                            <>
                              <Button icon="stop" small={true} intent={Intent.DANGER} outlined={true} active={true}>
                                PAUSED
                              </Button>
                            </>
                          </Popover2>
                        )}

                        {fundingIntervalInHours >= 8 && (
                          <Tooltip2 content="Funding interval" placement="top" hoverOpenDelay={420}>
                            <Button icon="social-media" small={true} outlined={true} onClick={() => setSelectedPairForFunding(item)}>
                              {fundingIntervalInHours} hours
                            </Button>
                          </Tooltip2>
                        )}

                        {fundingIntervalInHours < 8 && (
                          <Tooltip2 content="Funding interval" placement="top" hoverOpenDelay={420}>
                            <Button
                              icon="social-media"
                              small={true}
                              outlined={false}
                              intent={Intent.WARNING}
                              onClick={() => setSelectedPairForFunding(item)}
                            >
                              {fundingIntervalInHours} hours
                            </Button>
                          </Tooltip2>
                        )}
                      </ButtonGroup>
                    </div>

                    <div className="clear-both"></div>

                    <div className="mt-3">
                      <OpportunitiesList
                        tradingPair={item}
                        onUpdate={handleOpportunitiesUpdate}
                        onReloadPair={handleReloadItem}
                        minimal={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          );
        })}

        <Dialog
          portalContainer={document.getElementById('app') || undefined}
          title={`${selectedItem?.name}`}
          transitionDuration={500}
          isOpen={!!selectedItem}
          onClose={() => {
            handleClose();
          }}
          className={styles.dialog}
        >
          {selectedItem && (
            <TradingPairEditor
              tradingPair={selectedItem}
              onStart={handleStart}
              onStop={handleStop}
              onDelete={handleDelete}
              onReload={handleReloadItem}
            />
          )}
        </Dialog>

        <Dialog
          portalContainer={document.getElementById('app') || undefined}
          title={`#${selectedPairForFunding?.id} Scheduled jobs`}
          transitionDuration={500}
          isOpen={!!selectedPairForFunding}
          onClose={() => {
            setSelectedPairForFunding(null);
          }}
          style={{ width: '700px' }}
        >
          <div className={Classes.DIALOG_BODY}>
            {selectedPairForFunding && <WickCatcherFundingJobs pair={selectedPairForFunding}></WickCatcherFundingJobs>}
          </div>
        </Dialog>

        <TradingPairEditDialog item={editingItem} setItem={setEditingItem} onReloadItem={handleReloadItem} />
      </div>
    </>
  );
}
