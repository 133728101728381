import { Icon } from '@blueprintjs/core';

interface Props {
  active: boolean;
  iconSize?: number;
}

export function ConnectivityIndicator({ active, iconSize = 20 }: Props) {
  return (
    <>
      {active && (
        <span className="text-green-500">
          <Icon icon="dot" size={iconSize} />
        </span>
      )}

      {!active && (
        <span className="text-red-700">
          <Icon icon="dot" size={iconSize} />
        </span>
      )}
    </>
  );
}
