import { useEffect, useState } from 'react';
import _ from 'lodash';
import axios from '../../../shared/custom-axios';
import { Button, Classes, Code, H5, Icon, Intent, Popover, PopoverPosition, Tooltip } from '@blueprintjs/core';
import { Link, useNavigate } from 'react-router-dom';

import { AppToaster } from '../../../shared/app-toaster';
import { Account, AccountsListResponse, AccountType } from '../../../shared/interfaces/bot';

export function AccountList() {
  let navigate = useNavigate();
  const [accounts, setAccounts] = useState<Account[]>([]);

  useEffect(() => {
    axios.get<AccountsListResponse>('/api/accounts').then((response) => {
      setAccounts(response.data.data);
    });
  }, []);

  const handleDelete = (accountId: number) => {
    axios
      .delete<AccountsListResponse>(`/api/accounts/${accountId}`)
      .then(() => {
        setAccounts((prevState) => {
          const newState = prevState.filter(({ id }) => id !== accountId);
          return newState;
        });

        const message = 'Account deleted successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const handleEdit = (id: number) => {
    navigate(`/accounts/${id}/edit`);
  };

  return (
    <>
      <Link to={'/accounts/new'} className="mt-1 bp4-button">
        <Icon icon="add" />
        <div>New</div>
      </Link>

      <table className="mt-5 bp4-html-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Exchange Type</th>
            <th>Exchange</th>
            <th>Type</th>
            <th>Name</th>
            <th>
              <Tooltip
                className={Classes.TOOLTIP_INDICATOR}
                content={
                  <>
                    <em>Secret Identifier</em> in v1
                  </>
                }
              >
                ENV Name Prefix
              </Tooltip>
            </th>
            <th>Blockchain</th>
            <th>Wallet address</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {accounts.map((account) => {
            return (
              <tr key={account.id}>
                <td>{account.id}</td>
                <td>
                  <Code>{_.upperCase(account.exchange_type)}</Code>
                </td>
                <td>{_.upperFirst(account.main_exchange)}</td>
                <td>{account.type === AccountType.Main ? 'Main Account' : 'Sub-account'}</td>
                <td>{account.name}</td>
                <td>
                  <Code>{account.secret_identifier}</Code>
                </td>

                <td>{account.blockchain ? <Code>{account.blockchain}</Code> : ''}</td>
                <td>{account.address ? <Code>{account.address}</Code> : ''}</td>
                <td>
                  <Button
                    className="mr-5"
                    icon="edit"
                    minimal={true}
                    intent={Intent.PRIMARY}
                    small={true}
                    onClick={() => handleEdit(account.id)}
                  />

                  <Popover popoverClassName={Classes.POPOVER_CONTENT_SIZING} position={PopoverPosition.RIGHT}>
                    <Button intent={Intent.DANGER} icon="trash" minimal={true} small={true} />
                    <div key="text">
                      <H5>Confirm deletion</H5>
                      <p>Are you sure you want to delete this account?</p>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                        <Button className={Classes.POPOVER_DISMISS} style={{ marginRight: 10 }}>
                          Cancel
                        </Button>

                        <Button onClick={() => handleDelete(account.id)} intent={Intent.DANGER} className={Classes.POPOVER_DISMISS}>
                          Delete
                        </Button>
                      </div>
                    </div>
                  </Popover>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
