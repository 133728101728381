import { useCallback, useState } from 'react';
import axios from '../../../shared/custom-axios';
import { Dialog, Intent } from '@blueprintjs/core';

import { AppToaster } from '../../../shared/app-toaster';
import { OrderBooksListResponse, TradingPair } from '../../../shared/interfaces/bot';
import styles from './list.module.css';

import { TradingPairEditor } from '../editor/editor';
import orderService from '../../../shared/order-service';

interface TradingPairResponse {
  data: TradingPair;
}

interface Props {
  bot_id: string;
  id: number;
  name: string;
}

export function TradingPairLink({ id, name, bot_id }: Props) {
  const [item, setItem] = useState<TradingPair | null>();

  const handleReloadItem = useCallback((tradingPairId: number) => {
    axios
      .get<TradingPairResponse>(`/api/trading_pairs/${tradingPairId}`)
      .then((response) => {
        const newData = response.data.data;

        setItem(newData);
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, []);

  const handleDelete = (tradingPairId: number) => {
    axios
      .delete<OrderBooksListResponse>(`/api/trading_pairs/${tradingPairId}`)
      .then(() => {
        orderService.onUpdateActiveTradingPairs(tradingPairId);

        const message = 'Trading Pair deleted successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const handleStart = (tradingPairId: number, mode: object) => {
    axios
      .put<TradingPairResponse>(`/api/trading_pairs/${tradingPairId}/start`, mode)
      .then((response) => {
        const newData = response.data.data;

        if (item && item.id === newData.id) {
          setItem({ ...item, ...newData });
        }

        orderService.onUpdateActiveTradingPairs(tradingPairId);

        const message = 'Trading Pair started';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const error_message = JSON.stringify(error.response.data);
        AppToaster.show({ message: error_message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const handleStop = (tradingPairId: number, mode: object) => {
    axios
      .put<TradingPairResponse>(`/api/trading_pairs/${tradingPairId}/stop`, mode)
      .then((response) => {
        const newData = response.data.data;

        if (item && item.id === newData.id) {
          setItem({ ...item, ...newData });
        }

        orderService.onUpdateActiveTradingPairs(tradingPairId);

        const message = 'Trading Pair stopped';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const handleOpen = (tradingPairId: number) => {
    axios
      .get<TradingPairResponse>(`/api/trading_pairs/${tradingPairId}`)
      .then((response) => {
        const newData = response.data.data;

        setItem(newData);

        window.history.replaceState({}, '', `/bots/${bot_id}/trading_pairs/${tradingPairId}`);
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const handleClose = () => {
    setItem(null);
  };

  return (
    <>
      <div className="">
        <a
          href={`/bots/${bot_id}/trading_pairs/${id}`}
          onClick={(e) => {
            e.preventDefault();

            handleOpen(id);
          }}
          className="text-gray-800"
        >
          {name}{' '}
        </a>

        <Dialog
          portalContainer={document.getElementById('app') || undefined}
          title={`${item?.name}`}
          transitionDuration={500}
          isOpen={!!item}
          onClose={() => {
            handleClose();
          }}
          className={styles.dialog}
        >
          {item && (
            <TradingPairEditor
              tradingPair={item}
              onStart={handleStart}
              onStop={handleStop}
              onDelete={handleDelete}
              onReload={handleReloadItem}
            />
          )}
        </Dialog>
      </div>
    </>
  );
}
