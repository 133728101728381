import { Route, Routes } from 'react-router-dom';

import Navigation from '../../components/navigation/navigation';
import { Footer } from '../../components/footer/footer';
import { AccountEditPage } from '../../pages/accounts/edit';
import { AccountNewPage } from '../../pages/accounts/new';
import { AccountsIndexPage } from '../../pages/accounts';

export function AccountsRoutes() {
  return (
    <>
      <div className="App-header">
        <Navigation />
      </div>

      <div className="App-body h-full">
        <Routes>
          <Route path="/new" element={<AccountNewPage />} />
          <Route path=":id/edit" element={<AccountEditPage />} />
          <Route path="/" element={<AccountsIndexPage />} />
        </Routes>
      </div>

      <div className="App-footer fixed z-100 bottom-0 left-0 right-0 text-sm">
        <Footer />
      </div>
    </>
  );
}
