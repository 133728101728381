import { useEffect, useState } from 'react';
import { ExchangeType, Instrument, OrderBook, StrategyType } from '../../../../shared/interfaces/bot';
import _ from 'lodash';

interface Props {
  strategyType: StrategyType;
  primaryOrderBookId: string;
  secondaryOrderBookId: string;
  selectedPrimaryInstrument: Instrument | null;
  selectedSecondaryInstrument: Instrument | null;
  primaryOrderBooks: OrderBook[];
  secondaryOrderBooks: OrderBook[];
}

export function useFilterSupportedTradingCurrencies({
  strategyType,
  primaryOrderBookId,
  secondaryOrderBookId,
  selectedPrimaryInstrument,
  selectedSecondaryInstrument,
  primaryOrderBooks,
  secondaryOrderBooks,
}: Props) {
  const [supportedCurrencies, setSupportedCurrencies] = useState<string[]>([]);

  useEffect(() => {
    if (strategyType === StrategyType.NFTMarketMaking) {
      return setSupportedCurrencies(['USD']);
    }

    // For CEX vs CEX
    if (!primaryOrderBookId || !secondaryOrderBookId) return;

    if (selectedPrimaryInstrument?.exchange_type === ExchangeType.CEX && selectedSecondaryInstrument?.exchange_type === ExchangeType.CEX) {
      const selectedPrimaryOrderBook = primaryOrderBooks.find((orderBook) => orderBook.id.toString() === primaryOrderBookId);
      const selectedSecondaryOrderBook = secondaryOrderBooks.find((orderBook) => orderBook.id.toString() === secondaryOrderBookId);

      console.log(selectedPrimaryOrderBook?.supported_currencies, selectedSecondaryOrderBook?.supported_currencies);

      const intersectionCurrencies: string[] = _.intersection(
        selectedPrimaryOrderBook?.supported_currencies,
        selectedSecondaryOrderBook?.supported_currencies,
      );

      return setSupportedCurrencies(intersectionCurrencies);
    }

    if (selectedPrimaryInstrument?.exchange_type === ExchangeType.DEX && selectedSecondaryInstrument?.exchange_type === ExchangeType.CEX) {
      const selectedSecondaryOrderBook = secondaryOrderBooks.find((orderBook) => orderBook.id.toString() === secondaryOrderBookId);

      const intersectionCurrencies: string[] = _.intersection(
        [
          selectedPrimaryInstrument?.base_currency,
          selectedPrimaryInstrument?.backing_base_currency,
          selectedPrimaryInstrument?.quote_currency,
          selectedPrimaryInstrument?.backing_quote_currency,
        ],
        selectedSecondaryOrderBook?.supported_currencies,
      );

      return setSupportedCurrencies(intersectionCurrencies);
    }
  }, [
    strategyType,
    primaryOrderBookId,
    secondaryOrderBookId,
    primaryOrderBooks,
    secondaryOrderBooks,
    selectedPrimaryInstrument,
    selectedSecondaryInstrument,
  ]);

  return supportedCurrencies;
}
