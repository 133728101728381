import { Button, Intent } from '@blueprintjs/core';
import { useCallback, useState } from 'react';

import axios from '../../../shared/custom-axios';
import { AppToaster } from '../../../shared/app-toaster';

interface Props {
  botId: number;
}

export function BotStopButton({ botId }: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const handleRestart = useCallback(() => {
    setIsLoading(true);

    axios
      .post<any>(`/api/bots/${botId}/stop_all_pairs`, {})
      .then(() => {
        setIsLoading(false);

        const message = `All pairs stopped for selected bot`;
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        setIsLoading(false);

        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, []);

  return (
    <>
      <Button
        intent={Intent.WARNING}
        loading={isLoading}
        onClick={(e) => {
          e.stopPropagation();

          handleRestart();
        }}
      >
        Stop All
      </Button>
    </>
  );
}
