import { createContext } from 'react';
import { ExchangeType } from '../shared/interfaces/bot';

export interface ExchangeInfo {
  id: string;
  name: string;
  sub_exchanges: Array<SubExchangeInfo>;
  type: ExchangeType;
}

export interface SubExchangeInfo {
  id: string;
  name: string;
}

export const ExchangesInfoContext = createContext<ExchangeInfo[]>([]);
