import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Classes, Drawer, H5, Intent, Tab, Tabs } from '@blueprintjs/core';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from '../../../shared/custom-axios';
import { ActiveOrder, StrategyType, TradingPair } from '../../../shared/interfaces/bot';
import { getOrderAge } from '../../../shared/utils';
import { ActiveOrderContext } from '../../../contexts/active-order';
import { BotsManagerContext } from '../../../contexts/bots-manager';

interface Props {
  isOpen: boolean;
}

const sortByMostRecent = (p1: TradingPair, p2: TradingPair) => {
  return new Date(p1.last_active_at) > new Date(p2.last_active_at) ? -1 : 1;
};

export const ActiveSummary = forwardRef<any, Props>((props: Props, ref) => {
  const [activePairs, setActivePairs] = useState<TradingPair[]>([]);
  const [showActivePairSummary, setShowActivePairSummary] = useState<boolean>(props.isOpen);
  const { activeOrders } = useContext(ActiveOrderContext);
  const { currentBot } = useContext(BotsManagerContext);
  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    if (!currentBot) {
      return;
    }
    fetchActiveTradingPairList(currentBot?.id);
  }, [currentBot]);

  const sortActivePair = (activePairs: TradingPair[]) => {
    return activePairs.sort(sortByMostRecent);
  };

  const reload = () => {
    if (!currentBot) {
      return;
    }
    fetchActiveTradingPairList(currentBot?.id);
  };

  const fetchActiveTradingPairList = (botId: number) => {
    if (!botId) {
      return;
    }
    axios.get<any>(`/api/trading_pairs?bot_id=${botId}&status=active`).then((response) => {
      setActivePairs(sortActivePair(response.data.entries));
    });
  };

  const handleClick = (tradingPair: TradingPair) => {
    navigate(`/bots/${currentBot?.app_id}/trading_pairs/active?pair_id=${tradingPair?.id}`, { state: { from: location }, replace: true });
  };

  useImperativeHandle(ref, () => ({
    reload,
  }));

  return (
    <div id="active-summary">
      {!showActivePairSummary && (
        <>
          <div className="active-summary-collapsed">
            <Button className="bp4-elevation-3" intent={Intent.PRIMARY} icon="menu" onClick={() => setShowActivePairSummary(true)}></Button>
          </div>
        </>
      )}

      <Drawer
        title="Active Summary"
        size="420px"
        isOpen={showActivePairSummary}
        onClose={() => setShowActivePairSummary(false)}
        canOutsideClickClose={true}
        usePortal={true}
        hasBackdrop={false}
        style={{ top: '51px' }}
      >
        <div className={Classes.DRAWER_BODY}>
          <div className={Classes.DIALOG_BODY + ' active-summary'}>
            <Tabs animate={true} defaultSelectedTabId="active-pairs-summary" large={false} renderActiveTabPanelOnly={true} vertical={false}>
              <Tab
                id="active-pairs-summary"
                title="Pairs"
                tagContent={activePairs.length}
                panel={
                  <div className="list">
                    {activePairs.map((pair: TradingPair, i: number) => {
                      let classNames = '';
                      if (pair.short_mode === 'open_short' && pair.long_mode === 'open_long') {
                        classNames = 'long-and-short';
                      } else if (pair.short_mode === 'open_short') {
                        classNames = 'only-short';
                      } else if (pair.long_mode === 'open_long') {
                        classNames = 'only-long';
                      }
                      const activeOrdersByPair = activeOrders.filter((order) => order.trading_pair.id === pair.id);
                      const totalShort = activeOrdersByPair
                        .filter((o) => o.opportunity.side === 'short')
                        .reduce((acc, order) => {
                          return acc + order.total_value.amount;
                        }, 0);
                      const totalLong = activeOrdersByPair
                        .filter((o) => o.opportunity.side === 'long')
                        .reduce((acc, order) => {
                          return acc + order.total_value.amount;
                        }, 0);
                      return (
                        <div
                          className={classNames + ' active-pair-box'}
                          key={`active_pair_box_${pair.id}`}
                          onClick={() => handleClick(pair)}
                        >
                          <h5>
                            {pair.name} <span className="text-gray-500">#{pair.id}</span>
                          </h5>
                          <div className="flex flex-row justify-between mt-2">
                            <div>{totalShort}</div>
                            <div>{totalLong}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                }
              />

              <Tab
                id="active-entry-orders-summary"
                title="Entry Orders"
                tagContent={activeOrders.filter((order) => order.opportunity.direction !== 'exit').length}
                panel={
                  <div className="list">
                    {activeOrders
                      .filter((order) => order.opportunity.direction !== 'exit')
                      .map((order: ActiveOrder, index: number) => {
                        let wickCatcherText = '';
                        let pairText = '';

                        if (order.trading_pair.strategy_type === StrategyType.WickCatcher) {
                          wickCatcherText = `Wick Catcher #${order.trading_pair.wick_catcher_id}`;
                        }

                        pairText = `${order.trading_pair.name} #${order.trading_pair.id}`;

                        let title = `${order.trading_pair.primary_instrument.symbol}: ${order.total_value.amount} 
                            ${order.total_value.currency} @ ${order.price.amount} ${order.price.currency}`;

                        return (
                          <div className="mb-10 clear-both">
                            <H5>
                              {index + 1}. {title}
                            </H5>

                            <p>{wickCatcherText}</p>

                            <p>
                              Pair #{order.trading_pair.id}: {pairText}
                            </p>

                            <p className="float-left">
                              Quantity: {order.volume.amount} {order.volume.currency}
                            </p>
                            <p className="float-right">Age: {getOrderAge(order.submission_time)}</p>
                          </div>
                        );
                      })}
                  </div>
                }
              />

              <Tab
                id="active-exit-orders-summary"
                title="Exit Orders"
                tagContent={
                  activeOrders.filter(
                    (order) => (order.trading_pair.strategy_type === StrategyType.WickCatcher && order.opportunity.direction) === 'exit',
                  ).length
                }
                panel={
                  <div className="list">
                    {activeOrders
                      .filter(
                        (order) =>
                          (order.trading_pair.strategy_type === StrategyType.WickCatcher && order.opportunity.direction) === 'exit',
                      )
                      .map((order: ActiveOrder, index: number) => {
                        let wickCatcherText = '';
                        let pairText = '';

                        if (order.trading_pair.strategy_type === StrategyType.WickCatcher) {
                          wickCatcherText = `Wick Catcher #${order.trading_pair.wick_catcher_id}`;
                        }

                        pairText = `${order.trading_pair.name} #${order.trading_pair.id}`;

                        let title = `${order.trading_pair.primary_instrument.symbol}: ${order.total_value.amount} 
                            ${order.total_value.currency} @ ${order.price.amount} ${order.price.currency}`;

                        return (
                          <div className="mb-10 clear-both">
                            <H5>
                              {index + 1}. {title}
                            </H5>

                            <p>{wickCatcherText}</p>

                            <p>
                              Pair #{order.trading_pair.id}: {pairText}
                            </p>

                            <p className="float-left">
                              Quantity: {order.volume.amount} {order.volume.currency}
                            </p>
                            <p className="float-right">Age: {getOrderAge(order.submission_time)}</p>
                          </div>
                        );
                      })}
                  </div>
                }
              />
            </Tabs>
          </div>
        </div>
      </Drawer>
    </div>
  );
});
