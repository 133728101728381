import React from 'react';
import { NonIdealState, Icon } from '@blueprintjs/core';
import { ColumnInstance, usePagination, useSortBy, useTable, UseTableCellProps } from 'react-table';

import { EmergencyCancelOrder } from '../../../../shared/interfaces/bot';

import sharedStyles from '../../../../app/app.module.css';
import { getFirstSortBy } from '../../../../shared/utils';
import { Pagination } from '../../../common/pagination/pagination';

interface Props {
  columns: any;
  data: any;
  fetchData: (opts: any) => void;
  loading: boolean;
  totalEntries: number;
  getColumnProps: (column: any) => void;
  highlighedColumnIds: string[];
  showPartialData?: boolean;
  hoveredColumn: ColumnInstance<EmergencyCancelOrder> | null;
  pageCount: number;
}

export function EmergencyCancelsListLatestPaginatedTable({
  columns,
  data,
  fetchData,
  loading,
  totalEntries,
  getColumnProps,
  highlighedColumnIds,
  showPartialData = false,
  hoveredColumn,
  pageCount: controlledPageCount,
}: Props) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20 },
      manualSortBy: true,
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination,
  );

  React.useEffect(() => {
    const firstSortBy = getFirstSortBy(sortBy);

    fetchData({ pageIndex, pageSize, sortBy: firstSortBy });
  }, [fetchData, pageIndex, pageSize, sortBy]);

  return (
    <>
      {page.length <= 0 && (
        <div className="mt-5 w-full overflow-auto">
          <NonIdealState
            icon="folder-open"
            title="No items found"
            description="Please consider clicking Reload to see there is any new items"
          />
        </div>
      )}

      {page.length > 0 && (
        <div className={`mt-5 w-full ${showPartialData ? 'editor-custom-table' : 'overflow-auto'}`}>
          <table {...getTableProps()} className="border table-fixed text-xs">
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => {
                    const isHovered = hoveredColumn?.id === column.id;
                    const isHighlighted = highlighedColumnIds.includes(column.id);

                    return (
                      <th
                        {...column.getHeaderProps([getColumnProps(column), column.getSortByToggleProps()])}
                        className={`border px-4 py-1 text-light-blue-600 ${isHovered ? sharedStyles.highlighted : ''} ${
                          isHighlighted ? sharedStyles.highlighted : ''
                        }`}
                      >
                        {column.render('Header')}
                        <span className="block">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon icon="arrow-down" size={10} />
                            ) : (
                              <Icon icon="arrow-up" size={10} />
                            )
                          ) : (
                            ''
                          )}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            {!loading && (
              <tbody {...getTableBodyProps()}>
                {page.map((row: any, i: number) => {
                  prepareRow(row);

                  return (
                    <tr {...row.getRowProps()} className={sharedStyles.item}>
                      {row.cells.map((cell: UseTableCellProps<EmergencyCancelOrder>) => {
                        const shouldCancel =
                          (cell.column.id === 'ec_cancel_price' && cell.row.original.ec?.should_cancel) ||
                          (cell.column.id === 'offset_ec_cancel_price' && cell.row.original.offset_ec?.should_cancel) ||
                          (cell.column.id === 'bbo_ec_cancel_price' && cell.row.original.bbo_ec?.should_cancel);

                        const isHovered = hoveredColumn?.id === cell.column.id;
                        const isHighlighted = highlighedColumnIds.includes(cell.column.id);

                        return (
                          <td
                            {...cell.getCellProps()}
                            className={`border px-4 py-1 text-light-blue-600 ${isHovered ? sharedStyles.highlighted : ''} ${
                              shouldCancel ? 'text-red-600' : ''
                            } ${isHighlighted ? sharedStyles.highlighted : ''}`}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
          <div className="flex justify-center mt-5">
            <Pagination
              isLoading={false}
              totalEntries={totalEntries}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageLength={page.length}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPageSize={setPageSize}
              gotoPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
            />
          </div>
        </div>
      )}
    </>
  );
}
