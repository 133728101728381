import { useEffect } from 'react';
import { ExchangeType, Instrument, PrivateStream } from '../../../../shared/interfaces/bot';

import axios from '../../../../shared/custom-axios';
import { AppToaster } from '../../../../shared/app-toaster';
import { Intent } from '@blueprintjs/core';

interface Props {
  botId: number | undefined;
  instrumentId: string;
  accountId: string;
  loadedInstruments: Instrument[];
  loadedPrivateStreams: PrivateStream[];
  onAfterSelectDefault: (item: PrivateStream) => void;
  onAfterCreateDefault: () => void;
}

export function useDefaultPrivateStream({
  botId,
  instrumentId,
  accountId,
  loadedInstruments,
  loadedPrivateStreams,
  onAfterSelectDefault,
  onAfterCreateDefault,
}: Props) {
  useEffect(() => {
    if (!instrumentId || !accountId) {
      return;
    }

    if (loadedPrivateStreams.length > 0) {
      const defaultItem = loadedPrivateStreams.find((item) => item && item.is_default);

      if (defaultItem !== undefined) {
        return onAfterSelectDefault(defaultItem);
      }
    }

    // Create a new order book and set it as default when no default one is found

    const selectedInstrument = loadedInstruments.find(({ id }) => id === Number(instrumentId));

    const isPrivateStreamSupported =
      selectedInstrument?.exchange_type === ExchangeType.CEX ||
      (selectedInstrument?.exchange_type === ExchangeType.DEX && selectedInstrument.main_exchange === 'blur');

    if (!selectedInstrument || !isPrivateStreamSupported) {
      return;
    }

    const formData = {
      name: selectedInstrument?.symbol,
      description: '',
      main_exchange: selectedInstrument?.main_exchange,
      sub_exchange: selectedInstrument?.sub_exchange,
      instrument_id: instrumentId,
      account_id: accountId,
      is_default: true,
      bot_id: botId,
    };

    axios
      .post<PrivateStream[]>(`/api/private_streams`, { private_stream: formData })
      .then(() => {
        onAfterCreateDefault();
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, [loadedPrivateStreams]);
}
