import { useContext, useEffect, useState } from 'react';
import axios from '../../shared/custom-axios';
import { useMatch, useSearchParams } from 'react-router-dom';
import { Alignment, HTMLSelect, Icon, Intent, Navbar, NavbarGroup } from '@blueprintjs/core';

import { WickCatcher } from '../../shared/interfaces/bot';
import { BotsManagerContext } from '../../contexts/bots-manager';
import { WickCatchersList } from '../../components/wick-catcher/list/list';
import { usePageColumns as useLayoutColumns } from '../../components/common/use-layout-columns';
import { AppToaster } from '../../shared/app-toaster';

interface WickCatcherResponse {
  data: WickCatcher;
}

interface WickCatchersListResponse {
  data: WickCatcher[];
}

export const WickCatchersIndexPage = (props: any) => {
  const { currentBot } = useContext(BotsManagerContext);
  const [items, setItems] = useState<WickCatcher[]>([]);
  const match = useMatch(`/bots/${currentBot?.app_id}/wick_catchers/:subPage`);
  const subPage = match?.params.subPage || 'all';
  const [searchParams] = useSearchParams();
  const openingItemId = searchParams.get('editing_id');
  const reloadData = searchParams.get('reload');
  const [editingItem, setEditingItem] = useState<WickCatcher | null>(null);
  const [layoutColumns, setLayoutColumns] = useLayoutColumns('wick_catchers_page_columns');
  const supportedColumnOptions = [1, 2, 3, 4, 5];

  useEffect(() => {
    if (!currentBot) {
      return;
    }

    switch (subPage) {
      default: {
        axios.get<WickCatchersListResponse>(`/api/wick_catchers?bot_id=${currentBot?.id}`).then((response) => {
          setItems(response.data.data);
        });

        break;
      }
    }
  }, [reloadData, openingItemId, currentBot, subPage]);

  useEffect(() => {
    let wickCatcherId = openingItemId;

    if (openingItemId) {
      axios
        .get<WickCatcherResponse>(`/api/wick_catchers/${wickCatcherId}`)
        .then((response) => {
          const newData = response.data.data;

          setEditingItem(newData);
        })
        .catch((error) => {
          const message = JSON.stringify(error.response.data);
          AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
        });
    }
  }, [openingItemId]);

  if (!currentBot) {
    return <p>Current bot does not exist</p>;
  }

  return (
    <>
      <Navbar>
        <Navbar.Group>
          <Navbar.Heading className="font-bold">Wick Catchers:</Navbar.Heading>
        </Navbar.Group>

        <NavbarGroup align={Alignment.RIGHT}>
          <Icon icon="grid-view"></Icon>

          <HTMLSelect
            className="ml-3"
            name="exchange_type"
            value={layoutColumns}
            onChange={(e) => setLayoutColumns(+e.currentTarget.value)}
          >
            {supportedColumnOptions.map((columns) => {
              if (columns === 1) {
                return (
                  <option value={columns} key={columns}>
                    1 column
                  </option>
                );
              }

              return (
                <option value={columns} key={columns}>
                  {columns} columns
                </option>
              );
            })}
          </HTMLSelect>
        </NavbarGroup>
      </Navbar>

      <div className="mt-8 flex flex-row">
        <WickCatchersList
          items={items}
          setItems={setItems}
          editingItem={editingItem}
          setEditingItem={setEditingItem}
          layoutColumns={layoutColumns}
        />
      </div>
    </>
  );
};
