import { NonIdealState } from '@blueprintjs/core';
import { ColumnInstance, useTable, UseTableCellProps } from 'react-table';

import { EmergencyCancelOrder } from '../../../../shared/interfaces/bot';

import sharedStyles from '../../../../app/app.module.css';
interface Props {
  columns: any;
  data: any;
  showPartialData?: boolean;
  getColumnProps: (column: any) => void;
  highlighedColumnIds: string[];
  hoveredColumn: ColumnInstance<EmergencyCancelOrder> | null;
}

export function EmergencyCancelsListLatestTable({
  columns,
  data,
  showPartialData = false,
  getColumnProps,
  highlighedColumnIds,
  hoveredColumn,
}: Props) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <>
      {rows.length <= 0 && (
        <div className="mt-5 w-full overflow-auto">
          <NonIdealState
            icon="folder-open"
            title="No items found"
            description="If you keep this window open, as soon as there is one order got EC, it would be shown here immediately. For history querying, please go to History tab."
          />
        </div>
      )}

      {rows.length > 0 && <p className="mt-3">Showing {rows.length} orders</p>}

      {rows.length > 0 && (
        <div className={`mt-5 w-full ${showPartialData ? 'editor-custom-table' : ''}`}>
          <table {...getTableProps()} className="border table-fixed text-xs">
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => {
                    const isHovered = hoveredColumn?.id === column.id;
                    const isHighlighted = highlighedColumnIds.includes(column.id);

                    return (
                      <th
                        {...column.getHeaderProps([getColumnProps(column)])}
                        className={`border px-4 py-1 text-light-blue-600 ${isHovered ? sharedStyles.highlighted : ''} ${
                          isHighlighted ? sharedStyles.highlighted : ''
                        }`}
                      >
                        {column.render('Header')}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row: any, i: number) => {
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()} className={sharedStyles.item}>
                    {row.cells.map((cell: UseTableCellProps<EmergencyCancelOrder>) => {
                      const shouldCancel =
                        (cell.column.id === 'ec_cancel_price' && cell.row.original.ec?.should_cancel) ||
                        (cell.column.id === 'offset_ec_cancel_price' && cell.row.original.offset_ec?.should_cancel) ||
                        (cell.column.id === 'bbo_ec_cancel_price' && cell.row.original.bbo_ec?.should_cancel);

                      const isHovered = hoveredColumn?.id === cell.column.id;
                      const isHighlighted = highlighedColumnIds.includes(cell.column.id);

                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`border px-4 py-1 text-light-blue-600 ${isHovered ? sharedStyles.highlighted : ''} ${
                            shouldCancel ? 'text-red-600' : ''
                          } ${isHighlighted ? sharedStyles.highlighted : ''}`}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
