import _ from 'lodash';
import { Button, MenuItem } from '@blueprintjs/core';
import { ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select';

import { ContractStream, ConnectivityStatus } from '../../../shared/interfaces/bot';
import { useEffect, useState } from 'react';
import { ConnectivityIndicator } from '../../bot/connectivity-indicator';

interface Props {
  selectedItemId: number | string | undefined;
  items: ContractStream[];
  disabled?: boolean;
  handleSelect: (item: ContractStream) => void;
}

const InnerSelect = Select.ofType<ContractStream>();

const getLabel = (item: ContractStream) => {
  let baseText, additionalText;
  let { instrument } = item;

  if (instrument) {
    baseText = `${instrument.symbol} - ${_.upperFirst(instrument.main_exchange)}`;

    additionalText = item.instrument.dex_network.network?.name;
  }

  return `${baseText}${additionalText ? ` (${additionalText})` : ''}`;
};

const renderItem: ItemRenderer<ContractStream> = (item, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      className="w-96 min-w-max"
      active={modifiers.active}
      key={item.name}
      onClick={handleClick}
      labelElement={<ConnectivityIndicator active={item.status === ConnectivityStatus.Started} />}
      text={
        <>
          {item.is_default && (
            <span className="font-bold block">
              {item.name} <span className="font-normal text-xs">(default)</span>
            </span>
          )}

          {!item.is_default && <span className="font-bold block">{item.name}</span>}

          <span className="text-xs block">{getLabel(item)}</span>
        </>
      }
    ></MenuItem>
  );
};

const filterItem: ItemPredicate<ContractStream> = (query, item) => {
  const stringForSearch = [item.name, item.description, item.instrument?.base_currency, item.instrument?.quote_currency]
    .join(' ')
    .toLowerCase();

  return stringForSearch.indexOf(query.toLowerCase()) >= 0;
};

export function ContractStreamSelect({ selectedItemId, items, disabled = false, handleSelect }: Props) {
  const [selectedItem, setSelectedItem] = useState<ContractStream | null>();

  useEffect(() => {
    const selectedPrivateStream = items.find(({ id }) => id === Number(selectedItemId));

    if (selectedPrivateStream) {
      setSelectedItem(selectedPrivateStream);
    } else {
      setSelectedItem(null);
    }
  }, [selectedItemId, items]);

  return (
    <>
      <InnerSelect
        itemRenderer={renderItem}
        itemPredicate={filterItem}
        onItemSelect={handleSelect}
        activeItem={selectedItem}
        items={items}
        disabled={disabled}
      >
        <Button
          disabled={disabled}
          text={selectedItem ? selectedItem.name : 'Choose a contract stream'}
          rightIcon="double-caret-vertical"
        />
      </InnerSelect>
    </>
  );
}
