import { NonIdealState } from '@blueprintjs/core';
import { useTable, UseTableCellProps } from 'react-table';

import { TradeFeedHedgeOrder, TradeFeedHedgeUpdate, TradeFeedOrderFill } from '../../../../shared/interfaces/bot';

interface Props {
  columns: any;
  data: TradeFeedHedgeOrder[];
  rowProps: (row: any) => object;
  selectedItem?: TradeFeedHedgeOrder | null;
}

export function TradeFeedHedgeOrdersListTable({ columns, data, rowProps, selectedItem }: Props) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <>
      {rows.length <= 0 && (
        <div className="w-full overflow-auto">
          <NonIdealState
            icon="folder-open"
            title="No items found"
            description="If you keep this window open, as soon as there is one order fill, it would be shown here immediately. For history querying, please go to History tab."
          />
        </div>
      )}

      {rows.length === 2 && <p>{rows.length} hedge order</p>}

      {rows.length > 2 && <p>{rows.length} hedge orders</p>}

      {rows.length > 0 && (
        <div className="w-full overflow-auto">
          <table {...getTableProps()} className="border table-fixed text-xs box-border">
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      {...column.getHeaderProps({
                        style: { minWidth: column.minWidth, maxWidth: column.maxWidth, width: column.width },
                      })}
                      className="border px-4 py-2 text-light-blue-600 box-border"
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="text-right">
              {rows.map((row: any, i: number) => {
                prepareRow(row);

                if (row.original === selectedItem) {
                  return (
                    <tr {...row.getRowProps(rowProps(row))} className="bg-gray-100">
                      {row.cells.map((cell: UseTableCellProps<TradeFeedOrderFill | TradeFeedHedgeUpdate>) => {
                        return (
                          <td {...cell.getCellProps()} className="border px-4 py-2 text-light-blue-600">
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                }

                return (
                  <tr {...row.getRowProps(rowProps(row))} className="hover:bg-gray-100">
                    {row.cells.map((cell: UseTableCellProps<TradeFeedOrderFill | TradeFeedHedgeUpdate>) => {
                      return (
                        <td {...cell.getCellProps()} className="border px-4 py-2 text-light-blue-600">
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
