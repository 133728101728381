import { useEffect, useState } from 'react';
import { ColumnInstance } from 'react-table';
import { EmergencyCancelOrder } from '../../../shared/interfaces/bot';

export function useEmergencyCancelHighlightedLatencyColumns(): [
  ColumnInstance<EmergencyCancelOrder> | null,
  (column: ColumnInstance<EmergencyCancelOrder> | null) => void,
  string[],
] {
  const [hoveredColumn, setHoveredColumn] = useState<ColumnInstance<EmergencyCancelOrder> | null>(null);
  const [highlighedColumnIds, setHighlightedColumnIds] = useState<string[]>([]);

  useEffect(() => {
    if (!hoveredColumn) {
      setHighlightedColumnIds([]);

      return;
    }

    switch (hoveredColumn.id) {
      case 'latency_pre_http': {
        setHighlightedColumnIds(['session_start_time', 'start_time']);

        break;
      }

      case 'latency_http_request': {
        setHighlightedColumnIds(['start_time', 'exchange_transaction_time']);

        break;
      }

      case 'latency_post_processed': {
        setHighlightedColumnIds(['exchange_transaction_time', 'exchange_event_time']);

        break;
      }

      case 'latency_http_response': {
        setHighlightedColumnIds(['exchange_event_time', 'end_time']);

        break;
      }

      case 'latency_http': {
        setHighlightedColumnIds(['start_time', 'end_time']);

        break;
      }

      case 'latency_response': {
        setHighlightedColumnIds(['exchange_transaction_time', 'end_time']);

        break;
      }

      case 'latency_ec': {
        setHighlightedColumnIds(['session_start_time', 'end_time']);

        break;
      }
    }
  }, [hoveredColumn]);

  return [hoveredColumn, setHoveredColumn, highlighedColumnIds];
}
