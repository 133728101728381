import { ColumnInstance } from 'react-table';

import { EmergencyCancelOrder } from '../../../../shared/interfaces/bot';
import { EmergencyCancelsListLatestTable } from './table';

interface Props {
  columns: any[];
  data: EmergencyCancelOrder[];
  highlighedColumnIds: string[];
  showPartialData?: boolean;
  hoveredColumn: ColumnInstance<EmergencyCancelOrder> | null;
  setHoveredColumn: (column: ColumnInstance<EmergencyCancelOrder> | null) => void;
}

export function EmergencyCancelsListLatest({
  columns,
  data,
  highlighedColumnIds,
  showPartialData = false,
  hoveredColumn,
  setHoveredColumn,
}: Props) {
  return (
    <>
      <EmergencyCancelsListLatestTable
        columns={columns}
        data={data}
        highlighedColumnIds={highlighedColumnIds}
        showPartialData={showPartialData}
        hoveredColumn={hoveredColumn}
        getColumnProps={(column) => ({
          onMouseOver: () => {
            setHoveredColumn(column);
          },
          onMouseLeave: () => {
            setHoveredColumn(null);
          },
        })}
      />
    </>
  );
}
