import { WebSocketMessage } from '../../../../shared/interfaces/bot';

import sharedStyles from '../../../../app/app.module.css';

interface Props {
  items: WebSocketMessage[];
  isLoading: boolean;
}

export function ContractStreamMessagesList({ items, isLoading }: Props) {
  const isUserData = (message: WebSocketMessage) => {
    return message.type === 'user_data';
  };

  const isPingPong = (message: WebSocketMessage) => {
    return message.type === 'ping' || message.type === 'pong';
  };

  return (
    <>
      {isLoading && (
        <div className="bp4-non-ideal-state mt-20">
          <div className="bp4-non-ideal-state-visual">
            <span className="bp4-icon bp4-icon-folder-open"></span>
          </div>
          <h4 className="bp4-heading">Loading...</h4>
        </div>
      )}

      {!isLoading && items.length <= 0 && (
        <div className="bp4-non-ideal-state mt-20">
          <div className="bp4-non-ideal-state-visual">
            <span className="bp4-icon bp4-icon-folder-open"></span>
          </div>
          <h4 className="bp4-heading">No messages received</h4>
        </div>
      )}

      {!isLoading && items.length > 0 && (
        <table className="w-full border text-xs mt-5">
          <tbody className="font-normal h-full overflow-y-scroll">
            {items.map((item: WebSocketMessage, index) => {
              return (
                <tr key={index} className={`cursor-pointer ${sharedStyles.item}`}>
                  <td className="w-1 px-2.5 py-3 text-left font-semibold text-red-500">
                    {item.direction === 'in' && (
                      <span className="bp4-tag bp4-intent-warning w-full">
                        <span className="w-full text-center">IN</span>
                      </span>
                    )}

                    {item.direction === 'out' && (
                      <span className="bp4-tag bp4-intent-primary w-full">
                        <span className="w-full text-center">OUT</span>
                      </span>
                    )}
                  </td>

                  {isUserData(item) && <td className="px-2.5 py-3">{item.payload}</td>}

                  {isPingPong(item) && <td className="px-2.5 py-3">{item.type}</td>}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
}
