import { FormGroup, InputGroup, Button } from '@blueprintjs/core';

import { User } from '../../../shared/interfaces/bot';

interface Props {
  user: User;
}

export function UserProfile(props: Props) {
  return (
    <>
      <FormGroup label="Name" labelFor="user-name">
        <InputGroup id="user-name" placeholder="Placeholder text" value={props.user.name} />
      </FormGroup>

      <FormGroup label="Display Name" labelFor="user-display-name">
        <InputGroup id="user-display-name" placeholder="Placeholder text" value={props.user.name} />
      </FormGroup>

      <FormGroup label="Title" labelFor="user-title">
        <InputGroup id="user-title" placeholder="E.g. Trader" value={props.user.title} />
      </FormGroup>

      <FormGroup label="Email" labelFor="user-email">
        <InputGroup id="user-email" placeholder="Placeholder text" value={props.user.email} disabled={true} />
      </FormGroup>

      <div className="mt-8 text-right">
        <Button icon="floppy-disk" intent="primary">
          Update Profile
        </Button>
      </div>
    </>
  );
}
