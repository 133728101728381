import { FormEvent, useContext, useEffect } from 'react';
import { FormGroup, Intent, Radio, RadioGroup } from '@blueprintjs/core';
import _ from 'lodash';

import { TimezoneOption } from '../../../shared/interfaces/bot';
import {
  BaliDateFormater,
  BaliTimeFormater,
  BaliDateTimeFormater,
  BrowserDateFormater,
  BrowserTimeFormater,
  BrowserDateTimeFormater,
  TimezoneContext,
  UtcDateFormater,
  UtcTimeFormater,
  UtcDateTimeFormater,
} from '../../../contexts/timezone';
import { AppToaster } from '../../../shared/app-toaster';

export interface Params {
  appId: string;
}

export function UserPreferences() {
  const { timezone, setTimezone, setDateFormater, setTimeFormater, setDateTimeFormater } = useContext(TimezoneContext);

  const handleTimezoneChange = (e: FormEvent<HTMLInputElement>) => {
    setTimezone && setTimezone((e.target as HTMLInputElement).value as TimezoneOption);

    const message = `Changed timezone to ${_.upperFirst(timezone)} time`;
    AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
  };

  useEffect(() => {
    switch (timezone) {
      case 'bali': {
        setDateFormater && setDateFormater(BaliDateFormater);
        setTimeFormater && setTimeFormater(BaliTimeFormater);
        setDateTimeFormater && setDateTimeFormater(BaliDateTimeFormater);

        break;
      }

      case 'utc': {
        setDateFormater && setDateFormater(UtcDateFormater);
        setTimeFormater && setTimeFormater(UtcTimeFormater);
        setDateTimeFormater && setDateTimeFormater(UtcDateTimeFormater);

        break;
      }

      case 'browser': {
        setDateFormater && setDateFormater(BrowserDateFormater);
        setTimeFormater && setTimeFormater(BrowserTimeFormater);
        setDateTimeFormater && setDateTimeFormater(BrowserDateTimeFormater);

        break;
      }
    }
  }, [timezone, setDateFormater, setTimeFormater]);

  return (
    <>
      <FormGroup label="Timezone" subLabel="Will apply to any datetime values on UI">
        <RadioGroup className="timezone-preferences" onChange={handleTimezoneChange} selectedValue={timezone}>
          <Radio label="Bali Time" value="bali" />
          <Radio label="UTC Time" value="utc" />
          <Radio label="Browser Time" value="browser" />
        </RadioGroup>
      </FormGroup>
    </>
  );
}
