import { useContext, useEffect, useState } from 'react';
import { Link, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Card, Dialog, H5, Icon, Intent, Tag } from '@blueprintjs/core';
import _ from 'lodash';
import axios from '../../../shared/custom-axios';

import { AppToaster } from '../../../shared/app-toaster';
import { StartStopButton } from '../../common/button/start_stop_button';
import { PrivateStream, PrivateStreamResponse, PrivateStreamsListResponse } from '../../../shared/interfaces/bot';
import { BotsManagerContext } from '../../../contexts/bots-manager';

import styles from './list.module.css';
import { PrivateStreamItem } from '../item/item';

export function PrivateStreamList() {
  const { currentBot } = useContext(BotsManagerContext);
  let navigate = useNavigate();

  const [privateStreams, setPrivateStreams] = useState<PrivateStream[]>([]);
  const [selectedPrivateStream, setSelectedPrivateStream] = useState<PrivateStream | null>(null);

  useEffect(() => {
    if (!currentBot) {
      return;
    }

    axios.get<PrivateStreamsListResponse>(`/api/private_streams?bot_id=${currentBot?.id}`).then((response) => {
      setPrivateStreams(response.data.data);
    });
  }, [currentBot]);

  const handleDelete = (id: number) => {
    axios
      .delete<PrivateStreamsListResponse>(`/api/private_streams/${id}`)
      .then(() => {
        setPrivateStreams((prevState) => {
          const newState = prevState.filter((privateStream) => privateStream.id !== id);
          return newState;
        });

        const message = 'Private stream deleted successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const handleStart = (id: number) => {
    axios
      .put<PrivateStreamResponse>(`/api/private_streams/${id}/start`)
      .then((response) => {
        const newData = response.data.data;

        setPrivateStreams((prevState) => {
          return prevState.map((privateStream) => {
            if (privateStream.id === newData.id) {
              return newData;
            } else {
              return privateStream;
            }
          });
        });

        const message = 'Private stream started';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const handleStop = (id: number) => {
    axios
      .put<PrivateStreamResponse>(`/api/private_streams/${id}/stop`)
      .then((response) => {
        const newData = response.data.data;

        setPrivateStreams((prevState) => {
          return prevState.map((privateStream) => {
            if (privateStream.id === newData.id) {
              return newData;
            } else {
              return privateStream;
            }
          });
        });

        const message = 'Private stream stopped';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const handleOpen = (item: PrivateStream) => {
    setSelectedPrivateStream(item);
    navigate(`${item.id}`);
  };

  return (
    <>
      <h2 className="text-lg font-bold my-1">Private Streams</h2>
      <Link to={`/bots/${currentBot?.app_id}/private_streams/new`} className="mt-1 bp4-button">
        <Icon icon="add" />
        <span>New</span>
      </Link>

      <div className="private-streams grid gap-10 grid-cols-4 mt-5">
        {privateStreams.map((privateStream) => {
          return (
            <Card interactive={true} elevation={2} key={privateStream.id} className="group">
              <div className="private-stream">
                <div
                  className="private-stream-header pt-3 relative"
                  onClick={() => {
                    handleOpen(privateStream);
                  }}
                >
                  <H5>
                    {privateStream.name}
                    <span className="text-gray-300 select-none"> #{privateStream.id}</span>
                  </H5>

                  {privateStream.is_default && (
                    <span className="absolute -top-5 -left-5">
                      <Tag minimal={true}>Default</Tag>
                    </span>
                  )}
                </div>
                <div
                  className="private-stream-body"
                  onClick={() => {
                    handleOpen(privateStream);
                  }}
                >
                  <p>{privateStream.description}</p>
                  <p>
                    Exchange: {_.upperFirst(privateStream.main_exchange)} {_.upperFirst(privateStream.sub_exchange)}
                  </p>
                  <p>Account: {privateStream.account.name}</p>
                </div>
                <div className="private-stream-footer text-right">
                  <StartStopButton item={privateStream} handleStart={handleStart} handleStop={handleStop} />
                </div>
              </div>
            </Card>
          );
        })}
      </div>

      <Routes>
        <Route
          path={`:id`}
          element={
            <PrivateStreamDialog
              item={selectedPrivateStream}
              setItem={setSelectedPrivateStream}
              onStart={handleStart}
              onStop={handleStop}
              onDelete={handleDelete}
            />
          }
        />
      </Routes>
    </>
  );
}

interface PrivateStreamDialogProps {
  item: PrivateStream | null;
  setItem: (item: PrivateStream | null) => void;
  onStart: (id: number) => void;
  onStop: (id: number) => void;
  onDelete: (id: number) => void;
}

const PrivateStreamDialog = ({ item, setItem, onStart, onStop, onDelete }: PrivateStreamDialogProps) => {
  let { id } = useParams<{ id: string }>();
  let navigate = useNavigate();

  useEffect(() => {
    axios
      .get<PrivateStreamResponse>(`/api/private_streams/${id}`)
      .then((response) => {
        const newData = response.data.data;

        setItem(newData);
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, []);

  const handleClose = () => {
    setItem(null);
    navigate(-1);
  };

  if (!id) {
    return <></>;
  }

  return (
    <>
      <Dialog
        portalContainer={document.getElementById('app') || undefined}
        title={`${item?.id}. ${item?.name}`}
        transitionDuration={500}
        isOpen={!!item}
        onClose={() => {
          handleClose();
        }}
        className={styles.dialog}
      >
        <PrivateStreamItem item={item} onStart={onStart} onStop={onStop} onDelete={onDelete} />
      </Dialog>
    </>
  );
};
