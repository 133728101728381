import { TradingPairForm } from '../../components/trading-pair/form/form';
import React from 'react';
import { Card } from '@blueprintjs/core';

export const TradingPairsNewPage = (props: any) => {
  return (
    <>
      <div className="p-5 flex justify-center items-center">
        <Card interactive={false} elevation={2} className="new-pair-form-container">
          <h2 className="text-xl font-bold text-center">New trading pair</h2>

          <TradingPairForm />
        </Card>
      </div>
    </>
  );
};
