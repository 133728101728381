import { Dialog, Classes, Button, Icon, Intent } from '@blueprintjs/core';
import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BotsManagerContext } from '../../../contexts/bots-manager';
import { WickCatcherForm } from '../form/form';
import { WickCatcher } from '../../../shared/interfaces/bot';

interface Props {
  isVisible: boolean;
  setVisible: (visible: boolean) => void;
}

export function WickCatcherCreateDialog({ isVisible, setVisible }: Props) {
  let navigate = useNavigate();
  const { currentBot } = useContext(BotsManagerContext);
  const [newItem, setNewItem] = useState<WickCatcher | null>(null);

  const afterCreateNewItem = useCallback(({ data }: any) => {
    setNewItem(data);
  }, []);

  const openNewWickCatcher = useCallback((item: WickCatcher) => {
    setVisible(false);

    // Go back to listing page
    const url = `/bots/${currentBot?.app_id}/wick_catchers?reload=${+new Date()}&editing_id=${item.id}`;
    navigate(url);
  }, []);

  const handleClose = useCallback(() => {
    setVisible(false);

    // Go back to listing page
    const url = `/bots/${currentBot?.app_id}/wick_catchers?reload=${+new Date()}`;
    navigate(url, { replace: true });
  }, []);

  return (
    <Dialog
      portalContainer={document.getElementById('app') || undefined}
      title="New Wick Catcher"
      transitionDuration={500}
      isOpen={isVisible}
      onClose={handleClose}
      style={{ minWidth: '1275px' }}
    >
      {newItem ? (
        <div className={Classes.DIALOG_BODY}>
          <div>
            <span>
              The wick catcher <strong>{newItem.name}</strong> has been created. <Icon icon="endorsed" intent={Intent.SUCCESS} />
            </span>
            <div className="mt-7 text-right">
              <Button onClick={handleClose}>Go to listing page</Button>

              <Button
                intent="primary"
                className="ml-3"
                onClick={() => {
                  openNewWickCatcher(newItem);
                }}
              >
                Open Wick Catcher
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className={Classes.DIALOG_BODY}>
          <WickCatcherForm afterCreatePair={afterCreateNewItem} />
        </div>
      )}
    </Dialog>
  );
}
