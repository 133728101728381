import { useCallback, useEffect, useState } from 'react';
import axios from '../../shared/custom-axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Intent } from '@blueprintjs/core';

import { Account, AccountResponse } from '../../shared/interfaces/bot';
import { AppToaster } from '../../shared/app-toaster';
import { AccountForm } from '../../components/account/form/form';

export const AccountEditPage = (props: any) => {
  let navigate = useNavigate();
  let { id } = useParams<{ id: string }>();

  const [item, setItem] = useState<Account | null>(null);

  useEffect(() => {
    axios
      .get<AccountResponse>(`/api/accounts/${id}`)
      .then((response) => {
        const newData = response.data.data;
        setItem(newData);
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, [id]);

  const handleCreate = useCallback((formState: object) => {
    axios
      .post<Account[]>('/api/accounts', { account: formState })
      .then(() => {
        navigate('/accounts');
        const message = 'Account created successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        let message;

        if (error.response.data.errors) {
          message = JSON.stringify(error.response.data.errors);
        } else {
          message = error.response.data.error_message;
        }

        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, []);

  const handleUpdate = useCallback((accountId: number, formState: object) => {
    axios
      .put<AccountResponse>(`/api/accounts/${accountId}`, { account: formState })
      .then((response) => {
        console.log(response, 'WHAT');
        // TODO: Consider redirect to details page of given account instead (details page is not yet implemented)
        navigate('/accounts');

        const message = 'Account updated';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        let message;

        if (error.response.data.errors) {
          message = JSON.stringify(error.response.data.errors);
        } else {
          message = error.response.data.error_message;
        }

        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, []);

  return (
    <>
      <h2 className="text-lg font-bold my-1">Edit account</h2>

      <AccountForm item={item} onCreate={handleCreate} onUpdate={handleUpdate} />
    </>
  );
};
