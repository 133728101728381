import { useContext, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Collapse, Pre, Tabs, Tab, Callout, Intent } from '@blueprintjs/core';

import { ActiveOrder, GeneratedOrder, Opportunity, OpportunityLogs, TradingPair } from '../../../shared/interfaces/bot';
import { OpportunitySessionContext } from '../../../contexts/opportunity-session';
import socket from '../../../socket';
import { Channel } from 'phoenix';

function LogsPanel({ items }: { items: OpportunityLogs[] }) {
  return (
    <div>
      <Collapse isOpen={true} keepChildrenMounted={true}>
        <Pre className="whitespace-pre-wrap h-72 overflow-auto">
          {items.map((log, index) => {
            if (index === 0) {
              return (
                <div key={`PulserLog_${index}`}>
                  [{log.timestampt}] trading_pair_id={log.trading_pair_id} opportunity_id={log.opportunity_id} opportunity_state=
                  {log.opportunity_session_state} pulser_action={log.pulser_action}
                  <br />
                  <br />
                  opportunity_session={JSON.stringify(log.opportunity_session)}
                  <br />
                  <br />
                  generated_order={JSON.stringify(log.generated_order)}
                  {log.primary_exit ? (
                    <>
                      <br />
                      <br />
                      <p className={log.primary_exit.side === 'long' ? 'text-green-500' : 'text-red-500'}>
                        primary_exit={JSON.stringify(log.primary_exit)}
                      </p>
                    </>
                  ) : null}
                </div>
              );
            }

            return (
              <div key={`PulserLog_${index}`}>
                <br />
                <br />
                ----------
                <br />
                <br />[{log.timestampt}] trading_pair_id={log.trading_pair_id} opportunity_id={log.opportunity_id} opportunity_state=
                {log.opportunity_session_state} pulser_action={log.pulser_action}
                <br />
                <br />
                opportunity_session={JSON.stringify(log.opportunity_session)}
                <br />
                <br />
                generated_order={JSON.stringify(log.generated_order)}
                {log.primary_exit ? (
                  <>
                    <br />
                    <br />
                    <p className={log.primary_exit.side === 'long' ? 'text-green-500' : 'text-red-500'}>
                      primary_exit={JSON.stringify(log.primary_exit)}
                    </p>
                  </>
                ) : null}
              </div>
            );
          })}
        </Pre>
      </Collapse>
    </div>
  );
}

interface Props {
  activeOrders?: ActiveOrder[];
  tradingPair: TradingPair;
}

export function TradingPairEditorPulserLogs({ tradingPair }: Props) {
  const { primary_instrument, opportunities } = tradingPair;
  const [sortedOpportunities, setSortedOpportunities] = useState<Array<Opportunity>>([]);
  const { opportunitySessions } = useContext(OpportunitySessionContext);
  const [generatedOrders, setGeneratedOrders] = useState<{ [key: number]: GeneratedOrder }>({});
  const [opportunityLogs, setOpportunityLogs] = useState<Array<OpportunityLogs>>([]);
  let tradingPairChannel = useRef<Channel | null>(null);

  useEffect(() => {
    // Sort opportunities by side and percentage
    let opportunities = _.orderBy(tradingPair.opportunities, ['side', 'percentage'], ['desc', 'desc']);

    setSortedOpportunities(opportunities);
    tradingPairChannel.current = socket.channel(`trading_pair:${tradingPair.id}`, { trading_pair_id: tradingPair.id, limit: 5 });

    tradingPairChannel.current
      ?.join()
      .receive('ok', () => {
        console.log(
          `[#${tradingPair.id} ${tradingPair.name}] Joined "trading_pair:${tradingPair.id}" channel for receiving trading pair updates`,
        );

        // Trigger server to update us the current status of opportunities' pulsers
        tradingPairChannel.current?.push('pulser:init', {});
      })
      .receive('error', (resp) => {
        console.log('Unable to join', resp);
      });

    // Process data from every run cycle of a pulser
    tradingPairChannel.current?.on('pulser:run', ({ data }) => {
      setOpportunityLogs((prevState) => {
        return [data, ...prevState];
      });

      const { opportunity_id: opportunityId, generated_order: generatedOrder } = data;

      setGeneratedOrders((prevState) => {
        return { ...prevState, [opportunityId]: generatedOrder };
      });
    });

    tradingPairChannel.current?.onClose(() => {
      console.log(`[#${tradingPair.id} ${tradingPair.name}] Left "trading_pair:${tradingPair.id}" channel`);
    });

    return () => {
      tradingPairChannel.current?.leave();
    };
  }, []);

  return (
    <>
      <Callout intent={Intent.NONE} title={undefined}>
        Note: The following UI layout is not final design.
      </Callout>

      <Tabs animate={true} renderActiveTabPanelOnly={false} vertical={true} className="mt-5 w-full">
        <Tab id="all" title="All" panel={<LogsPanel items={opportunityLogs} />} panelClassName="w-full" />

        {sortedOpportunities.map((opportunity) => {
          const logs = opportunityLogs.filter(({ opportunity_id }) => opportunity.id === opportunity_id);

          return (
            <Tab
              key={`opportunity-logs-${opportunity.id}`}
              id={`opportunity-${opportunity.id}`}
              title={`${opportunity.percentage}%`}
              panel={<LogsPanel items={logs} />}
              panelClassName="w-full"
            />
          );
        })}
      </Tabs>

      <h3 className="mt-3 text-base font-semibold">Last generated orders</h3>

      {opportunities.length > 0 && (
        <table className="border table-fixed">
          <thead>
            <tr>
              <th className="w-1/3 border px-4 py-2 text-light-blue-600">%</th>
              <th className="w-1/3 border px-4 py-2 text-light-blue-600">Wall Price ({primary_instrument.quote_currency})</th>
              <th className="w-1/3 border px-4 py-2 text-light-blue-600">Target Price ({primary_instrument.quote_currency})</th>
              <th className="w-1/3 border px-4 py-2 text-light-blue-600">Volume ({primary_instrument.volume_currency})</th>
              <th className="w-1/3 border px-4 py-2 text-light-blue-600">Total Value ({primary_instrument.quote_currency})</th>
              <th className="w-1/3 border px-4 py-2 text-light-blue-600">Pulser countdown (seconds)</th>
            </tr>
          </thead>
          <tbody>
            {sortedOpportunities
              .sort(({ percentage: a }, { percentage: b }) => b - a)
              .map(({ id, percentage, trading_pair_id }) => {
                const opportunitySession = opportunitySessions?.[id];
                const countDown = opportunitySession?.pulser_countdown;

                return (
                  <tr key={id}>
                    <td className="border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium">{percentage}</td>
                    <td className="border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium">
                      {generatedOrders[id]?.wall_price}
                    </td>
                    <td className="border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium">
                      {generatedOrders[id]?.price.amount}
                    </td>
                    <td className="border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium">
                      {generatedOrders[id]?.volume.amount}
                    </td>
                    <td className="border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium">
                      {generatedOrders[id]?.total_value.amount}
                    </td>
                    <td className="border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium">
                      {countDown ? Math.floor(countDown / 1000) : countDown}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}

      {/* <div className="grid grid-cols-6 gap-4">
            <div className="col-span-6">
              <h3 className="mt-3 text-base font-semibold">Active orders</h3>

              <table className="border table-fixed">
                <thead>
                  <tr>
                    <th className="w-1/3 border px-4 py-2 text-light-blue-600">%</th>
                    <th className="w-1/3 border px-4 py-2 text-light-blue-600">External ID</th>
                    <th className="w-1/3 border px-4 py-2 text-light-blue-600">Internal ID</th>
                    <th className="w-1/3 border px-4 py-2 text-light-blue-600">Volume</th>
                    <th className="w-1/3 border px-4 py-2 text-light-blue-600">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {activeOrders
                    .filter(({ trading_pair: { id } }) => id === tradingPair.id)
                    .sort(({ original: { price: a } }, { original: { price: b } }) => b.amount - a.amount)
                    .map(({ internal_id, external_id, original, opportunity }) => {
                      return (
                        <tr key={external_id}>
                          <td className="border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium">{opportunity?.percentage}</td>
                          <td className="border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium">{external_id}</td>
                          <td className="border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium">{internal_id}</td>
                          <td className="border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium">{original.volume.amount}</td>
                          <td className="border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium">{original.price.amount}</td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div> */}
    </>
  );
}
