import Navigation from '../../components/navigation/navigation';

export function HomePage() {
  return (
    <>
      <div className="App-header">
        <Navigation />
      </div>

      <div className="App-body h-full">
        <p>Home</p>
      </div>
    </>
  );
}
