import { InstrumentList } from '../../components/instrument/list/list';

export const InstrumentsIndexPage = (props: any) => {
  return (
    <>
      <h2 className="text-lg font-bold my-1">Instruments</h2>

      <InstrumentList />
    </>
  );
};
