import _ from 'lodash';
import { ContractType, ExchangeType, Instrument } from '../../shared/interfaces/bot';

const getBinanceTradingUrl = (item: Instrument) => {
  if (item.main_exchange !== 'binance') {
    return '';
  }

  if (item.sub_exchange === 'spot') {
    return `https://www.binance.com/en/trade/${item.base_currency}_${item.quote_currency}?type=spot`;
  }

  if (item.sub_exchange === 'margin') {
    return `https://www.binance.com/en/trade-margin/${item.base_currency}_${item.quote_currency}`;
  }

  if (item.sub_exchange === 'swap') {
    if (item.contract_type === ContractType.Linear) {
      return `https://www.binance.com/en/futures/${item.symbol}`;
    }

    if (item.contract_type === ContractType.Inverse) {
      return `https://www.binance.com/en/delivery/${item.base_currency.toLowerCase()}${item.quote_currency.toLowerCase()}_perpetual`;
    }
  }

  if (item.sub_exchange === 'futures') {
    if (item.contract_type === ContractType.Linear) {
      return `https://www.binance.com/en/futures/${item.base_currency.toLowerCase()}${item.quote_currency.toLowerCase()}_quarter`;
    }

    if (item.contract_type === ContractType.Inverse) {
      return `https://www.binance.com/en/delivery/${item.base_currency}${item.quote_currency}_quarter`;
    }
  }
};

const getOkexTradingUrl = (item: Instrument) => {
  if (item.main_exchange !== 'okex') {
    return '';
  }

  if (item.sub_exchange === 'spot') {
    return `https://www.okx.com/trade-spot/${item.symbol.toLowerCase()}`;
  }

  if (item.sub_exchange === 'margin') {
    return `https://www.okx.com/trade-margin/${item.symbol.toLowerCase()}`;
  }

  if (item.sub_exchange === 'swap') {
    return `https://www.okx.com/trade-swap/${item.symbol.toLowerCase()}`;
  }

  if (item.sub_exchange === 'futures') {
    return `https://www.okx.com/trade-futures/${item.symbol.toLowerCase()}`;
  }
};

const getBybitTradingUrl = (item: Instrument) => {
  if (item.main_exchange !== 'bybit') {
    return '';
  }

  switch (item.sub_exchange) {
    case 'spot':
    case 'margin':
      return `https://www.bybit.com/en-US/trade/spot/${item.base_currency}/${item.quote_currency}`;
    case 'swap':
      if (item.contract_type === 'linear') {
        return `https://www.bybit.com/trade/usdt/${item.symbol}`;
      } else if (item.contract_type === 'inverse') {
        return `https://www.bybit.com/trade/inverse/${item.symbol}`;
      } else {
        return '';
      }
    default:
      return '';
  }
};

// Get an URL to a symbol's trading page on exchanges
export const getTradingUrl = (item: Instrument) => {
  switch (item.main_exchange) {
    case 'binance':
      return getBinanceTradingUrl(item);
    case 'okex':
      return getOkexTradingUrl(item);
    case 'bybit':
      return getBybitTradingUrl(item);
    default:
      return '';
  }
};

export const getDepths = (instrument: Instrument) => {
  const tickSize = instrument.tick_size;
  const lastDigit = Number(tickSize.toString().slice(-1));

  if (lastDigit === 5) {
    return [tickSize, tickSize * 2, tickSize * 20, tickSize * 200, tickSize * 2000, tickSize * 20000, tickSize * 200000];
  } else {
    return [tickSize, tickSize * 10, tickSize * 100, tickSize * 1000, tickSize * 10000, tickSize * 100000];
  }
};

export const getSymbol = (item: Instrument) => {
  if (item.sub_exchange === 'futures') {
    // For Futures contract, show "weekly/bi_weekly/monthly/quarterly/bi_quarterly" for easier to follow
    // because barely seeing expiry date embeded in the symbol could be hard to interpret
    return (
      <>
        {item.symbol} <span className=" font-normal text-xs">({item.expiry_type})</span>
      </>
    );
  }

  return item.symbol;
};

export const getMarketType = (item: Instrument) => {
  let baseText;

  if (item.exchange_type === ExchangeType.CEX) {
    baseText = `${_.upperFirst(item.main_exchange)} ${_.upperFirst(item.sub_exchange)}`;
  } else {
    baseText = `${_.upperFirst(item.main_exchange)} (${item.dex_network?.network?.name})`;
  }

  let additionalText;

  switch (item.sub_exchange) {
    case 'futures':
    case 'swap': {
      switch (item.contract_type) {
        case 'linear': {
          additionalText = `(${item.quote_currency}-margined) - ${_.upperFirst(item.margin_mode)}`;
          break;
        }

        case 'inverse': {
          additionalText = `(Coin-margined) - ${_.upperFirst(item.margin_mode)}`;
          break;
        }
      }
      break;
    }

    case 'margin': {
      additionalText = `- ${_.upperFirst(item.margin_mode)}`;

      break;
    }

    default: {
    }
  }

  return `${baseText}${additionalText ? ` ${additionalText}` : ''}`;
};
