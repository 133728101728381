import { Alignment, FormGroup, HTMLSelect, Icon, NumericInput, Switch, Tooltip } from '@blueprintjs/core';
import { ChangeEvent, FormEvent } from 'react';

import { WickCatcherHighLowPauseSettings } from '../../../shared/interfaces/bot';

const TIMEFRAMES_OPTIONS = ['1d', '1w', '1m'];

interface Props {
  formState: WickCatcherHighLowPauseSettings;
  disabled?: boolean;
  onInputChange: (event: FormEvent<HTMLInputElement | HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>) => void;
  onNumberInputChange: (
    withCommas?: boolean,
  ) => (valueAsNumber: number, valueAsString: string, inputElement: HTMLInputElement | null) => void;
  onSwitchChange: (evt: any) => void;
}

export function HighLowPauseSettingsForm({ formState, onInputChange, onNumberInputChange, onSwitchChange }: Props) {
  console.log(formState.primary_enabled, 'formState');

  return (
    <div>
      <div className="border border-gray-300 rounded-md py-5 px-5 mb-4 relative">
        <div className="grid grid-cols-4 gap-4">
          <div>
            <Switch
              name="overall_enabled"
              checked={formState.overall_enabled}
              onChange={onSwitchChange}
              labelElement={<strong>Overall Enabled</strong>}
              inline={true}
              alignIndicator={Alignment.LEFT}
            />
          </div>
        </div>

        <div className="grid grid-cols-4 gap-4">
          <FormGroup
            label={
              <div className="flex flex-row items-center" style={{ height: '18px' }}>
                Time Frame
                <Tooltip content="Which time frame we look back into for pause checking" placement="top">
                  <Icon icon="help" size={10} className="ml-1 mb-1" />
                </Tooltip>
              </div>
            }
            labelFor="overall_timeframe"
            labelInfo=""
          >
            <HTMLSelect
              className="w-4/5"
              disabled={!formState.overall_enabled}
              id="overall_timeframe"
              name="overall_timeframe"
              value={formState.overall_timeframe}
              onChange={onInputChange}
            >
              {TIMEFRAMES_OPTIONS.map((value) => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </HTMLSelect>
          </FormGroup>

          <FormGroup
            label={
              <div className="flex flex-row items-center" style={{ height: '18px' }}>
                Sensitivity (%)
                <Tooltip
                  content="Pause a pair if overall market is within [Sensitivity Overall]% away from the highest or the lowest price of chosen Timeframe"
                  placement="top"
                >
                  <Icon icon="help" size={10} className="ml-1 mb-1" />
                </Tooltip>
              </div>
            }
            labelFor="Sensitivity"
          >
            <NumericInput
              disabled={!formState.overall_enabled}
              id="overall_sensitivity"
              name="overall_sensitivity"
              fill={true}
              className="w-4/5"
              value={formState.overall_sensitivity}
              onValueChange={onNumberInputChange(false)}
              buttonPosition="none"
              placeholder="E.g. 1"
            />
          </FormGroup>

          <FormGroup
            label={
              <div className="flex flex-row items-center" style={{ height: '18px' }}>
                Pause Check Debounce (ms)
                <Tooltip content="The debounce to check high/low pausing" placement="top">
                  <Icon icon="help" size={10} className="ml-1 mb-1" />
                </Tooltip>
              </div>
            }
            labelFor="overall_pause_check_debounce"
            helperText=""
          >
            <NumericInput
              disabled={!formState.overall_enabled}
              id="overall_pause_check_debounce"
              name="overall_pause_check_debounce"
              fill={true}
              value={formState.overall_pause_check_debounce}
              onValueChange={onNumberInputChange(true)}
              buttonPosition="none"
              placeholder="E.g. 10000"
            />
          </FormGroup>

          <FormGroup
            label={
              <div className="flex flex-row items-center" style={{ height: '18px' }}>
                Unpause Check Debounce (ms)
                <Tooltip content="The debounce to check high/low unpausing" placement="top">
                  <Icon icon="help" size={10} className="ml-1 mb-1" />
                </Tooltip>
              </div>
            }
            labelFor="overall_resume_check_debounce"
            helperText=""
          >
            <NumericInput
              disabled={!formState.overall_enabled}
              id="overall_resume_check_debounce"
              name="overall_resume_check_debounce"
              fill={true}
              value={formState.overall_resume_check_debounce}
              onValueChange={onNumberInputChange(true)}
              buttonPosition="none"
              placeholder="E.g. 10000"
            />
          </FormGroup>
        </div>
      </div>

      <div className="border border-gray-300 rounded-md py-5 px-5 mb-4 relative">
        <div className="grid grid-cols-4 gap-4">
          <div>
            <Switch
              name="primary_enabled"
              checked={formState.primary_enabled}
              onChange={onSwitchChange}
              labelElement={<strong>Primary Enabled</strong>}
              inline={true}
              alignIndicator={Alignment.LEFT}
            />
          </div>
        </div>

        <div className="grid grid-cols-4 gap-4">
          <FormGroup
            label={
              <div className="flex flex-row items-center" style={{ height: '18px' }}>
                Time Frame
                <Tooltip content="Which time frame we look back into for pause checking" placement="top">
                  <Icon icon="help" size={10} className="ml-1 mb-1" />
                </Tooltip>
              </div>
            }
            labelFor="primary_timeframe"
            labelInfo=""
          >
            <HTMLSelect
              className="w-4/5"
              disabled={!formState.primary_enabled}
              id="primary_timeframe"
              name="primary_timeframe"
              value={formState.primary_timeframe}
              onChange={onInputChange}
            >
              {TIMEFRAMES_OPTIONS.map((value) => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </HTMLSelect>
          </FormGroup>

          <FormGroup
            label={
              <div className="flex flex-row items-center" style={{ height: '18px' }}>
                Sensitivity (%)
                <Tooltip
                  content="Pause a pair if primary market is within [Sensitivity Primary]% away from the highest or the lowest price of chosen Timeframe"
                  placement="top"
                >
                  <Icon icon="help" size={10} className="ml-1 mb-1" />
                </Tooltip>
              </div>
            }
            labelFor="Sensitivity"
          >
            <NumericInput
              disabled={!formState.primary_enabled}
              id="primary_sensitivity"
              name="primary_sensitivity"
              fill={true}
              className="w-4/5"
              value={formState.primary_sensitivity}
              onValueChange={onNumberInputChange(false)}
              buttonPosition="none"
              placeholder="E.g. 1"
            />
          </FormGroup>

          <FormGroup
            label={
              <div className="flex flex-row items-center" style={{ height: '18px' }}>
                Pause Check Debounce (ms)
                <Tooltip content="The debounce to check high/low pausing" placement="top">
                  <Icon icon="help" size={10} className="ml-1 mb-1" />
                </Tooltip>
              </div>
            }
            labelFor="primary_pause_check_debounce"
            helperText=""
          >
            <NumericInput
              disabled={!formState.primary_enabled}
              id="primary_pause_check_debounce"
              name="primary_pause_check_debounce"
              fill={true}
              value={formState.primary_pause_check_debounce}
              onValueChange={onNumberInputChange(true)}
              buttonPosition="none"
              placeholder="E.g. 10000"
            />
          </FormGroup>

          <FormGroup
            label={
              <div className="flex flex-row items-center" style={{ height: '18px' }}>
                Unpause Check Debounce (ms)
                <Tooltip content="The debounce to check high/low unpausing" placement="top">
                  <Icon icon="help" size={10} className="ml-1 mb-1" />
                </Tooltip>
              </div>
            }
            labelFor="primary_resume_check_debounce"
            helperText=""
          >
            <NumericInput
              disabled={!formState.primary_enabled}
              id="primary_resume_check_debounce"
              name="primary_resume_check_debounce"
              fill={true}
              value={formState.primary_resume_check_debounce}
              onValueChange={onNumberInputChange(true)}
              buttonPosition="none"
              placeholder="E.g. 10000"
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
}
