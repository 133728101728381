import { Menu, Classes, Icon, Position, Button, Intent, MenuDivider, ButtonGroup, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { TradingPairCreateDialog } from '../../trading-pair/create-dialog/create-dialog';
import { WickCatcherCreateDialog } from '../../wick-catcher/create-dialog/create-dialog';

export function TradingPairsMenu({ bot }: any) {
  let currentBot = bot;

  const [openWickCatcherCreatePopup, setOpenWickCatcherCreatePopup] = useState<boolean>(false);
  const [openTradingPairCreatePopup, setOpenTradingPairCreatePopup] = useState<boolean>(false);

  const tradingPairsDropdownMenu = useCallback(() => {
    if (!currentBot) {
      return;
    }

    return (
      <Menu key="menu" large={false}>
        <MenuItem
          className="font-semibold"
          intent="primary"
          icon="add"
          onClick={() => setOpenTradingPairCreatePopup(true)}
          text="New Trading Pair"
        />

        <MenuDivider />

        <NavLink
          to={`/bots/${currentBot.app_id}/order_books`}
          className={({ isActive }) => `${isActive ? 'bp4-active' : ''} bp4-menu-item ${Classes.POPOVER_DISMISS}`}
        >
          <Icon icon="join-table" />
          <div>Order Books</div>
        </NavLink>

        <NavLink
          to={`/bots/${currentBot.app_id}/private_streams`}
          className={({ isActive }) => `${isActive ? 'bp4-active' : ''} bp4-menu-item ${Classes.POPOVER_DISMISS}`}
        >
          <Icon icon="one-to-many" />
          <div>Private Streams</div>
        </NavLink>

        <NavLink
          to={`/bots/${currentBot.app_id}/contract_streams `}
          className={({ isActive }) => `${isActive ? 'bp4-active' : ''} bp4-menu-item ${Classes.POPOVER_DISMISS}`}
        >
          <Icon icon="one-to-many" />
          <div>Contract Streams</div>
        </NavLink>
      </Menu>
    );
  }, [currentBot]);

  return (
    <>
      <ButtonGroup className="mr-3">
        <NavLink
          to={`/bots/${currentBot?.app_id}/wick_catchers`}
          className={({ isActive }) => `${isActive ? 'bp4-active' : ''} bp4-button bp4-outlined ${Classes.POPOVER_DISMISS}`}
        >
          <Icon icon="cube-add"></Icon>
          <div>Wick Catchers</div>
        </NavLink>

        <Button onClick={() => setOpenWickCatcherCreatePopup(true)} intent={Intent.NONE} icon="plus" />
      </ButtonGroup>

      <ButtonGroup>
        <NavLink
          to={`/bots/${currentBot?.app_id}/trading_pairs`}
          className={({ isActive }) => `${isActive ? 'bp4-active' : ''} bp4-button bp4-outlined ${Classes.POPOVER_DISMISS}`}
        >
          <Icon icon="cube"></Icon>
          <div>Trading Pairs</div>
        </NavLink>

        <Popover2
          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
          enforceFocus={false}
          content={tradingPairsDropdownMenu()}
          minimal={false}
          position={Position.BOTTOM}
        >
          <Button intent={Intent.NONE} outlined={true} rightIcon="double-caret-vertical" />
        </Popover2>
      </ButtonGroup>

      <WickCatcherCreateDialog isVisible={openWickCatcherCreatePopup} setVisible={setOpenWickCatcherCreatePopup} />

      <TradingPairCreateDialog isVisible={openTradingPairCreatePopup} setVisible={setOpenTradingPairCreatePopup} />
    </>
  );
}
