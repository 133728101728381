import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Classes, H5, InputGroup, Intent, Popover, PopoverPosition, Tab, Tabs } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { Channel } from 'phoenix';
import axios from '../../../shared/custom-axios';
import socket from '../../../socket';

import { BotEvent, PrivateStream, WebSocketMessage, WebSocketMessagesListResponse } from '../../../shared/interfaces/bot';
import { PrivateStreamMessagesList } from '../message/list/list';
import { StartStopButton } from '../../common/button/start_stop_button';

function UserDataPanel(props: { items: WebSocketMessage[]; isLoading: boolean }) {
  return (
    <div>
      <p className={Classes.RUNNING_TEXT}>Incoming order updates pushed from exchange in real-time.</p>

      <PrivateStreamMessagesList items={props.items} isLoading={props.isLoading} />
    </div>
  );
}

function PingsPongsPanel(props: { items: WebSocketMessage[]; isLoading: boolean }) {
  return (
    <div>
      <p className={Classes.RUNNING_TEXT}>Pings and pongs messages transferred between given bot's WebSocket client and exchange.</p>

      <PrivateStreamMessagesList items={props.items} isLoading={props.isLoading} />
    </div>
  );
}

function AllPanel(props: { items: WebSocketMessage[]; isLoading: boolean }) {
  return (
    <div>
      <p className={Classes.RUNNING_TEXT}></p>

      <PrivateStreamMessagesList items={props.items} isLoading={props.isLoading} />
    </div>
  );
}

interface Props {
  item: PrivateStream | null;
  onStart: (itemId: number) => void;
  onStop: (itemId: number) => void;
  onDelete: (itemId: number) => void;
}

export function PrivateStreamItem({ item, onStart, onStop, onDelete }: Props) {
  const navigate = useNavigate();

  const [websocketMessages, setWebSocketMessages] = useState<WebSocketMessage[]>([]);
  const [userDataMessages, setUserDataMessages] = useState<WebSocketMessage[]>([]);
  const [pingsPongsMessages, setPingsPongsMessages] = useState<WebSocketMessage[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  let channel = useRef<Channel | null>(null);

  const handleDelete = (id: number) => {
    onDelete(id);
    navigate(-1);
  };

  const search = useCallback(
    _.debounce((searchTerm) => {
      setIsLoading(true);

      axios
        .get<WebSocketMessagesListResponse>(`/api/websocket_messages/search?private_stream_id=${item?.id}&query=${searchTerm}`)
        .then((response) => {
          setWebSocketMessages(response.data.data);
          setIsLoading(false);
        });
    }, 300),
    [],
  );

  const handleSearchTermChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    search(searchTerm);
  };

  useEffect(() => {
    if (!item) {
      return;
    }

    axios.get<WebSocketMessagesListResponse>(`/api/websocket_messages?private_stream_id=${item?.id}`).then((response) => {
      setWebSocketMessages(response.data.data);
    });

    channel.current = socket.channel(`private_stream:${item.id}`, { private_stream_id: item.id });

    channel.current
      ?.join()
      .receive('ok', () => {
        console.log(`Joined "private_stream:${item.id}" channel for receiving private WS updates`);
      })
      .receive('error', (resp: any) => {
        console.log('Unable to join', resp);
      });

    channel.current?.on('client:receive_ws_message', (data: BotEvent) => {
      console.log('Receive private stream data', data);

      setWebSocketMessages((prevState) => [...prevState, data.data]);
    });

    channel.current?.on('client:send_ws_message', (data: BotEvent) => {
      console.log('Send private stream data', data);

      setWebSocketMessages((prevState) => [...prevState, data.data]);
    });

    return () => {
      channel.current?.leave();
      setWebSocketMessages([]);
    };
  }, [item]);

  useEffect(() => {
    let userDataMessages = websocketMessages.filter((message: WebSocketMessage) => {
      return message.type === 'user_data';
    });

    setUserDataMessages(userDataMessages);

    let pingsPongsMessages = websocketMessages.filter((message: WebSocketMessage) => {
      return message.type === 'ping' || message.type === 'pong';
    });

    setPingsPongsMessages(pingsPongsMessages);
  }, [websocketMessages]);

  return (
    <>
      {item && (
        <div className={Classes.DIALOG_BODY + ' h-full overflow-y-scroll'}>
          <p>Description: {item.description}</p>
          <p>
            Exchange: {_.upperFirst(item.main_exchange)} {_.upperFirst(item.sub_exchange)}
          </p>
          <p>Account: {item.account.name}</p>

          <div className="mt-5">
            <StartStopButton item={item} handleStart={onStart} handleStop={onStop} />

            <Tabs animate={true} id="TabsExample" key="horizontal" large={true} className="h-full overflow-y-scroll mt-3">
              <Tab
                id="user_data"
                title="User data"
                panel={<UserDataPanel items={userDataMessages} isLoading={isLoading} />}
                className="h-full overflow-y-scroll"
              />

              <Tab
                id="pings_pongs"
                title="Pings & Pongs"
                panel={<PingsPongsPanel items={pingsPongsMessages} isLoading={isLoading} />}
                className="h-full overflow-y-scroll"
              />
              <Tab
                id="all"
                title="All"
                panel={<AllPanel items={websocketMessages} isLoading={isLoading} />}
                className="h-full overflow-y-scroll"
              />

              <Tabs.Expander />

              <InputGroup
                name="searchTerm"
                value={searchTerm}
                onChange={(event) => {
                  event.persist();
                  handleSearchTermChange(event.target.value);
                }}
                className=" flex w-3/5"
                type="text"
                placeholder="Search..."
              />
            </Tabs>
          </div>
        </div>
      )}
      <div className={Classes.DIALOG_FOOTER}>
        {item && (
          <Popover popoverClassName={Classes.POPOVER_CONTENT_SIZING} position={PopoverPosition.RIGHT}>
            <Button intent={Intent.DANGER} text="Delete" small={true} />
            <div key="text">
              <H5>Confirm deletion</H5>
              <p>Are you sure you want to delete this private stream?</p>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                <Button className={Classes.POPOVER_DISMISS} style={{ marginRight: 10 }}>
                  Cancel
                </Button>
                <Button onClick={() => handleDelete(item.id)} intent={Intent.DANGER} className={Classes.POPOVER_DISMISS}>
                  Delete
                </Button>
              </div>
            </div>
          </Popover>
        )}
      </div>
    </>
  );
}
