import axios from 'axios';
import { HTTP_API_BASE_URL } from './configs';

const configs = {
  baseURL: HTTP_API_BASE_URL,
};

// Create a custom Axios instance for carrying access token by default
const axiosInstance = axios.create(configs);

const UNAUTHORIZED_CODE = 401;

// Intercept request and inject the access token to Authorization header
axiosInstance.interceptors.request.use((config) => {
  // add token to request headers
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('user_token')}`;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === UNAUTHORIZED_CODE) {
      localStorage.removeItem('user_token');

      if (window.location.href !== '/login') {
        window.location.href = '/login';
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
