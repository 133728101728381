import { createContext } from 'react';

import { Bot } from '../shared/interfaces/bot';

export interface BotsManager {
  bots: Bot[];
  currentBot?: Bot | null;
  setCurrentBot?(bot: Bot | null): void;
}

const defaultState: BotsManager = {
  bots: [],
  currentBot: null,
};

export const BotsManagerContext = createContext<BotsManager>(defaultState);
