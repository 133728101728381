import { useState, useEffect, useContext } from 'react';

import { APP_NAME } from '../../shared/configs';
import { ActiveOrderContext } from '../../contexts/active-order';
import { BotsManagerContext } from '../../contexts/bots-manager';

export function usePageTitle(defaultValue: string): [string, React.Dispatch<React.SetStateAction<string>>] {
  const { currentBot } = useContext(BotsManagerContext);
  const { activeOrders } = useContext(ActiveOrderContext);

  let [pageTitle, setPageTitle] = useState(defaultValue);

  useEffect(() => {
    let title;
    let activeOrdersInfo = '';

    if (activeOrders.length > 0) {
      activeOrdersInfo = `(${activeOrders.length}) `;
    }

    if (currentBot) {
      title = `${activeOrdersInfo}${currentBot.name} | ${APP_NAME}`;
    } else {
      title = `${activeOrdersInfo}${APP_NAME}`;
    }

    setPageTitle(title);
  }, [currentBot, activeOrders]);

  return [pageTitle, setPageTitle];
}
