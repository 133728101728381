import { Button, Dialog, DialogBody, H6, Intent } from '@blueprintjs/core';
import { useCallback, useState } from 'react';

import axios from '../../../shared/custom-axios';
import { AppToaster } from '../../../shared/app-toaster';

interface Props {
  botId: number;
}

export function BotIpAddressButton({ botId }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [response, setResponse] = useState<any>(undefined);

  const handleRestart = useCallback(() => {
    setIsLoading(true);

    axios
      .get<any>(`/api/bots/${botId}/ip_address`, {})
      .then(({ data }) => {
        setIsLoading(false);
        setResponse(data);
        setIsDialogOpen(true);
      })
      .catch((error) => {
        setIsLoading(false);

        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, []);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
    setResponse(undefined);
  }, []);

  return (
    <>
      <Button
        intent={Intent.NONE}
        loading={isLoading}
        onClick={(e) => {
          e.stopPropagation();

          handleRestart();
        }}
        onDoubleClick={(e) => e.stopPropagation()}
      >
        Show public IP addresses
      </Button>

      <div onDoubleClick={(e) => e.stopPropagation()}>
        <Dialog isOpen={isDialogOpen} onClose={() => handleCloseDialog()}>
          <DialogBody>
            <H6>App ID</H6>
            <p>{response?.bot_app_id}</p>

            <H6>Public IP Address</H6>
            <p>{response?.bot_ip_address}</p>

            {response?.bot_ip_address === response?.proxy_ip_address && (
              <>
                <H6>Public Proxy IP Address (seems no proxy is used)</H6>
                <p>{response?.proxy_ip_address}</p>
              </>
            )}

            {response?.bot_ip_address !== response?.proxy_ip_address && (
              <>
                <H6>Public Proxy IP Address</H6>
                <p>{response?.proxy_ip_address}</p>
              </>
            )}
          </DialogBody>
        </Dialog>
      </div>
    </>
  );
}
