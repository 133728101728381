import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Channel } from 'phoenix';

import { BotsManagerContext } from './bots-manager';
import socket from '../socket';
import { BotHealth as BotHealthCheck, BotState } from '../shared/interfaces/bot';

interface State {
  botsHealthChecks: { [index: number]: BotHealthCheck };
}

const defaultState: State = {
  botsHealthChecks: {},
};

interface PongResponses {
  last_ping_at: string;
  last_pong_responses: {
    [key: string]: { time_received: string };
  };
}

export const BotsHealthContext = createContext<State>(defaultState);

export function BotsHealthContextProvider({ children }: any) {
  const { bots } = useContext(BotsManagerContext);

  let channel = useRef<Channel | null>(null);

  const [botsHealth, setBotsHealth] = useState<{ [index: number]: BotHealthCheck }>({});

  useEffect(() => {
    channel.current = socket.channel('bot:lobby', {});

    channel.current?.on('bots_health_check_updates', (data: PongResponses) => {
      const { last_pong_responses: pongResponses } = data;

      bots.forEach((bot) => {
        const pongResponse = pongResponses[bot.app_id];
        if (pongResponse) {
          const { time_received: timeReceived } = pongResponse;

          setBotsHealth((prevState) => {
            const botHealth: BotHealthCheck = {
              connectivity_status: BotState.Connected,
              last_pong_response_at: timeReceived,
            };

            return { ...prevState, [bot.id]: botHealth };
          });
        } else {
          setBotsHealth((prevState) => {
            let current = prevState[bot.id];

            if (current) {
              const botHealth: BotHealthCheck = {
                ...current,
                connectivity_status: BotState.Disconnected,
              };

              return { ...prevState, [bot.id]: botHealth };
            }

            return prevState;
          });
        }
        return bot;
      });
    });

    return () => {
      channel.current?.off('bots_health_check_updates');
    };
  }, [bots]);

  return <BotsHealthContext.Provider value={{ botsHealthChecks: botsHealth }}>{children}</BotsHealthContext.Provider>;
}
