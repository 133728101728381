import moment from 'moment';
import { Intent } from '@blueprintjs/core';
import { SortingRule } from 'react-table';

import { AppToaster } from './app-toaster';
import { Network, OrderFillTradingPair, StrategyType, TradeFeedOrderFill, TradingPair } from './interfaces/bot';

const DAY_IN_SECOND = 86400;
const HOUR_IN_SECOND = 3600;

export function copyToClipboard(content: string) {
  navigator.clipboard.writeText(content);
  AppToaster.show({ message: 'Copied', icon: 'tick', intent: Intent.PRIMARY, timeout: 1000 });
}

export function getDateTimeDiff(a: string, b: string) {
  const timeA = new Date(a);
  const timeB = new Date(b);
  return timeB.getTime() - timeA.getTime();
}

export function getTxUrl(network: Network | undefined, txHash: string | undefined) {
  if (network && txHash) {
    return new URL(`/tx/${txHash}`, network?.block_explorer_url).href;
  }
}

export const DEFAULT_SORT_BY = { id: 'id', desc: false };

export function getFirstSortBy(sortBy: SortingRule<object>[]) {
  // Currently support sorting by only one field/column
  return sortBy.length > 0 ? sortBy[0] : DEFAULT_SORT_BY;
}

export function getPercentageOrRatioText(tradingPair: TradingPair | OrderFillTradingPair, orderFill: TradeFeedOrderFill | null) {
  if (!orderFill || !orderFill.opportunity) {
    return;
  }

  switch (tradingPair.strategy_type) {
    case StrategyType.Normal: {
      return `Target: ${orderFill.opportunity.percentage}%`;
    }

    case StrategyType.PairsTrading: {
      return `Target: ${orderFill.opportunity.ratio}`;
    }
  }
}

// parse object to url query string params
export const serialize = (obj: any, prefix?: any): any => {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p];
      str.push(v !== null && typeof v === 'object' ? serialize(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v));
    }
  }
  return str.join('&');
};

export function countDecimalPlaces(number: number): number {
  if (Math.floor(number) === number) return 0;

  return number.toString().split('.')[1].length || 0;
}

export function getOrderAge(datetime: string): string {
  const now_str = moment.utc().format('YYYY-MM-DD HH:mm:ss');
  const start_time = moment(datetime);
  const end_time = moment(now_str);
  const duration = moment.duration(end_time.diff(start_time));
  const durationInSecond = duration.asSeconds();
  if (durationInSecond > DAY_IN_SECOND) {
    return `${duration.asDays().toFixed(1)} days`;
  } else if (durationInSecond > HOUR_IN_SECOND) {
    return `${duration.asHours().toFixed(1)}h`;
  } else if (durationInSecond > 60) {
    return `${duration.asMinutes().toFixed(1)}m`;
  } else {
    return `${durationInSecond.toFixed(1)}s`;
  }
}
