import { Position, Toaster } from '@blueprintjs/core';

export const AppToaster = Toaster.create(
  {
    className: 'app-toaster',
    position: Position.TOP,
  },
  document.body,
);

export const AppRightToaster = Toaster.create(
  {
    className: 'app-toaster',
    position: Position.TOP_RIGHT,
    maxToasts: 5,
  },
  document.body,
);
