import { NonIdealState } from '@blueprintjs/core';
import { useEffect, useState } from 'react';
import { useTable, UseTableCellProps } from 'react-table';

import { Opportunity } from '../../../shared/interfaces/bot';

interface Props {
  columns: any;
  data: Opportunity[];
  viewedOpportunity?: number;
}

export function OpportunitiesListTable({ columns, data, viewedOpportunity }: Props) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const [separatorIndex, setSeparatorIndex] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Show a separator line between short and long groups
    const shortOpportunityPresent = rows.some((row) => row.original.side === 'short');
    const longOpportunityPresent = rows.some((row) => row.original.side === 'long');

    if (shortOpportunityPresent && longOpportunityPresent) {
      const firstLongOpportunityIndex = rows.findIndex((row) => row.original.side === 'long');
      setSeparatorIndex(firstLongOpportunityIndex);
    }
  }, [rows]);

  return (
    <>
      {rows.length <= 0 && (
        <div className="w-full overflow-auto">
          <NonIdealState
            icon="folder-open"
            title="No settings found"
            description="Double-click here and add new settings to start trading."
          />
        </div>
      )}

      {rows.length > 0 && (
        <div className="overflow-auto">
          <table {...getTableProps()} className="border table-fixed text-xs w-full border-gray-800">
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      {...column.getHeaderProps({
                        style: { minWidth: column.minWidth, Width: column.minWidth, width: column.width },
                      })}
                      className="border border-gray-300 px-1.5 py-1 text-light-blue-600"
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="text-right">
              {rows.map((row: any, i: number) => {
                prepareRow(row);

                let className = '';
                let isSkippedTradingRow = row.original.quantity <= 0 || row.original.quota <= 0;
                let isFinished = !isSkippedTradingRow && row.original.quantity === 0;

                if (i === separatorIndex) {
                  className = className.concat(' border-t-4 border-gray-300');
                }

                if (viewedOpportunity && row.original.id === viewedOpportunity) {
                  className = className.concat(' bg-blue-300');
                } else if (row.original.is_flip) {
                  className = className.concat(' bg-blue-500 flip-row border-blue-300');
                } else if (isSkippedTradingRow) {
                  className = className.concat(' filter blur');
                } else {
                  className = className.concat(' hover:bg-gray-100');
                }

                return (
                  <tr {...row.getRowProps()} className={className}>
                    {row.cells.map((cell: UseTableCellProps<Opportunity>) => {
                      let className = 'border border-gray-300 px-1.5 py-1 text-light-blue-600';

                      if (row.original.is_flip) {
                        className = 'border border-blue-600 px-1.5 py-1 font-semibold text-white';
                      }

                      // Style finished opportunity as light green
                      if (isFinished && cell.column.id === 'remain') {
                        className = className.concat(' bg-green-100');
                      }

                      return (
                        <td
                          {...cell.getCellProps({
                            style: { height: '68px', minWidth: cell.column.minWidth, width: cell.column.width },
                          })}
                          className={className}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
