import React, { useContext } from 'react';
import _ from 'lodash';

import { TradeFeedHedgeUpdate, TradeFeedOrderFill } from '../../../../../shared/interfaces/bot';
import { TimezoneContext } from '../../../../../contexts/timezone';

import { TradeFeedHedgeUpdatesListTable } from './table';

interface Props {
  items: TradeFeedHedgeUpdate[];
}

export function TradeFeedHedgeOrderHedgeUpdatesList({ items }: Props) {
  const { dateFormater, timeFormater } = useContext(TimezoneContext);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          if (!order.fill_tx_ts) {
            return '';
          }

          const dateTime = new Date(order.fill_tx_ts + 'Z');
          return dateFormater.format(dateTime);
        },
        minWidth: 128,
        maxWidth: 128,
      },
      {
        Header: 'Time',
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          if (!order.fill_tx_ts) {
            return '';
          }

          const dateTime = new Date(order.fill_tx_ts + 'Z');
          return timeFormater.format(dateTime);
        },
        minWidth: 110,
        maxWidth: 110,
      },
      {
        Header: 'Lag Time (ms)',
        accessor: 'lag_time',
        minWidth: 120,
        maxWidth: 120,
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Avg Fill Price',
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          if (!order.avg_fill_price || !order.avg_fill_price.amount) {
            return;
          }

          return `${order.avg_fill_price?.amount} ${order.avg_fill_price?.currency}`;
        },
      },
      {
        Header: 'Fill Price',
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          return (
            <>
              <span className="block">
                <span className="font-semibold py-1 px-2 border border-gray-300 text-gray-600 rounded-xl whitespace-pre">
                  {order.last_fill_price?.amount}
                </span>
              </span>

              <span className="block mt-2 text-gray-600 text-xxs">{order.last_fill_price?.currency}</span>
            </>
          );
        },
      },
      {
        Header: 'Fill Volume / Total Volume',
        disableSortBy: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          const isFull =
            order.last_fill_volume?.amount &&
            order.original_volume?.amount &&
            order.last_fill_volume?.amount >= order.original_volume?.amount;

          if (isFull) {
            return (
              <>
                <span className="font-semibold py-1 px-2 border border-gray-400 bg-gray-300 text-gray-900 rounded-xl whitespace-pre">
                  {order.last_fill_volume?.amount}
                  {order.original_volume?.amount ? ` / ${order.original_volume?.amount}` : ''}
                </span>

                <span className="block mt-2 text-gray-600 text-xxs">{order.original_volume?.currency}</span>
              </>
            );
          }

          return (
            <>
              <span className="block">
                <span className="font-semibold py-1 px-2 border border-gray-300 text-gray-600 rounded-xl whitespace-pre">
                  {order.last_fill_volume?.amount}
                  {order.original_volume?.amount ? ` / ${order.original_volume?.amount}` : ''}
                </span>
              </span>

              <span className="block mt-2 text-gray-600 text-xxs">{order.original_volume?.currency}</span>
            </>
          );
        },
      },
      {
        Header: 'Fill Value',
        disableSortBy: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          return (
            <>
              <span className="block">{order.last_fill_value?.amount}</span>
            </>
          );
        },
      },
    ],
    [dateFormater, timeFormater],
  );

  return (
    <>
      <TradeFeedHedgeUpdatesListTable columns={columns} data={items} />
    </>
  );
}
