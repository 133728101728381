import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Alignment, Switch, Tab, Tabs } from '@blueprintjs/core';
import axios from '../../../shared/custom-axios';
import socket from '../../../socket';

import { EmergencyCancelOrder, EmergencyCancelOrdersListResponse, TradingPair } from '../../../shared/interfaces/bot';
import { BotsManagerContext } from '../../../contexts/bots-manager';
import { TimezoneContext } from '../../../contexts/timezone';

import { EmergencyCancelsListLatest } from './latest/latest';
import { EmergencyCancelsListHistory } from './history/history';
import { useEmergencyCancelHighlightedLatencyColumns } from './use-highlighted-latency-columns';
import { useEmergencyCancelListColumns } from './use-columns';
import { Channel } from 'phoenix';

interface Props {
  tradingPair: TradingPair;
}

export function EmergencyCancelsList({ tradingPair }: Props) {
  const { bots } = useContext(BotsManagerContext);
  const currentBot = bots.find((bot) => bot.id === tradingPair.bot_id);
  const [isDetailsView, setIsDetailsView] = useState(false);
  const { dateFormater, timeFormater } = useContext(TimezoneContext);
  const [latestEcOrders, setLatestEcOrders] = useState<Array<EmergencyCancelOrder>>([]);
  let tradingPairChannel = useRef<Channel | null>(null);

  const [hoveredColumn, setHoveredColumn, highlighedColumnIds] = useEmergencyCancelHighlightedLatencyColumns();
  const columns = useEmergencyCancelListColumns({ isDetailsView, dateFormater, timeFormater });

  const handleDetailsViewChange = () => {
    setIsDetailsView(!isDetailsView);
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      const basePath = '/api/emergency_cancels/orders';

      const path = `${basePath}?&page_size=${pageSize}&page=${pageIndex + 1}&trading_pair_id=${tradingPair.id}&bot_id=${currentBot?.id}`;

      axios.get<EmergencyCancelOrdersListResponse>(path).then((response) => {
        const { entries } = response.data;

        setLatestEcOrders(entries);
      });
    },
    [tradingPair.id, currentBot],
  );

  useEffect(() => {
    if (!tradingPair) {
      return;
    }

    // Fetch the latest data
    fetchData({ pageSize: 20, pageIndex: 1 });

    tradingPairChannel.current = socket.channel(`trading_pair:${tradingPair.id}`, { trading_pair_id: tradingPair.id, limit: 5 });

    // Populate data with latest ones coming from WebSocket
    tradingPairChannel.current?.on('emergency_cancel:order_cancellation', ({ data }) => {
      setLatestEcOrders((prevState) => {
        // Replace the current item with latest state via WebSocket

        // TODO: Is it a good solution? No?
        const currentIndex = prevState.findIndex((item) => item.internal_id === data.internal_id);

        if (currentIndex > -1) {
          const newState = [...prevState];
          newState[currentIndex] = data;

          return newState;
        }

        return [data, ...prevState];
      });
    });

    tradingPairChannel.current?.onClose(() => {
      console.log(`[#${tradingPair.id} ${tradingPair.name}] Left "trading_pair:${tradingPair.id}" channel`);
    });

    return () => {
      tradingPairChannel.current?.leave();
    };
  }, []);

  return (
    <>
      <Tabs id="emergency-cancel-orders" defaultSelectedTabId="lastest-orders" large={false}>
        <Tab
          id="lastest-orders"
          title="Latest"
          panel={
            <EmergencyCancelsListLatest
              columns={columns}
              data={latestEcOrders}
              hoveredColumn={hoveredColumn}
              showPartialData={true}
              setHoveredColumn={setHoveredColumn}
              highlighedColumnIds={highlighedColumnIds}
            />
          }
        />

        <Tab
          id="orders-history"
          title="History"
          panel={
            <EmergencyCancelsListHistory
              tradingPair={tradingPair}
              columns={columns}
              hoveredColumn={hoveredColumn}
              showPartialData={true}
              setHoveredColumn={setHoveredColumn}
              highlighedColumnIds={highlighedColumnIds}
            />
          }
        />

        <Tabs.Expander />

        <Switch
          checked={isDetailsView}
          onChange={handleDetailsViewChange}
          labelElement={<strong>Details view</strong>}
          inline={true}
          alignIndicator={Alignment.LEFT}
        />
      </Tabs>
    </>
  );
}
