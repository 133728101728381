import React, { FormEvent, useState } from 'react';
import { Button, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { Bot, BotSetting } from '../../../shared/interfaces/bot';

interface Props {
  setting?: BotSetting;
  handleSubmit: (data: any) => void;
}
export function BotSettingForm({ setting, handleSubmit }: Props) {
  const initForm = {
    id: setting?.id,
    key: setting?.key,
    value: setting?.value,
    bot_id: setting?.bot_id,
    description: setting?.description,
  };

  const [formState, setFormState] = useState(initForm);
  const [errors, setErrors] = useState<string[]>([]);

  const handleInputChange = (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    setErrors([]);
    const { name, value } = event.currentTarget;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmitForm = () => {
    const errors = [];
    if (!formState.key || formState.key === '') {
      errors.push('key');
    }
    if (!formState.value || formState.value === '') {
      errors.push('value');
    }
    setErrors(errors);
    if (errors.length === 0) {
      handleSubmit(formState);
    }
  };

  const hasError = (name: string) => {
    return errors.findIndex((err: string) => err == name) >= 0;
  };

  return (
    <>
      <div>
        <FormGroup
          label="Key"
          intent={hasError('key') ? Intent.DANGER : Intent.NONE}
          labelFor="key"
          labelInfo="(required)"
          helperText={hasError('key') && 'Please enter the key'}
        >
          <InputGroup id="text-input" name="key" value={formState.key} onChange={handleInputChange} placeholder="Setting Key" />
        </FormGroup>

        <FormGroup
          label="Value"
          intent={hasError('value') ? Intent.DANGER : Intent.NONE}
          labelFor="value"
          labelInfo="(required)"
          helperText={hasError('value') && 'Please enter the value'}
        >
          <InputGroup id="text-input" name="value" value={formState.value} onChange={handleInputChange} placeholder="Setting value" />
        </FormGroup>

        <FormGroup label="Description" labelFor="description">
          <InputGroup
            id="text-input"
            name="description"
            value={formState.description}
            onChange={handleInputChange}
            placeholder="Description"
          />
        </FormGroup>

        <Button intent="primary" onClick={handleSubmitForm}>
          Submit
        </Button>
      </div>
    </>
  );
}
