import { useEffect } from 'react';
import { ContractStream, ContractStreamsListResponse, Instrument } from '../../../../shared/interfaces/bot';

import axios from '../../../../shared/custom-axios';

interface Props {
  botId: number | undefined;
  instrumentId: string;
  accountId: string;
  selectedInstrument: Instrument | null;
  onDataReady: (items: ContractStream[]) => void;
}

export function useReloadContractStreams({ botId, instrumentId, accountId, selectedInstrument, onDataReady }: Props) {
  useEffect(() => {
    const isContractStreamSupported = selectedInstrument?.exchange_type === 'dex' && selectedInstrument.main_exchange !== 'blur';

    if (isContractStreamSupported) {
      if (instrumentId) {
        axios.get<ContractStreamsListResponse>(`/api/contract_streams?instrument_id=${instrumentId}&bot_id=${botId}`).then((response) => {
          onDataReady(response.data.data);
        });
      }

      // setFormState((prevState) => ({
      //   ...prevState,
      //   primary_contract_stream_id: '',
      // }));
    }
  }, [selectedInstrument, instrumentId, botId]);
}
