import _ from 'lodash';
import { Button, MenuItem } from '@blueprintjs/core';
import { ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select';

import { OrderBook, ConnectivityStatus } from '../../../shared/interfaces/bot';
import { useEffect, useState } from 'react';
import { ConnectivityIndicator } from '../../bot/connectivity-indicator';

interface Props {
  selectedItemId: number | string | undefined;
  items: OrderBook[];
  disabled?: boolean;
  handleSelect: (item: OrderBook) => void;
}

const InnerSelect = Select.ofType<OrderBook>();

const getLabel = (item: OrderBook) => {
  let baseText, additionalText;
  let { instrument } = item;

  if (instrument) {
    baseText = `${instrument.symbol}`;

    switch (instrument.sub_exchange) {
      case 'futures':
      case 'swap': {
        switch (instrument.contract_type) {
          case 'linear': {
            additionalText = `(${instrument.quote_currency}-margined) - ${_.upperFirst(instrument.margin_mode)}`;
            break;
          }

          case 'inverse': {
            additionalText = `(Coin-margined) - ${_.upperFirst(instrument.margin_mode)}`;
            break;
          }
        }

        break;
      }

      case 'margin': {
        additionalText = `- ${_.upperFirst(instrument.margin_mode)}`;

        break;
      }

      default: {
      }
    }
  }

  return `${baseText}${additionalText ? ` ${additionalText}` : ''}`;
};

const renderItem: ItemRenderer<OrderBook> = (item, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      className="w-96 min-w-max"
      active={modifiers.active}
      key={item.name}
      onClick={handleClick}
      labelElement={<ConnectivityIndicator active={item.status === ConnectivityStatus.Started} />}
      text={
        <>
          {item.is_default && (
            <span className="font-bold block">
              {item.name} <span className="font-normal text-xs">(default)</span>
            </span>
          )}

          {!item.is_default && <span className="font-bold block">{item.name}</span>}

          <span className="text-xs block">{getLabel(item)}</span>
        </>
      }
    ></MenuItem>
  );
};

const filterItem: ItemPredicate<OrderBook> = (query, item) => {
  const stringForSearch = [
    item.name,
    item.main_exchange,
    item.sub_exchange,
    item.description,
    item.instrument?.base_currency,
    item.instrument?.quote_currency,
  ]
    .join(' ')
    .toLowerCase();

  return stringForSearch.indexOf(query.toLowerCase()) >= 0;
};

export function OrderBookSelect({ selectedItemId, items, disabled = false, handleSelect }: Props) {
  const [selectedItem, setSelectedItem] = useState<OrderBook | null>();

  useEffect(() => {
    if (items.length < 1) {
      return;
    }

    const selectedInstrument = items.find(({ id }) => id === Number(selectedItemId));

    setSelectedItem(selectedInstrument);
  }, [selectedItemId, items]);

  return (
    <>
      <InnerSelect
        itemRenderer={renderItem}
        itemPredicate={filterItem}
        onItemSelect={handleSelect}
        activeItem={selectedItem}
        items={items}
        disabled={disabled}
      >
        <Button disabled={disabled} text={selectedItem ? selectedItem.name : 'Choose an order book'} rightIcon="double-caret-vertical" />
      </InnerSelect>
    </>
  );
}
