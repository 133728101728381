import { useContext, useEffect, useState } from 'react';
import { Link, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Card, Dialog, H5, Icon, Intent, Tag } from '@blueprintjs/core';
import _ from 'lodash';
import axios from '../../../shared/custom-axios';

import { AppToaster } from '../../../shared/app-toaster';
import { StartStopButton } from '../../common/button/start_stop_button';
import { ContractStream, ContractStreamResponse, ContractStreamsListResponse } from '../../../shared/interfaces/bot';
import { BotsManagerContext } from '../../../contexts/bots-manager';

import styles from './list.module.css';
import { ContractStreamItem } from '../item/item';

export function ContractStreamList() {
  const { currentBot } = useContext(BotsManagerContext);
  let navigate = useNavigate();

  const [contractStreams, setContractStreams] = useState<ContractStream[]>([]);
  const [selectedContractStream, setSelectedContractStream] = useState<ContractStream | null>(null);

  useEffect(() => {
    if (!currentBot) {
      return;
    }

    axios.get<ContractStreamsListResponse>(`/api/contract_streams?bot_id=${currentBot?.id}`).then((response) => {
      setContractStreams(response.data.data);
    });
  }, [currentBot]);

  const handleDelete = (id: number) => {
    axios
      .delete<ContractStreamsListResponse>(`/api/contract_streams/${id}`)
      .then(() => {
        setContractStreams((prevState) => {
          const newState = prevState.filter((contractStream) => contractStream.id !== id);
          return newState;
        });

        const message = 'Contract stream deleted successfully!';
        AppToaster.show({
          message: message,
          icon: 'tick',
          intent: Intent.SUCCESS,
          timeout: 2000,
        });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({
          message: message,
          icon: 'warning-sign',
          intent: Intent.DANGER,
          timeout: 3500,
        });
      });
  };

  const handleStart = (id: number) => {
    axios
      .put<ContractStreamResponse>(`/api/contract_streams/${id}/start`)
      .then((response) => {
        const newData = response.data.data;

        setContractStreams((prevState) => {
          return prevState.map((contractStream) => {
            if (contractStream.id === newData.id) {
              return newData;
            } else {
              return contractStream;
            }
          });
        });

        const message = 'Contract stream started';
        AppToaster.show({
          message: message,
          icon: 'tick',
          intent: Intent.SUCCESS,
          timeout: 2000,
        });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({
          message: message,
          icon: 'warning-sign',
          intent: Intent.DANGER,
          timeout: 3500,
        });
      });
  };

  const handleStop = (id: number) => {
    axios
      .put<ContractStreamResponse>(`/api/contract_streams/${id}/stop`)
      .then((response) => {
        const newData = response.data.data;

        setContractStreams((prevState) => {
          return prevState.map((contractStream) => {
            if (contractStream.id === newData.id) {
              return newData;
            } else {
              return contractStream;
            }
          });
        });

        const message = 'Contract stream stopped';
        AppToaster.show({
          message: message,
          icon: 'tick',
          intent: Intent.SUCCESS,
          timeout: 2000,
        });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({
          message: message,
          icon: 'warning-sign',
          intent: Intent.DANGER,
          timeout: 3500,
        });
      });
  };

  const handleOpen = (item: ContractStream) => {
    setSelectedContractStream(item);
    navigate(`${item.id}`);
  };

  return (
    <>
      <h2 className="text-lg font-bold my-1">Contract Streams</h2>
      <Link to={`/bots/${currentBot?.app_id}/contract_streams/new`} className="mt-1 bp4-button">
        <Icon icon="add" />
        <span>New</span>
      </Link>

      <div className="contract-streams grid gap-10 grid-cols-4 mt-5">
        {contractStreams.map((contractStream) => {
          return (
            <Card
              interactive={true}
              elevation={2}
              key={contractStream.id}
              className="group"
              onDoubleClick={(e) => {
                e.preventDefault();
                handleOpen(contractStream);
              }}
            >
              <div className="contract-stream">
                <div className="contract-stream-header pt-3 relative">
                  <H5>
                    {contractStream.name}
                    <span className="text-gray-300 select-none"> #{contractStream.id}</span>
                  </H5>

                  {contractStream.is_default && (
                    <span className="absolute -top-5 -left-5">
                      <Tag minimal={true}>Default</Tag>
                    </span>
                  )}
                </div>
                <div className="contract-stream-body">
                  <p>{contractStream.description}</p>
                  <p>Exchange: {_.upperFirst(contractStream.instrument.main_exchange)}</p>
                </div>
                <div className="contract-stream-footer text-right">
                  <StartStopButton item={contractStream} handleStart={handleStart} handleStop={handleStop} />
                </div>
              </div>
            </Card>
          );
        })}
      </div>

      <Routes>
        <Route
          path={`:id`}
          element={
            <ContractStreamDialog
              item={selectedContractStream}
              setItem={setSelectedContractStream}
              onStart={handleStart}
              onStop={handleStop}
              onDelete={handleDelete}
            />
          }
        />
      </Routes>
    </>
  );
}

interface ContractStreamDialogProps {
  item: ContractStream | null;
  setItem: (item: ContractStream | null) => void;
  onStart: (id: number) => void;
  onStop: (id: number) => void;
  onDelete: (id: number) => void;
}

const ContractStreamDialog = ({ item, setItem, onStart, onStop, onDelete }: ContractStreamDialogProps) => {
  let { id } = useParams<{ id: string }>();
  let navigate = useNavigate();

  useEffect(() => {
    axios
      .get<ContractStreamResponse>(`/api/contract_streams/${id}`)
      .then((response) => {
        const newData = response.data.data;

        setItem(newData);
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({
          message: message,
          icon: 'warning-sign',
          intent: Intent.DANGER,
          timeout: 3500,
        });
      });
  }, []);

  const handleClose = () => {
    setItem(null);
    navigate(-1);
  };

  if (!id) {
    return <></>;
  }

  return (
    <>
      <Dialog
        portalContainer={document.getElementById('app') || undefined}
        title={`${item?.id}. ${item?.name}`}
        transitionDuration={500}
        isOpen={!!item}
        onClose={() => {
          handleClose();
        }}
        className={styles.dialog}
      >
        <ContractStreamItem item={item} onStart={onStart} onStop={onStop} onDelete={onDelete} />
      </Dialog>
    </>
  );
};
