import { createContext } from 'react';

import { User } from '../shared/interfaces/bot';

export interface Auth {
  currentUser: User | null;
  setCurrentUser?(user: User | null): void;
}

const defaultState: Auth = {
  currentUser: null,
};

export const AuthContext = createContext<Auth>(defaultState);
