import { createContext } from 'react';

import { TimezoneOption } from '../shared/interfaces/bot';

// Bali time https://time.is/Bali
export const BaliDateFormater = new Intl.DateTimeFormat('en-US', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  timeZone: 'Asia/Makassar',
});

export const BaliTimeFormater = new Intl.DateTimeFormat('en-US', {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  fractionalSecondDigits: 3,
  hourCycle: 'h23',
  timeZone: 'Asia/Makassar',
});

export const BaliDateTimeFormater = new Intl.DateTimeFormat('en-US', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  fractionalSecondDigits: 3,
  hourCycle: 'h23',
  timeZone: 'Asia/Makassar',
});

export const UtcDateFormater = new Intl.DateTimeFormat('en-US', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  timeZone: 'UTC',
});

export const UtcTimeFormater = new Intl.DateTimeFormat('en-US', {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  fractionalSecondDigits: 3,
  hourCycle: 'h23',
  timeZone: 'UTC',
});

export const UtcDateTimeFormater = new Intl.DateTimeFormat('en-US', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  fractionalSecondDigits: 3,
  hourCycle: 'h23',
  timeZone: 'UTC',
});

export const BrowserDateFormater = new Intl.DateTimeFormat('en-US', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
});

export const BrowserTimeFormater = new Intl.DateTimeFormat('en-US', {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  fractionalSecondDigits: 3,
  hourCycle: 'h23',
});

export const BrowserDateTimeFormater = new Intl.DateTimeFormat('en-US', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  fractionalSecondDigits: 3,
  hourCycle: 'h23',
});

export interface Timezone {
  timezone: TimezoneOption;
  dateFormater: Intl.DateTimeFormat;
  timeFormater: Intl.DateTimeFormat;
  dateTimeFormater: Intl.DateTimeFormat;
  setTimezone?(mode: TimezoneOption): void;
  setDateFormater?(mode: Intl.DateTimeFormat): void;
  setTimeFormater?(mode: Intl.DateTimeFormat): void;
  setDateTimeFormater?(mode: Intl.DateTimeFormat): void;
}

const defaultState: Timezone = {
  timezone: TimezoneOption.Bali,
  dateFormater: BaliDateFormater,
  timeFormater: BaliTimeFormater,
  dateTimeFormater: BaliDateTimeFormater,
};

export const TimezoneContext = createContext<Timezone>(defaultState);
