import { useParams, Params } from 'react-router-dom';
import { BotSettingList } from '../../components/bot/settings/settings';

export function BotsSettingsPage() {
  return (
    <>
      <h2 className="text-lg font-bold my-1">Bot Settings</h2>
      <BotSettingList />
    </>
  );
}
