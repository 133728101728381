import React from 'react';
import { useContext, useState } from 'react';
import _ from 'lodash';
import { Button, Classes, Code, Dialog, FormGroup, H6, Intent, NumericInput, Pre, Tag } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';

import { HedgeState, TradeFeedHedgeOrder, TradeFeedOrderFill } from '../../../../shared/interfaces/bot';
import { copyToClipboard } from '../../../../shared/utils';
import { TimezoneContext } from '../../../../contexts/timezone';

import { TradeFeedHedgeOrdersListTable } from '../list/table';

interface Props {
  item: TradeFeedHedgeOrder;
  onSetItem: (item: TradeFeedHedgeOrder | null) => void;
  orderFill?: TradeFeedOrderFill;
  onSubmit: (order: TradeFeedHedgeOrder, params: any) => void;
}

export function TradeFeedHedgeOrderManualRetryForm({ item, onSetItem, orderFill, onSubmit }: Props) {
  const { dateFormater, timeFormater } = useContext(TimezoneContext);

  const initForm = {
    internal_id: item.internal_id,
    price: String(item.price?.amount),
    volume: String(item.volume?.amount),
    trading_pair_id: orderFill?.trading_pair_id,
    bot_id: orderFill?.bot_id,
  };

  const [formState, setFormState] = useState(initForm);

  const handleNumberInputChange = (valueAsNumber: number, valueAsString: string, element: HTMLInputElement) => {
    const { name, value } = element;

    setFormState((prevState) => ({
      ...prevState,
      [name]: valueAsString,
    }));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Hedge Time',
        accessor: (order: TradeFeedHedgeOrder, _rowIndex: number) => {
          if (!order.rest_start_bot_ts) {
            return '';
          }

          const dateTime = new Date(order.rest_start_bot_ts + 'Z');
          return timeFormater.format(dateTime);
        },
      },
      {
        Header: 'State',
        accessor: (order: TradeFeedHedgeOrder, _rowIndex: number) => {
          return (
            <div className="text-center">
              {order.hedge_state == HedgeState.Failed && (
                <Tag minimal={true} intent={Intent.DANGER}>
                  {_.upperFirst(HedgeState.Failed)}
                </Tag>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Side',
        accessor: (order: TradeFeedHedgeOrder, _rowIndex: number) => {
          return (
            <div className="text-center">
              {order.side == 'buy' && (
                <Tag minimal={true} intent={Intent.SUCCESS}>
                  {_.upperFirst(order.side)}
                </Tag>
              )}

              {order.side == 'sell' && (
                <Tag minimal={true} intent={Intent.DANGER}>
                  {_.upperFirst(order.side)}
                </Tag>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Price',
        accessor: (order: TradeFeedHedgeOrder, _rowIndex: number) => {
          if (!order.price?.amount) {
            return;
          }

          return `${order.price?.amount} ${order.price?.currency}`;
        },
      },
      {
        Header: 'Volume',
        accessor: (order: TradeFeedHedgeOrder, _rowIndex: number) => {
          if (!order.volume?.amount) {
            return;
          }

          return (
            <>
              <span className="block">
                {order.volume?.amount} {order.volume?.currency}
              </span>

              <span className="block">
                {order.total_value?.amount ? `(${order.total_value?.amount} ${order.total_value?.currency})` : ''}
              </span>
            </>
          );
        },
      },
      {
        Header: 'Internal ID',
        accessor: (order: TradeFeedHedgeOrder, _rowIndex: number) => {
          return (
            <Tooltip2 content={order.internal_id} placement="top">
              <span className="cursor-pointer" onClick={() => copyToClipboard(order.internal_id)}>
                {order.internal_id?.substr(0, 6)}
              </span>
            </Tooltip2>
          );
        },
      },
      {
        Header: 'External ID',
        accessor: (order: TradeFeedHedgeOrder, _rowIndex: number) => {
          return (
            <Tooltip2 content={order.external_id} placement="top">
              <span className="cursor-pointer" onClick={() => copyToClipboard(order.external_id)}>
                {order.external_id?.substr(0, 6)}
              </span>
            </Tooltip2>
          );
        },
      },
    ],
    [dateFormater, timeFormater],
  );

  return (
    <>
      <Dialog
        isOpen={!!item}
        onClose={() => {
          onSetItem(null);
        }}
        title="Manual Retry"
        style={{ minWidth: '700px' }}
      >
        <div className={Classes.DIALOG_BODY}>
          {item && (
            <>
              <TradeFeedHedgeOrdersListTable
                columns={columns}
                data={[item]}
                rowProps={(row) => ({
                  // onDoubleClick: () => itemDetailsViewable && setSelectedItem(row.original)
                })}
              />

              {item.hedge_state === HedgeState.Failed && (
                <div className="mt-5">
                  <H6>Failed reason</H6>

                  <Pre className="whitespace-pre-wrap">{JSON.stringify(item.failed_reason)}</Pre>
                  {item.non_retryable_reason && (
                    <p>Retry skipped. Reason: {<Code className="">{item.non_retryable_reason || 'not_supported_error'}</Code>}</p>
                  )}
                </div>
              )}
            </>
          )}

          <div className="mt-5">
            <p className="my-5">Now retry manually with following settings:</p>

            <FormGroup label="Price" labelFor="price" labelInfo="(required)">
              <NumericInput
                id="text-input"
                name="price"
                value={formState.price}
                onValueChange={handleNumberInputChange}
                placeholder="Price for this retry order"
              />
            </FormGroup>

            <FormGroup label="Volume" labelFor="volume" labelInfo="(required)">
              <NumericInput
                id="text-input"
                name="volume"
                value={formState.volume}
                onValueChange={handleNumberInputChange}
                placeholder="Volume for this retry order"
              />
            </FormGroup>
          </div>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={() => {
                onSetItem(null);
              }}
              small={true}
            >
              Close
            </Button>

            <Button
              intent="primary"
              onClick={() => {
                onSubmit(item, formState);
              }}
            >
              Retry now
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
