import { Menu, Classes, Icon, Position, Button, Intent, MenuDivider, ButtonGroup } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { APP_NAME } from '../../../shared/configs';
import { Bot, BotState } from '../../../shared/interfaces/bot';
import styles from '../navigation.module.css';
import { BotsManagerContext } from '../../../contexts/bots-manager';

import { ConnectivityIndicator } from '../../bot/connectivity-indicator';
import { BotsHealthContext } from '../../../contexts/bots-health';

export function NavigationBotsMenu() {
  const { bots, currentBot, setCurrentBot } = useContext(BotsManagerContext);
  const { botsHealthChecks } = useContext(BotsHealthContext);

  const botsDropdownMenu = (
    <Menu key="menu" large={false}>
      <MenuDivider title="Switching Bots" />

      {bots.map((bot: Bot) => {
        return (
          <li key={bot.id}>
            <Link
              to={`/bots/${bot.app_id}/trading_pairs/active`}
              className={`${currentBot === bot ? 'bp4-active' : ''} relative bp4-menu-item ` + Classes.POPOVER_DISMISS}
              onClick={() => setCurrentBot && setCurrentBot(bot)}
            >
              <Icon icon="application" />
              <div>{bot.name}</div>

              <span className="absolute top-1 right-1">
                <ConnectivityIndicator active={botsHealthChecks[bot.id]?.connectivity_status === BotState.Connected} />
              </span>
            </Link>
          </li>
        );
      })}

      <MenuDivider />

      <Link to={'/bots'} className={'bp4-menu-item ' + Classes.POPOVER_DISMISS} onClick={() => setCurrentBot && setCurrentBot(null)}>
        <Icon icon="applications" />
        <div>Manage Bots</div>
      </Link>

      <Link
        to={'/bots/metrics'}
        className={'bp4-menu-item ' + Classes.POPOVER_DISMISS}
        onClick={() => setCurrentBot && setCurrentBot(null)}
      >
        <Icon icon="timeline-line-chart" />
        <div>Apps Metrics</div>
      </Link>
    </Menu>
  );

  return (
    <ButtonGroup>
      <NavLink
        to={`/bots`}
        onClick={() => setCurrentBot && setCurrentBot(null)}
        className={`bp4-button hover:no-underline ${styles['app-name']}`}
      >
        <h1 className="font-extrabold">{APP_NAME}</h1>
      </NavLink>

      <Popover2
        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        enforceFocus={false}
        content={botsDropdownMenu}
        minimal={false}
        position={Position.BOTTOM_LEFT}
      >
        <>
          {currentBot && (
            <Button
              intent={botsHealthChecks[currentBot.id]?.connectivity_status === BotState.Connected ? Intent.SUCCESS : Intent.NONE}
              text={currentBot.name}
              icon="layers"
              rightIcon="double-caret-vertical"
            />
          )}

          {!currentBot && <Button intent={Intent.NONE} text="Bots" icon="layers" rightIcon="double-caret-vertical" />}
        </>
      </Popover2>
    </ButtonGroup>
  );
}
