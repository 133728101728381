import axios from '../../shared/custom-axios';
import { useState, useEffect } from 'react';

import { ExchangeInfo } from '../../contexts/exchanges-info';
import { useToken } from '../auth/use-token';

export function useExchanges() {
  const [exchangesInfo, setExchangesInfo] = useState<ExchangeInfo[]>([]);
  const [token] = useToken();

  useEffect(() => {
    if (!token) {
      return;
    }

    axios.get<ExchangeInfo[]>('/api/exchanges').then((response) => {
      setExchangesInfo(response.data);
    });
  }, [token]);

  return exchangesInfo;
}
