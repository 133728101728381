import { Button, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';

import { Instrument } from '../../../shared/interfaces/bot';
import { getSymbol, getMarketType } from '../../instrument/utils';

interface Props {
  selectedItem: Instrument | null;
  items: Instrument[];
  disabled?: boolean;
  handleSelect: (item: Instrument) => void;
  query?: string;
  handleQueryChange?: (value: string) => void;
}

const InnerSelect = Select.ofType<Instrument>();

const renderItem: ItemRenderer<Instrument> = (item, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      className="w-80 min-w-max"
      active={modifiers.active}
      key={item.id}
      onClick={handleClick}
      text={
        <>
          <span className="font-bold block">{getSymbol(item)}</span>
          <span className="text-xs block">{getMarketType(item)}</span>
        </>
      }
    ></MenuItem>
  );
};

export function InstrumentSelect({ selectedItem, items, disabled = false, handleSelect, query, handleQueryChange }: Props) {
  return (
    <>
      <InnerSelect
        itemRenderer={renderItem}
        query={query}
        onQueryChange={(value) => handleQueryChange && handleQueryChange(value)}
        onItemSelect={handleSelect}
        items={items}
        disabled={disabled}
        noResults={<MenuItem disabled={true} text="No results." />}
      >
        <Button disabled={disabled} text={selectedItem ? selectedItem.symbol : 'Choose an instrument'} rightIcon="double-caret-vertical" />
      </InnerSelect>
    </>
  );
}
