import React from 'react';
import { Classes, Tooltip2 } from '@blueprintjs/popover2';

import { EmergencyCancelOrder } from '../../../shared/interfaces/bot';
import { copyToClipboard } from '../../../shared/utils';

interface Props {
  isDetailsView: boolean;
  dateFormater: Intl.DateTimeFormat;
  timeFormater: Intl.DateTimeFormat;
}

export function useEmergencyCancelListColumns({ isDetailsView, dateFormater, timeFormater }: Props): any[] {
  const detailsViewOnlyColumns = React.useMemo(
    () => [
      'latency_pre_http',
      'start_time',
      'latency_http_request',
      'exchange_transaction_time',
      'latency_post_processed',
      'exchange_event_time',
      'latency_http_response',
      'end_time',
      'latency_http',
      'latency_response',
      'latency_ec',
    ],
    [],
  );

  const columns = React.useMemo(() => {
    const columns = [
      {
        id: 'id',
        Header: 'ID',
        accessor: (order: EmergencyCancelOrder, _rowIndex: number) => {
          if (order.opportunity?.id) {
            return order.opportunity.id;
          }

          // This field is for backward-compabitility, will be replaced by the 'opportunity' field above
          return order.opportunity_id;
        },
        disableSortBy: true,
      },
      {
        id: 'opportunity.percentage',
        Header: '%',
        accessor: (order: EmergencyCancelOrder, _rowIndex: number) => {
          if (order.opportunity?.percentage) {
            return order.opportunity.percentage;
          }

          // This field is for backward-compabitility, will be replaced by the 'opportunity' field above
          return order.opportunity_percentage;
        },
        disableSortBy: true,
      },
      {
        id: 'opportunity.direction',
        Header: 'Direction',
        accessor: (order: EmergencyCancelOrder, _rowIndex: number) => {
          return order.opportunity?.direction;
        },
        disableSortBy: true,
      },
      {
        id: 'date',
        Header: () => {
          const content = (
            <>
              <p className="m-0">The date at which EC started</p>
              <p className="m-0 text-center">
                <em>(Bot time)</em>
              </p>
            </>
          );

          return (
            <Tooltip2 hoverOpenDelay={420} content={content} placement="top" className={Classes.TOOLTIP2_INDICATOR}>
              Date
            </Tooltip2>
          );
        },
        accessor: (order: EmergencyCancelOrder, _rowIndex: number) => {
          if (!order.session_start_time) {
            return '';
          }

          const dateTime = new Date(order.session_start_time + 'Z');
          return dateFormater.format(dateTime);
        },
        disableSortBy: true,
      },
      {
        id: 'session_start_time',
        Header: () => {
          const content = (
            <>
              <p className="m-0">The time at which EC started</p>
              <p className="m-0 text-center">
                <em>(Bot time)</em>
              </p>
            </>
          );

          return (
            <Tooltip2 hoverOpenDelay={420} content={content} placement="top" className={Classes.TOOLTIP2_INDICATOR}>
              EC Start Time
            </Tooltip2>
          );
        },
        accessor: (order: EmergencyCancelOrder, _rowIndex: number) => {
          if (!order.session_start_time) {
            return '';
          }

          const dateTime = new Date(order.session_start_time + 'Z');

          return timeFormater.format(dateTime);
        },
      },
      {
        id: 'latency_pre_http',
        Header: () => {
          const content = (
            <>
              <p className="m-0">Time taken for EC to do checking</p>
              <p className="m-0 text-center">
                <em>(REST Start Time - EC Start Time)</em>
              </p>
            </>
          );

          return (
            <Tooltip2 hoverOpenDelay={420} content={content} placement="top" className={Classes.TOOLTIP2_INDICATOR}>
              Pre REST Latency (ms)
            </Tooltip2>
          );
        },
        accessor: 'latency_pre_http',
      },
      {
        id: 'start_time',
        Header: () => {
          const content = (
            <>
              <p className="m-0">The time REST request was about to be sent</p>
              <p className="m-0 text-center">
                <em>(Bot time)</em>
              </p>
            </>
          );

          return (
            <Tooltip2 hoverOpenDelay={420} content={content} placement="top" className={Classes.TOOLTIP2_INDICATOR}>
              REST Start Time
            </Tooltip2>
          );
        },
        accessor: (order: EmergencyCancelOrder, _rowIndex: number) => {
          if (!order.start_time) {
            return '';
          }

          const dateTime = new Date(order.start_time + 'Z');
          return timeFormater.format(dateTime);
        },
      },
      {
        id: 'latency_http_request',
        Header: () => {
          const content = (
            <>
              <p className="m-0">Time taken for REST request to reach exchange server and to be done processed"</p>
              <p className="m-0 text-center">
                <em>(Transaction Time - REST Start Time)</em>
              </p>
            </>
          );

          return (
            <Tooltip2 hoverOpenDelay={420} content={content} placement="top" className={Classes.TOOLTIP2_INDICATOR}>
              REST Request Latency (ms)
            </Tooltip2>
          );
        },
        accessor: 'latency_http_request',
      },
      {
        id: 'exchange_transaction_time',
        Header: () => {
          const content = (
            <>
              <p className="m-0">The time at which REST request was processed by exchange</p>
              <p className="m-0 text-center">
                <em>(Exchange time)</em>
              </p>
            </>
          );

          return (
            <Tooltip2 hoverOpenDelay={420} content={content} placement="top" className={Classes.TOOLTIP2_INDICATOR}>
              Transaction Time
            </Tooltip2>
          );
        },
        accessor: (order: EmergencyCancelOrder, _rowIndex: number) => {
          if (!order.exchange_transaction_time) {
            return '';
          }

          const dateTime = new Date(order.exchange_transaction_time + 'Z');
          return timeFormater.format(dateTime);
        },
      },
      {
        id: 'latency_post_processed',
        Header: () => {
          const content = (
            <>
              <p className="m-0">Time taken for exchange to prepare a response message</p>
              <p className="m-0 text-center">
                <em>(Event Time - Transaction Time)</em>
              </p>
            </>
          );

          return (
            <Tooltip2 hoverOpenDelay={420} content={content} placement="top" className={Classes.TOOLTIP2_INDICATOR}>
              Post Processed Latency (ms)
            </Tooltip2>
          );
        },
        accessor: 'latency_post_processed',
      },
      {
        id: 'exchange_event_time',
        Header: () => {
          const content = (
            <>
              <p className="m-0">The time at which response message was about to be sent back</p>
              <p className="m-0 text-center">
                <em>(Exchange time)</em>
              </p>
            </>
          );

          return (
            <Tooltip2 hoverOpenDelay={420} content={content} placement="top" className={Classes.TOOLTIP2_INDICATOR}>
              Event Time
            </Tooltip2>
          );
        },
        accessor: (order: EmergencyCancelOrder, _rowIndex: number) => {
          if (!order.exchange_event_time) {
            return '';
          }

          const dateTime = new Date(order.exchange_event_time + 'Z');
          return timeFormater.format(dateTime);
        },
      },
      {
        id: 'latency_http_response',
        Header: () => {
          const content = (
            <>
              <p className="m-0">Time taken for the response message to get back to our bot</p>
              <p className="m-0 text-center">
                <em>(REST End Time - Event Time)</em>
              </p>
            </>
          );

          return (
            <Tooltip2 hoverOpenDelay={420} content={content} placement="top" className={Classes.TOOLTIP2_INDICATOR}>
              REST Response Latency (ms)
            </Tooltip2>
          );
        },
        accessor: 'latency_http_response',
      },
      {
        id: 'end_time',
        Header: () => {
          const content = (
            <>
              <p className="m-0">The time at which our bot received the response</p>
              <p className="m-0 text-center">
                <em>(Bot time)</em>
              </p>
            </>
          );

          return (
            <Tooltip2 hoverOpenDelay={420} content={content} placement="top" className={Classes.TOOLTIP2_INDICATOR}>
              REST End Time
            </Tooltip2>
          );
        },
        accessor: (order: EmergencyCancelOrder, _rowIndex: number) => {
          if (!order.end_time) {
            return '';
          }

          const dateTime = new Date(order.end_time + 'Z');
          return timeFormater.format(dateTime);
        },
      },
      {
        id: 'latency_http',
        Header: () => {
          const content = (
            <>
              <p className="m-0">Time taken for the whole REST request/response trip</p>
              <p className="m-0 text-center">
                <em>(REST End Time - REST Start Time)</em>
              </p>
            </>
          );

          return (
            <Tooltip2 hoverOpenDelay={420} content={content} placement="top" className={Classes.TOOLTIP2_INDICATOR}>
              REST Latency (ms)
            </Tooltip2>
          );
        },
        accessor: 'latency_http',
      },
      {
        id: 'latency_response',
        Header: () => {
          const content = (
            <>
              <p className="m-0">Time taken for a response reached our bot after request was processed by exchange</p>
              <p className="m-0 text-center">
                <em>(REST End Time - Transaction Time)</em>
              </p>
            </>
          );

          return (
            <Tooltip2 hoverOpenDelay={420} content={content} placement="top" className={Classes.TOOLTIP2_INDICATOR}>
              Response Latency (ms)
            </Tooltip2>
          );
        },
        accessor: 'latency_response',
      },
      {
        id: 'latency_ec',
        Header: () => {
          const content = (
            <>
              <p className="m-0">Time taken for the whole EC process of this order</p>
              <p className="m-0 text-center">
                <em>(REST End Time - EC Start Time)</em>
              </p>
            </>
          );

          return (
            <Tooltip2 hoverOpenDelay={420} content={content} placement="top" className={Classes.TOOLTIP2_INDICATOR}>
              EC Latency (ms)
            </Tooltip2>
          );
        },
        accessor: 'latency_ec',
      },
      {
        id: 'external_id',
        Header: 'External ID',
        accessor: (order: EmergencyCancelOrder, _rowIndex: number) => {
          return (
            <Tooltip2 content={order.external_id} placement="top">
              <span className="cursor-pointer" onClick={() => copyToClipboard(order.external_id)}>
                {order.external_id?.substr(0, 6)}
              </span>
            </Tooltip2>
          );
        },
      },
      {
        id: 'internal_id',
        Header: 'Internal ID',
        accessor: (order: EmergencyCancelOrder, _rowIndex: number) => {
          return (
            <Tooltip2 content={order.internal_id} placement="top">
              <span className="cursor-pointer" onClick={() => copyToClipboard(order.internal_id)}>
                {order.internal_id.substr(0, 6)}
              </span>
            </Tooltip2>
          );
        },
      },
      {
        id: 'side',
        Header: 'Side',
        accessor: 'side',
      },
      {
        id: 'original_price',
        Header: 'Order Price',
        accessor: 'original_price',
      },
      {
        id: 'ec_cancel_price',
        Header: 'EC Price (%)',
        accessor: (order: EmergencyCancelOrder, _rowIndex: number) => {
          return `${order.ec?.cancel_price} (${order.ec?.percentage}%)`;
        },
        disableSortBy: true,
      },
      {
        id: 'offset_ec_cancel_price',
        Header: 'Offset EC Price (%)',
        accessor: (order: EmergencyCancelOrder, _rowIndex: number) => {
          return `${order.offset_ec?.cancel_price} (${order.offset_ec?.percentage}%)`;
        },
        disableSortBy: true,
      },
      {
        id: 'bbo_ec_cancel_price',
        Header: 'BBO EC Price (%)',
        accessor: (order: EmergencyCancelOrder, _rowIndex: number) => {
          return `${order.bbo_ec?.cancel_price} (${order.bbo_ec?.percentage}%)`;
        },
        disableSortBy: true,
      },
      {
        id: 'offset_ec_best_price',
        Header: 'Best Bid/Ask',
        accessor: (order: EmergencyCancelOrder, _rowIndex: number) => {
          return order.offset_ec?.best_price;
        },
        disableSortBy: true,
      },
      {
        id: 'bbo_ec_best_price',
        Header: 'BBO',
        accessor: (order: EmergencyCancelOrder, _rowIndex: number) => {
          return order.bbo_ec?.best_price;
        },
        disableSortBy: true,
      },
      {
        id: 'bbo_price_diff',
        Header: 'BBO Price Diff',
        accessor: 'bbo_price_diff',
      },
    ];

    if (isDetailsView) {
      // Details view shows all columns
      return columns;
    } else {
      // Compact view shows all columns except some timestamp and latency columns
      return columns.filter(({ id }) => !detailsViewOnlyColumns.includes(id));
    }
  }, [isDetailsView, dateFormater, timeFormater, detailsViewOnlyColumns]);

  return columns;
}
