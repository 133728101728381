import { useParams, Params } from 'react-router-dom';

export function BotsShowPage() {
  let { appId } = useParams<keyof Params>();

  console.log(appId, 'AAAAAA');

  return (
    <>
      <h3>Bot: {appId}</h3>
    </>
  );
}
