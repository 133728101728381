import { useContext, useState } from 'react';
import { Tab, Tabs } from '@blueprintjs/core';

import { ExchangesInfoContext } from '../../../contexts/exchanges-info';
import { DexListPanel } from './panel';

export function DexList() {
  const supportedExchanges = useContext(ExchangesInfoContext);
  const [selectedTab, setSelectedTab] = useState('');

  const dexes = supportedExchanges.filter(({ type }) => type === 'dex');

  const handleSelectTab = (tab: string) => {
    setSelectedTab(tab);
  };

  return (
    <>
      <h2 className="text-lg font-bold my-1">Decentralized Exchanges (DEXes)</h2>

      <Tabs
        animate={true}
        id="TabsExample"
        renderActiveTabPanelOnly={true}
        selectedTabId={selectedTab || dexes[0]?.id}
        onChange={handleSelectTab}
      >
        {dexes.map((exchange) => (
          <Tab id={exchange.id} key={exchange.id} title={exchange.name} panel={<DexListPanel item={exchange} />} />
        ))}
      </Tabs>
    </>
  );
}
