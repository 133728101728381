import { Alignment, FormGroup, HTMLSelect, Icon, NumericInput, Switch, Tooltip } from '@blueprintjs/core';
import { ChangeEvent, FormEvent } from 'react';

import { WickCatcherExitSettings } from '../../../shared/interfaces/bot';
import { TIMEFRAMES_IN_MINUTES } from './form';

export interface PrimaryExitSettingFormState {
  percentage: number;
  max_individual: number | undefined;
  debounce_amendment: number | undefined;
  movement_index: number | undefined;
  tier: number;
  movement_time_frame: number;
  secondary_hedge_tier: number;
}

interface Props {
  formState: WickCatcherExitSettings;
  disabled?: boolean;
  onInputChange: (event: FormEvent<HTMLInputElement | HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>) => void;
  onNumberInputChange: (
    withCommas?: boolean,
  ) => (valueAsNumber: number, valueAsString: string, inputElement: HTMLInputElement | null) => void;
  onSwitchChange: (evt: any) => void;
}

export function PrimaryExitSettingsForm({ formState, disabled = false, onInputChange, onNumberInputChange, onSwitchChange }: Props) {
  return (
    <div>
      <div className="border border-gray-300 rounded-md py-5 px-5 mb-4 relative">
        <div className="grid grid-cols-3 gap-4">
          <FormGroup
            label={
              <div className="flex flex-row items-center" style={{ height: '18px' }}>
                Exit Percentage
                <Tooltip content="Exit order % parameter" placement="top">
                  <Icon icon="help" size={10} className="ml-1 mb-1" />
                </Tooltip>
              </div>
            }
            labelFor="Exit Percentage"
          >
            <NumericInput
              disabled={disabled}
              id="percentage"
              name="percentage"
              fill={true}
              className="w-4/5"
              value={formState.percentage?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
              onValueChange={onNumberInputChange(false)}
              buttonPosition="none"
              placeholder="E.g. 1"
            />
          </FormGroup>

          <FormGroup label="Exit Max Individual" labelFor="max_individual" labelInfo="" helperText="">
            <NumericInput
              disabled={disabled}
              id="max_individual"
              name="max_individual"
              fill={true}
              className="w-4/5"
              value={formState.max_individual?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
              onValueChange={onNumberInputChange(true)}
              buttonPosition="none"
              placeholder="E.g. 10000"
            />
          </FormGroup>

          <FormGroup
            label={
              <div className="flex flex-row items-center" style={{ height: '18px' }}>
                Exit Debounce
                <Tooltip content="The debounce to amend exit order" placement="top">
                  <Icon icon="help" size={10} className="ml-1 mb-1" />
                </Tooltip>
              </div>
            }
            labelFor="debounce_amendment"
            helperText=""
          >
            <NumericInput
              disabled={disabled}
              id="debounce_amendment"
              name="debounce_amendment"
              fill={true}
              value={formState.debounce_amendment?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
              onValueChange={onNumberInputChange(true)}
              buttonPosition="none"
              placeholder="E.g. 10000"
            />
          </FormGroup>
        </div>

        <div className="grid grid-cols-3 gap-4">
          <FormGroup label="Exit Tier" labelFor="tier" labelInfo="" helperText="">
            <NumericInput
              disabled={disabled}
              id="tier"
              name="tier"
              fill={true}
              className="w-4/5"
              value={formState.tier?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
              onValueChange={onNumberInputChange(false)}
              buttonPosition="none"
              placeholder="E.g. 1"
            />
          </FormGroup>

          <FormGroup label="Exit Movement Index" labelFor="movement_index" labelInfo="" helperText="">
            <NumericInput
              disabled={disabled}
              id="movement_index"
              name="movement_index"
              fill={true}
              className="w-4/5"
              value={formState.movement_index?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
              onValueChange={onNumberInputChange(false)}
              buttonPosition="none"
              placeholder="E.g. 1.5"
            />
          </FormGroup>

          <FormGroup label="Exit Movement Timeframe" labelFor="movement_time_frame" labelInfo="" helperText="">
            <HTMLSelect
              className="w-4/5"
              disabled={disabled}
              id="movement_time_frame"
              name="movement_time_frame"
              value={formState.movement_time_frame}
              onChange={onInputChange}
            >
              {TIMEFRAMES_IN_MINUTES.map(({ text, value }, index) => (
                <option value={value} key={index}>
                  {text}
                </option>
              ))}
            </HTMLSelect>
          </FormGroup>

          <FormGroup
            label={
              <div className="flex flex-row items-center" style={{ height: '18px' }}>
                Secondary Hedge Tier
                <Tooltip
                  content="If Overall market movement is bigger than this setting then we will hedge and exit on secondary market"
                  placement="top"
                >
                  <Icon icon="help" size={10} className="ml-1 mb-1" />
                </Tooltip>
              </div>
            }
            labelFor="Secondary Hedge Tier"
          >
            <NumericInput
              disabled={disabled}
              id="secondary_hedge_tier"
              name="secondary_hedge_tier"
              fill={true}
              className="w-4/5"
              value={formState.secondary_hedge_tier?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
              onValueChange={onNumberInputChange(false)}
              buttonPosition="none"
              placeholder="E.g. 1"
            />
          </FormGroup>
        </div>
      </div>

      <h4 className="text-base font-medium my-1">Ratio Settings</h4>
      <div className="border border-gray-300 rounded-md py-5 px-5 mb-4 relative">
        <div className="grid grid-cols-3 gap-4">
          <FormGroup
            label={
              <div className="flex flex-row items-center" style={{ height: '18px' }}>
                Activate Ratio Distance
                <Tooltip content="Should be a number in bip, ex: 50" placement="top">
                  <Icon icon="help" size={10} className="ml-1 mb-1" />
                </Tooltip>
              </div>
            }
            labelFor="Activate Ratio Distance"
          >
            <NumericInput
              disabled={disabled}
              id="activate_ratio_distance"
              name="activate_ratio_distance"
              fill={true}
              className="w-4/5"
              value={formState.activate_ratio_distance?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
              onValueChange={onNumberInputChange(false)}
              buttonPosition="none"
              placeholder="E.g. 50"
            />
          </FormGroup>

          <FormGroup label="Implied Ratio Multiplier" labelFor="implied_ratio_multiplier" labelInfo="" helperText="">
            <NumericInput
              disabled={disabled}
              id="implied_ratio_multiplier"
              name="implied_ratio_multiplier"
              fill={true}
              className="w-4/5"
              value={formState.implied_ratio_multiplier?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
              onValueChange={onNumberInputChange(false)}
              buttonPosition="none"
              placeholder="E.g. 20"
            />
          </FormGroup>

          <FormGroup
            label={
              <div className="flex flex-row items-center" style={{ height: '18px' }}>
                EC Ratio Change
                <Tooltip content="Ratio change for EC" placement="top">
                  <Icon icon="help" size={10} className="ml-1 mb-1" />
                </Tooltip>
              </div>
            }
            labelFor="ec_ratio_change"
            helperText=""
          >
            <NumericInput
              disabled={disabled}
              id="ec_ratio_change"
              name="ec_ratio_change"
              fill={true}
              value={formState.ec_ratio_change?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
              onValueChange={onNumberInputChange(false)}
              buttonPosition="none"
              placeholder="E.g. 200"
            />
          </FormGroup>
        </div>

        <div className="grid grid-cols-3 gap-4">
          <FormGroup label="Tick Parameter" labelFor="tier" labelInfo="" helperText="">
            <NumericInput
              disabled={disabled}
              id="tick_parameter"
              name="tick_parameter"
              fill={true}
              className="w-4/5"
              value={formState.ratio_tick_size?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
              onValueChange={onNumberInputChange(false)}
              buttonPosition="none"
              placeholder="E.g. 1"
            />
          </FormGroup>

          <FormGroup label="Volume Multiplier" labelFor="movement_index" labelInfo="" helperText="">
            <NumericInput
              disabled={disabled}
              id="implied_volume_multiplier"
              name="implied_volume_multiplier"
              fill={true}
              className="w-4/5"
              value={formState.implied_volume_multiplier?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
              onValueChange={onNumberInputChange(false)}
              buttonPosition="none"
              placeholder="E.g. 1.5"
            />
          </FormGroup>

          <FormGroup label="EC Replacement Debounce" labelFor="ec_replacement_debounce" labelInfo="" helperText="">
            <NumericInput
              disabled={disabled}
              id="ec_replacement_debounce"
              name="ec_replacement_debounce"
              fill={true}
              className="w-4/5"
              value={formState.ec_replacement_debounce?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
              onValueChange={onNumberInputChange(false)}
              buttonPosition="none"
              placeholder="E.g. 10000"
            />
          </FormGroup>

          <Switch
            name="disable_immediately_exit"
            checked={!formState.disable_immediately_exit}
            onChange={onSwitchChange}
            labelElement={<strong>Immediately Exit</strong>}
            inline={true}
            alignIndicator={Alignment.LEFT}
          />
        </div>
      </div>
    </div>
  );
}
