import { useCallback, useEffect, useState } from 'react';
import { ControlGroup, Button, NumericInput, HTMLSelect, NonIdealState, InputGroup, Icon } from '@blueprintjs/core';
import { usePagination, useSortBy, useTable, UseTableCellProps } from 'react-table';

import { Exchange, Instrument } from '../../../shared/interfaces/bot';
import sharedStyles from '../../../app/app.module.css';
import { ExchangeSelect } from '../../common/exchange-select/exchange-select';
import { DEFAULT_SORT_BY, getFirstSortBy } from '../../../shared/utils';

interface Props {
  columns: any;
  data: any;
  fetchData: (opts: any) => void;
  loading: boolean;
  needToRefresh: boolean;
  totalEntries: number;
  pageCount: number;
  selectItem?: (item: Instrument) => void;
}

export function InstrumentListTable({
  columns,
  data,
  fetchData,
  loading,
  needToRefresh,
  totalEntries,
  pageCount: controlledPageCount,
  selectItem,
}: Props) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20, sortBy: [DEFAULT_SORT_BY] },
      manualPagination: true,
      manualSortBy: true,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination,
  );

  const [searchTerm, setSearchTerm] = useState('');
  const defaultFilterTerms = { mainExchange: '', subExchange: '' };
  const [filterTerms, setFilterTerms] = useState<{ mainExchange: string; subExchange: string }>(defaultFilterTerms);
  const { mainExchange, subExchange } = filterTerms;

  const handleSearch = useCallback(() => {
    if (pageIndex > 0) {
      gotoPage(0);
    } else {
      const firstSortBy = getFirstSortBy(sortBy);

      fetchData({ pageIndex, pageSize, searchTerm, filterTerms, sortBy: firstSortBy });
    }
  }, [fetchData, pageIndex, pageSize, gotoPage, searchTerm, filterTerms, sortBy]);

  useEffect(() => {
    const firstSortBy = getFirstSortBy(sortBy);

    fetchData({ filterTerms, pageIndex, pageSize, searchTerm, sortBy: firstSortBy });
  }, [fetchData, pageIndex, pageSize, needToRefresh, searchTerm, filterTerms, sortBy]);

  return (
    <>
      <div className="pagination grid grid-cols-2 gap-4">
        <div className="col-span-1">
          <ControlGroup vertical={false}>
            <ExchangeSelect
              mainExchange={mainExchange}
              subExchange={subExchange}
              handleSelect={(item: Exchange) => {
                setFilterTerms({ mainExchange: item.main_exchange, subExchange: item.sub_exchange });
              }}
              hasAllOption={true}
            />

            <InputGroup
              value={searchTerm}
              placeholder="Filter by symbol, base currency, quote currency..."
              onChange={(event) => {
                setSearchTerm(event.currentTarget.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
              fill={true}
            ></InputGroup>

            <Button type="submit" icon="search" onClick={handleSearch}>
              Filter
            </Button>
          </ControlGroup>
        </div>

        <div className="col-span-1 grid justify-items-end">
          <ControlGroup vertical={false} fill={false}>
            <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </Button>

            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </Button>

            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </Button>

            <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {'>>'}
            </Button>

            <Button disabled={true}>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </Button>

            <NumericInput
              value={pageIndex + 1}
              defaultValue={pageIndex + 1}
              onValueChange={(valueAsNumber) => {
                const page = valueAsNumber ? valueAsNumber - 1 : 0;
                gotoPage(page);
              }}
            ></NumericInput>

            <HTMLSelect
              fill={true}
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </HTMLSelect>
          </ControlGroup>

          <p className="mt-5">
            Showing {page.length} of {totalEntries} items
          </p>
        </div>
      </div>

      {page.length <= 0 && (
        <div className="mt-5 w-full overflow-auto">
          <NonIdealState icon="folder-open" title="No items found" description="Please consider clear any filters and hit Filter button." />
        </div>
      )}

      {page.length > 0 && (
        <div className="mt-5 w-full overflow-auto">
          <table {...getTableProps()} className="border table-fixed text-xs min-w-full">
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className="border px-4 py-1 text-light-blue-600">
                      <div className="flex items-center justify-center">
                        {column.render('Header')}
                        <span className="ml-1">
                          {column.isSorted ? column.isSortedDesc ? <Icon icon="caret-down"></Icon> : <Icon icon="caret-up"></Icon> : ''}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {!loading && (
              <tbody {...getTableBodyProps()} className="text-center">
                {page.map((row: any, i: number) => {
                  prepareRow(row);

                  return (
                    <tr {...row.getRowProps()} className={sharedStyles.item} onDoubleClick={() => selectItem && selectItem(row.original)}>
                      {row.cells.map((cell: UseTableCellProps<Instrument>) => {
                        return (
                          <td {...cell.getCellProps()} className="border px-4 py-1 text-light-blue-600">
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      )}
    </>
  );
}
