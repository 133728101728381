import _ from 'lodash';
import { Button, Callout, ControlGroup, FormGroup, HTMLSelect, InputGroup, Intent, Menu, MenuItem, Tag } from '@blueprintjs/core';
import { useCallback, useEffect, useState } from 'react';
import axios from '../../../shared/custom-axios';

import { Account, AccountsListResponse, Exchange, Instrument, InstrumentsListResponse } from '../../../shared/interfaces/bot';
import { ExchangeSelect } from '../exchange-select/exchange-select';
import { getSymbol, getMarketType } from '../../instrument/utils';

export interface Pair {
  id: number | undefined;
  primary: AccountInstrument | undefined;
  secondary: AccountInstrument | undefined;
  inserted_at: string;
}

export interface AccountInstrument {
  account: Account;
  instrument: Instrument;
}

interface Props {
  selectedItems: Pair[];
  editingItem: Pair | undefined;
  selectEditingItem: (item: Pair) => void;
  updateItem: (pair: Pair, accountInstrument: AccountInstrument, side: string) => void;
  removeItemSide: (pair: Pair, side: string) => void;
  removeItem: (pair: Pair) => void;
  // toggleSelectItem: (item: AccountInstrument) => void;
  // addNewItem: (item: AccountInstrument) => void;
  handleAddNext: (editingPair: Pair | undefined, newAccountInstrument: AccountInstrument, prioritySide?: string) => void;
}

export interface AccountInstrumentFormState {
  main_exchange: string;
  sub_exchange: string;
  exchange_type: 'cex' | 'dex';
  account_id: number | undefined;
}

export function PairsSelect({
  selectedItems,
  editingItem,
  selectEditingItem: selectUpdatingItem,
  removeItemSide,
  updateItem,
  removeItem,
  handleAddNext,
}: Props) {
  const [formState, setFormState] = useState<AccountInstrumentFormState>({
    main_exchange: '',
    sub_exchange: '',
    exchange_type: 'cex',
    account_id: undefined,
  });

  const { main_exchange: mainExchange, sub_exchange: subExchange } = formState;

  const [instruments, setInstruments] = useState<Instrument[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>(undefined);
  const [selectedPrimaryInstrumentsMap, setSelectedPrimaryInstrumentsMap] = useState<{ [index: string]: Pair }>({});
  const [searchTerm, setSearchTerm] = useState('');

  const fetchInstruments = useCallback(
    _.debounce((mainExchange, subExchange, searchTerm, onHandler) => {
      let path = `/api/instruments?main_exchange=${mainExchange}&sub_exchange=${subExchange}&page_size=200`;

      if (searchTerm && searchTerm !== '') {
        path = `${path}&search=${searchTerm}`;
      }

      axios.get<InstrumentsListResponse>(path).then((response) => {
        const { entries } = response.data;

        if (searchTerm && searchTerm !== '') {
          entries.sort((o1, o2) => {
            if (o1.symbol.startsWith(searchTerm) && o2.symbol.startsWith(searchTerm)) {
              return o1.id - o2.id;
            }

            if (o1.symbol.startsWith(searchTerm) && !o2.symbol.startsWith(searchTerm)) {
              return -1;
            }

            if (!o1.symbol.startsWith(searchTerm) && o2.symbol.startsWith(searchTerm)) {
              return 1;
            }
            return o1.id - o2.id;
          });
        }

        onHandler(entries);
      });
    }, 500),
    [],
  );

  const handleSearch = useCallback(() => {
    fetchInstruments(mainExchange, subExchange, searchTerm, setInstruments);
  }, [mainExchange, subExchange, searchTerm, setInstruments, fetchInstruments]);

  useEffect(() => {
    let map: { [index: string]: Pair } = {};

    selectedItems.forEach((item: Pair) => {
      if (item.primary) {
        let id = item.primary.instrument.id;
        map[id] = item;
      }
    });

    setSelectedPrimaryInstrumentsMap(map);
  }, [selectedItems]);

  return (
    <div className="pairs-select">
      <div className="grid grid-cols-3 gap-10">
        <div>
          <FormGroup label="Exchange" labelInfo="(required)" className="m-0">
            <ExchangeSelect
              mainExchange={formState.main_exchange}
              subExchange={formState.sub_exchange}
              handleSelect={(selectedItem: Exchange) => {
                if (mainExchange !== selectedItem.main_exchange || subExchange !== selectedItem.sub_exchange) {
                  if (selectedAccount) {
                    fetchInstruments(selectedItem.main_exchange, selectedItem.sub_exchange, searchTerm, setInstruments);
                  }

                  axios
                    .get<AccountsListResponse>(
                      `/api/accounts?main_exchange=${selectedItem.main_exchange}&sub_exchange=${selectedItem.sub_exchange}`,
                    )
                    .then((response) => {
                      setAccounts(response.data.data);
                    });
                }

                setFormState((prevState) => {
                  return {
                    ...prevState,
                    exchange_type: selectedItem.type,
                    main_exchange: selectedItem.main_exchange,
                    sub_exchange: selectedItem.sub_exchange,
                  };
                });
              }}
            />
          </FormGroup>

          <FormGroup label="Account" labelInfo="(required)" className="m-0">
            <HTMLSelect
              disabled={accounts.length < 1}
              id="account_id"
              name="account_id"
              value={formState.account_id}
              onChange={(event) => {
                const { name, value } = event.currentTarget;
                let selectedAccount = accounts.find((item) => item.id === Number(value));

                setSelectedAccount(selectedAccount);

                if (selectedAccount) {
                  fetchInstruments(mainExchange, subExchange, searchTerm, setInstruments);
                } else {
                  setInstruments([]);
                }

                setFormState((prevState) => {
                  return {
                    ...prevState,
                    [name]: value,
                  };
                });
              }}
            >
              <option value="">Choose an account</option>
              {accounts.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </HTMLSelect>
          </FormGroup>
        </div>

        <div className="col-span-2 relative">
          <div className="-mt-8">
            <Callout icon="help">
              <p className="m-0 text-xs">Choose exchange and account first on the left first</p>

              <p className="my-1 text-xs font-bold">To create a new pair:</p>

              <p className="my-1 ml-5 text-xs">
                1. Hovering on an instrument on the left list and click on button
                <Button small={true} icon="arrow-right" minimal={true} /> to select it as <span className="font-bold">primary</span> for the
                new pair
              </p>

              <p className="my-1 ml-5 text-xs">
                2. Hovering on another instrument on the left list and click on button
                <Button small={true} icon="arrow-right" minimal={true} /> to select it as <span className="font-bold">secondary</span> for
                the new pair
              </p>

              <p className="my-1 ml-5 text-xs">
                To remove an instrument on the right list, hover on the desired instrument and click on button{' '}
                <Button small={true} icon="cross" minimal={true} />{' '}
              </p>

              <p className="m-0 mt-0.5 text-xs italic">Double-click on the left list (to select an instrument) also works</p>
            </Callout>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-10 mt-1">
        <div>
          <ControlGroup vertical={false}>
            <InputGroup
              value={searchTerm}
              placeholder="Filter by symbol, base currency, quote currency..."
              onChange={(event) => {
                setSearchTerm(event.currentTarget.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
              fill={true}
              small={true}
            ></InputGroup>

            <Button type="submit" icon="search" onClick={handleSearch} small={true}>
              Filter
            </Button>
          </ControlGroup>
        </div>
        <div></div>
      </div>

      <div className="grid grid-cols-3 gap-10">
        <div className="">
          <p className="my-1 text-xs opacity-60">Found {instruments.length} items</p>

          <div className="">
            <Menu className="overflow-auto border border-gray-300 rounded-md" style={{ height: '420px' }}>
              {instruments.map((item) => {
                let instrumentId = item.id;

                return (
                  <MenuItem
                    key={instrumentId}
                    className="group"
                    text={
                      <>
                        <span className=" block relative">
                          <span className="">
                            <span className="font-bold">{getSymbol(item)}</span>

                            {selectedPrimaryInstrumentsMap[instrumentId] && (
                              <span className="float-right font-normal text-gray-500 text-xxs">Selected as primary</span>
                            )}

                            <span className="text-xs block">{getMarketType(item)}</span>
                          </span>

                          <span className="w-full text-center hidden group-hover:block absolute right-0 top-2">
                            <Button
                              small={true}
                              icon="arrow-right"
                              onClick={() => {
                                let nextEditingItem = selectedItems.find((item, i) => !item.primary || !item.secondary);

                                if (nextEditingItem) {
                                  selectUpdatingItem(nextEditingItem);
                                } else {
                                  selectUpdatingItem(selectedItems[selectedItems.length - 1]);
                                }
                                handleAddNext(nextEditingItem, { instrument: item, account: selectedAccount } as AccountInstrument);
                              }}
                              intent={Intent.PRIMARY}
                            />
                          </span>
                        </span>
                      </>
                    }
                    active={!!selectedPrimaryInstrumentsMap[instrumentId]}
                    onDoubleClick={() => {
                      let nextEditingItem = selectedItems.find((item, i) => !item.primary || !item.secondary);

                      if (nextEditingItem) {
                        selectUpdatingItem(nextEditingItem);
                      } else {
                        selectUpdatingItem(selectedItems[selectedItems.length - 1]);
                      }
                      handleAddNext(nextEditingItem, { instrument: item, account: selectedAccount } as AccountInstrument);
                    }}
                  ></MenuItem>
                );
              })}
            </Menu>
          </div>
        </div>

        <div className="col-span-2">
          <p className="my-1 text-xs opacity-60">
            Selected {selectedItems.length} pairs (
            {
              _.uniqBy(
                selectedItems.flatMap((i) => i.primary),
                (i) => i?.instrument.id,
              ).length
            }{' '}
            unique markets on primary and{' '}
            {
              _.uniqBy(
                selectedItems.flatMap((i) => i.secondary),
                (i) => i?.instrument.id,
              ).length
            }{' '}
            unique markets on secondary)
          </p>

          <div className="selected-pairs">
            <Menu className="overflow-auto border border-gray-300 rounded-md" style={{ height: '420px' }}>
              {selectedItems.map((item, index) => {
                const key = item.id ? item.id : index;
                const timeNow = new Date();
                const insertedAt = new Date(item.inserted_at + 'Z');
                const ageInSeconds = (timeNow.getTime() - insertedAt.getTime()) / 1000;
                const isNew = !item.inserted_at || ageInSeconds <= 60;

                return (
                  <MenuItem
                    key={key}
                    className="pair"
                    selected={item === editingItem}
                    text={
                      <div className="relative grid grid-cols-12">
                        <div className="col-span-2 grid grid-cols-12 gap-2 text-xxs text-gray-500 cursor-text">
                          <div className="col-span-9 grid grid-cols-2">
                            <span className="block pl-5">{index + 1}</span>
                            {item.id && <span className="block">#{item.id}</span>}
                            <span className="block col-span-2 text-center">
                              {isNew && (
                                <Tag intent={Intent.PRIMARY} round={true} minimal={false} fill={true}>
                                  New
                                </Tag>
                              )}
                            </span>
                          </div>

                          <div className="col-span-3"></div>
                        </div>

                        <div className="col-span-10 content grid grid-cols-2 gap-8">
                          {item.primary && (
                            <div className="group whitespace-pre-wrap">
                              {/* <span className="absolute -left-2 z-10">{index + 1}</span> */}
                              <span className="font-bold block relative">
                                {getSymbol(item.primary.instrument)}

                                <span className="hidden group-hover:flex absolute w-full top-4 justify-center">
                                  <span className="mx-1">
                                    <Button
                                      small={true}
                                      icon="double-chevron-down"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        let nextEditingItem = selectedItems.find((item, i) => i >= index && !item.primary);

                                        if (nextEditingItem) {
                                          selectUpdatingItem(nextEditingItem);
                                        } else {
                                          selectUpdatingItem(selectedItems[selectedItems.length - 1]);
                                        }

                                        handleAddNext(
                                          nextEditingItem,
                                          {
                                            instrument: item.primary?.instrument,
                                            account: item.primary?.account,
                                          } as AccountInstrument,
                                          'primary',
                                        );
                                      }}
                                      intent={Intent.PRIMARY}
                                    />
                                  </span>

                                  <span className="mx-1">
                                    <Button
                                      small={true}
                                      icon="cross"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        removeItemSide(item, 'primary');
                                      }}
                                      intent={Intent.DANGER}
                                    />
                                  </span>
                                </span>
                              </span>

                              <span className="text-xs block">
                                {item.primary && getMarketType(item.primary.instrument)} | {item.primary?.account.name}
                              </span>
                            </div>
                          )}

                          {!item.primary && <div className="whitespace-pre-wrap border border-dashed border-gray-400"></div>}

                          {item.secondary && (
                            <div className="group whitespace-pre-wrap">
                              <span className="font-bold block relative">
                                {getSymbol(item.secondary.instrument)}

                                <span className="hidden group-hover:flex w-full absolute top-4 justify-center">
                                  <span className="mx-1">
                                    <Button
                                      small={true}
                                      icon="double-chevron-down"
                                      onClick={(e) => {
                                        e.stopPropagation();

                                        let nextEditingItem = selectedItems.find((item, i) => i >= index && !item.secondary);

                                        if (nextEditingItem) {
                                          selectUpdatingItem(nextEditingItem);
                                        } else {
                                          selectUpdatingItem(selectedItems[selectedItems.length - 1]);
                                        }

                                        handleAddNext(
                                          nextEditingItem,
                                          {
                                            instrument: item.secondary?.instrument,
                                            account: item.secondary?.account,
                                          } as AccountInstrument,
                                          'secondary',
                                        );
                                      }}
                                      intent={Intent.PRIMARY}
                                    />
                                  </span>

                                  <span className="mx-1">
                                    <Button
                                      small={true}
                                      icon="cross"
                                      onClick={(e) => {
                                        e.stopPropagation();

                                        removeItemSide(item, 'secondary');
                                      }}
                                      intent={Intent.DANGER}
                                    />
                                  </span>
                                </span>
                              </span>

                              <span className="text-xs block">
                                {item.secondary && getMarketType(item.secondary.instrument) + '|'} {item.secondary?.account.name}
                              </span>
                            </div>
                          )}

                          {!item.secondary && <div className="whitespace-pre-wrap border border-dashed border-gray-400"></div>}
                        </div>
                      </div>
                    }
                    onClick={() => {
                      selectUpdatingItem(item);
                    }}
                  ></MenuItem>
                );
              })}
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
}
