import _ from 'lodash';
import { TradeFeedDisplayView, TradeFeedHedgeUpdate, TradeFeedOrderFill } from '../../../../shared/interfaces/bot';
import { TradeFeedListLatestTable } from './table';
import { SortingRule } from 'react-table';

interface Props {
  columns: any[];
  data: TradeFeedOrderFill[] | TradeFeedHedgeUpdate[];
  initSortBy?: SortingRule<any>[];
  pageIndex?: number;
  pageSize?: number;
  totalPage?: number;
  totalEntries?: number;
  onChangePage?: (pageIndex: number, pageSize: number, sortBy: any) => void;
  displayView?: TradeFeedDisplayView;
  changeDisplayView?: (view: TradeFeedDisplayView) => void;
  softSelectedItem: TradeFeedOrderFill | null;
  // Item selected by single click
  softSelectItem?: (item: TradeFeedOrderFill | null) => void;
  // Item selected by double click
  hardSelectItem?: (item: TradeFeedOrderFill) => void;
  showCounter?: boolean;
  showPagination?: boolean;
  showColor?: boolean;
  showPartialData?: boolean;
  isLoading: boolean;
}

export function TradeFeedListLatest({
  columns,
  data,
  initSortBy,
  pageIndex,
  pageSize,
  totalPage,
  totalEntries,
  onChangePage,
  displayView,
  changeDisplayView,
  softSelectedItem,
  softSelectItem,
  hardSelectItem,
  showPagination = false,
  showCounter = true,
  showColor = false,
  showPartialData = false,
  isLoading = false,
}: Props) {
  if (!_.isNumber(pageIndex) || !_.isNumber(pageSize)) return <></>;

  return (
    <>
      <TradeFeedListLatestTable
        columns={columns}
        data={data}
        initSortBy={initSortBy}
        displayView={displayView}
        changeDisplayView={changeDisplayView}
        softSelectedItem={softSelectedItem}
        softSelectItem={softSelectItem}
        hardSelectItem={hardSelectItem}
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalPage={totalPage}
        totalEntries={totalEntries}
        onChangePage={onChangePage}
        showPagination={showPagination}
        showCounter={showCounter}
        showColor={showColor}
        showPartialData={showPartialData}
        isLoading={isLoading}
      />
    </>
  );
}
