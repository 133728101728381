import React, { useState } from 'react';
import axios from '../../../shared/custom-axios';
import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';

import { Exchange } from '../../../shared/interfaces/bot';
import { AppToaster } from '../../../shared/app-toaster';
import { ExchangeSelect } from '../../common/exchange-select/exchange-select';

enum SyncStatus {
  SYNCED = 'synced',
  SYNCING = 'syncing',
}

interface Props {
  afterSync: () => void;
}

export function SyncButton({ afterSync }: Props) {
  const [openDialog, setOpenDialog] = useState(false);
  const [mainExchange, setMainExchange] = useState('');
  const [subExchange, setSubExchange] = useState('');
  const [syncStatus, setSyncStatus] = useState<SyncStatus>(SyncStatus.SYNCED);

  const startSync = (exchange: String) => {
    setOpenDialog(false);
    setSyncStatus(SyncStatus.SYNCING);
    axios
      .post<any>('/api/instruments/sync', { exchange: exchange })
      .then(() => {
        afterSync();
        setSyncStatus(SyncStatus.SYNCED);
        const message = 'Sync Instruments successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        setSyncStatus(SyncStatus.SYNCED);
        const message = error.response.data.message;
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const onOpenDialog = () => {
    setOpenDialog(true);
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const getButtonLabel = () => {
    if (syncStatus == SyncStatus.SYNCING) {
      return 'Syncing...';
    } else {
      return 'Sync';
    }
  };

  return (
    <>
      <Button icon="refresh" intent="primary" onClick={() => onOpenDialog()}>
        {getButtonLabel()}
      </Button>
      <Dialog title="Choose Exchange" icon="inbox" onClose={onCloseDialog} isOpen={openDialog} style={{ minWidth: '300px' }}>
        <div className={Classes.DIALOG_BODY}>
          <h5 className="mb-1">Please select exchange want to update the instrument list.</h5>
          <ExchangeSelect
            mainExchange={mainExchange}
            subExchange={subExchange}
            mode="short"
            handleSelect={(item: Exchange) => {
              setMainExchange(item.main_exchange);
              setSubExchange(item.sub_exchange);
            }}
          />
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={onCloseDialog}>Cancel</Button>
            <Button
              disabled={!mainExchange || mainExchange == ''}
              intent="primary"
              onClick={() => {
                startSync(mainExchange);
              }}
            >
              Start
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
