import { useEffect, useState } from 'react';
import axios from '../../shared/custom-axios';

import { Instrument, InstrumentsListResponse } from '../../shared/interfaces/bot';

interface Props {
  main_exchange?: string;
  sub_exchange?: string;
  query?: string;
}

const DEFAULT_PAGE_SIZE = 200;

export function useInstruments({ main_exchange = '', sub_exchange = '', query = '' }: Props) {
  const [instruments, setInstruments] = useState<Instrument[]>([]);

  useEffect(() => {
    if (!main_exchange && !sub_exchange) return;

    const path = `/api/instruments?main_exchange=${main_exchange}&sub_exchange=${sub_exchange}&page_size=${DEFAULT_PAGE_SIZE}&search=${query}`;

    axios.get<InstrumentsListResponse>(path).then((response) => {
      const { entries } = response.data;
      setInstruments(entries);
    });
  }, [main_exchange, sub_exchange, query]);

  return instruments;
}
