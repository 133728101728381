export const supportedBlockChains = [
  { id: 'ethereum', name: 'Ethereum' },
  { id: 'binance_smart_chain', name: 'Binance Smart Chain' },
  { id: 'polygon', name: 'Polygon' },
  { id: 'hyperliquid-l1', name: 'HyperLiquid L1' },
];

export const dexTypes = [
  { id: 'defi', name: 'DeFi' },
  { id: 'nft', name: 'NFT' },
];

export const blockchainMappings: { [key: string]: { id: string; name: string } } = supportedBlockChains.reduce((acc: any, item) => {
  acc[item.id] = item;

  return acc;
}, {});
