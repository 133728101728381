import { Route, Routes } from 'react-router-dom';

import Navigation from '../../components/navigation/navigation';
import { Footer } from '../../components/footer/footer';
import { TradingPairsNewPage } from '../../pages/trading_pairs/new';
import { TradingPairsShowPage } from '../../pages/trading_pairs/show';
import { TradingPairsIndexPage } from '../../pages/trading_pairs';
import { useState } from 'react';

export function TradingPairsRoutes() {
  const [jumpingPair, setJumpingPair] = useState(null);
  return (
    <>
      <div className="App-header">
        <Navigation
          onClickError={(tradingPairId: any) => {
            setJumpingPair(tradingPairId);
          }}
        />
      </div>

      <div className="App-body h-full">
        <Routes>
          <Route path="new" element={<TradingPairsNewPage />} />
          <Route path="active" element={<TradingPairsIndexPage jumpingPair={jumpingPair} setJumpingPair={setJumpingPair} />} />
          <Route path="recently_active" element={<TradingPairsIndexPage jumpingPair={jumpingPair} setJumpingPair={setJumpingPair} />} />
          <Route path="recently_created" element={<TradingPairsIndexPage jumpingPair={jumpingPair} setJumpingPair={setJumpingPair} />} />
          <Route path=":id" element={<TradingPairsShowPage />} />
          <Route index element={<TradingPairsIndexPage jumpingPair={jumpingPair} setJumpingPair={setJumpingPair} />} />
        </Routes>
      </div>

      <div className="App-footer fixed z-100 bottom-0 left-0 right-0 text-sm">
        <Footer />
      </div>
    </>
  );
}
