import { useEffect, useState } from 'react';

const STORAGE_KEY = 'user_token';

const getToken = () => {
  const userToken = localStorage.getItem(STORAGE_KEY);

  return userToken;
};

export function useToken(): [string | null, (userToken: string) => void] {
  const [token, setToken] = useState<string | null>(getToken());

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, token || '');
  }, [token]);

  return [token, setToken];
}
