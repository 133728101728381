import { Blockquote, Code } from '@blueprintjs/core';
import { NEWRELIC_CHART_URLS } from '../../shared/configs';

export function BotsMetricsPage() {
  let urls = NEWRELIC_CHART_URLS?.split('|').filter((i) => i !== '') || [];

  return (
    <>
      <h2 className="text-lg font-bold my-1">Apps Metrics</h2>
      <h3>Charts from NewRelic</h3>

      {urls.length < 1 && (
        <div>
          <p className="mt-10">
            No charts are configured. Please set NewRelic charts URLs via ENV <Code>REACT_APP_NEWRELIC_CHART_URLS</Code>
          </p>

          <p className="mt-2">
            Many URLs could be set, delimited by character <Code>|</Code> is required. For example:{' '}
            <span className="mt-2 block">
              <Blockquote>
                REACT_APP_NEWRELIC_CHART_URLS=https://chart-embed.service.newrelic.com/herald/abc-def?height=400px&timepicker=true|https://chart-embed.service.newrelic.com/herald/ghi-jkl?height=400px&timepicker=true
              </Blockquote>
            </span>
          </p>
        </div>
      )}

      {urls.map((url, index) => {
        return <iframe key={index} src={url} style={{ width: '100%', height: '480px' }} />;
      })}
    </>
  );
}
