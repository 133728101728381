import { useCallback, useEffect, useState } from 'react';
import axios from '../../../shared/custom-axios';
import { Button, Card, Classes, Dialog, H5, Intent } from '@blueprintjs/core';

import { AppToaster } from '../../../shared/app-toaster';
import { DexNetwork, DexNetworksListResponse, Network } from '../../../shared/interfaces/bot';
import { ExchangeInfo } from '../../../contexts/exchanges-info';
import { DexNetworkForm } from '../network/form/form';
import { blockchainMappings } from '../../../shared/supported-blockchains';

export function DexListPanel({ item }: { item: ExchangeInfo }) {
  const [dexNetworks, setDexNetworks] = useState<DexNetwork[]>([]);
  const [isOpenNewFrom, setIsOpenNewForm] = useState(false);
  const [editingDexNetwork, setEditingDexNetwork] = useState<DexNetwork | null>(null);

  const handleEditDexNetwork = (item: DexNetwork | null) => {
    setEditingDexNetwork(item);
  };

  const handleReload = useCallback(() => {
    axios.get<DexNetworksListResponse>(`/api/dex_networks?exchange=${item.id}`).then((response) => {
      setDexNetworks(response.data.data);
    });
  }, [item]);

  const handleSubmit = (formData: any) => {
    axios
      .post<Network[]>('/api/dex_networks', { dex_network: formData })
      .then(() => {
        const message = `Setup a network for ${formData.main_exchange} successfully!`;
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });

        setIsOpenNewForm(false);
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const handleUpdate = (formData: any) => {
    console.log(formData, 'formData');
    axios
      .put<Network[]>(`/api/dex_networks/${formData.id}`, { dex_network: formData })
      .then(() => {
        const message = `Updated network for ${formData.main_exchange} successfully!`;
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });

        setEditingDexNetwork(null);

        handleReload();
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  useEffect(() => {
    handleReload();
  }, [handleReload]);

  return (
    <div>
      <Button
        icon="add"
        onClick={() => {
          setIsOpenNewForm(true);
        }}
      >
        <div>Integrate with new blockchain</div>
      </Button>

      <div className="bots grid gap-10 grid-cols-2 mt-5">
        {dexNetworks.map((item: DexNetwork) => {
          return (
            <Card
              interactive={true}
              elevation={2}
              key={item.id}
              onDoubleClick={() => {
                handleEditDexNetwork(item);
              }}
            >
              <div key={item.id} className="trading-pair">
                <div className="card-header relative">
                  <H5>
                    {blockchainMappings[item.blockchain].name} ({item.network?.name})
                  </H5>
                </div>
                <div className="card-body">
                  <p>
                    Router address:{' '}
                    <a href={`${item.network?.block_explorer_url}/address/${item.router_address}`} target="_blank" rel="noreferrer">
                      {item.router_address}
                    </a>
                  </p>
                  <p>
                    Subgraph URL:{' '}
                    <a href={item.subgraph_url} target="_blank" rel="noreferrer">
                      {item.subgraph_url}
                    </a>
                  </p>
                </div>
                <div className="card-footer flex flex-row-reverse"></div>
              </div>
            </Card>
          );
        })}
      </div>

      <Dialog
        portalContainer={document.getElementById('app') || undefined}
        title={`Setup a network for ${item?.name}`}
        transitionDuration={500}
        isOpen={isOpenNewFrom}
        onClose={() => {
          setIsOpenNewForm(false);
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <DexNetworkForm exchange={item} onCreate={handleSubmit} />
        </div>

        <div className={Classes.DIALOG_FOOTER}></div>
      </Dialog>

      <Dialog
        portalContainer={document.getElementById('app') || undefined}
        title={editingDexNetwork && `Editing ${blockchainMappings[editingDexNetwork.blockchain].name} network for ${item?.name}`}
        transitionDuration={500}
        isOpen={!!editingDexNetwork}
        onClose={() => {
          setEditingDexNetwork(null);
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <DexNetworkForm exchange={item} item={editingDexNetwork} onUpdate={handleUpdate} />
        </div>

        <div className={Classes.DIALOG_FOOTER}></div>
      </Dialog>
    </div>
  );
}
