import { useEffect, useState } from 'react';
import { Button, Intent } from '@blueprintjs/core';

/*

This button could be used for resource that has two states of "running" and "stopped"
The button can be shown in a consistently fixed-size so it doesn't break its surrounding layout
regardless of either states with different inner texts, also be shown with the inverse action/state
upon being hovered to suggest users the otherwise action that they can do.

Currently it is used in resources like OrderBooks and PrivateStreams

Side note: I actually prefer the term "state" here but damn, my mistake that I used "status" elsewhere earlier
so no time to change it now. Second thought, maybe it doesn't even matter.

*/

enum Status {
  Running = 'started',
  Stopped = 'stopped',
}

interface ItemWithStatus {
  id: number;
  status: Status;
}

// Yes, generic baby, apply to resource of any type having a status of "running"/"stopped"
interface Props<T extends ItemWithStatus> {
  item: T;
  handleStart: (id: any) => void;
  handleStop: (id: any) => void;
  className?: string;
}

export function StartStopButton({ item, handleStart, handleStop, className = 'w-20' }: Props<any>) {
  // Is this button in idle state or active state (being mouse hovered over)
  const [isIdle, setIdle] = useState(true);
  // What is the button text for the idle state
  const [idleText, setIdleText] = useState('');
  // What is the button text for the active state
  const [activeText, setActiveText] = useState('');
  // What is the intent/style of the idle button
  const [idleIntent, setIdleIntent] = useState<Intent>();
  // What is the intent/style of the active button
  const [activeIntent, setActiveIntent] = useState<Intent>();

  useEffect(() => {
    switch (item.status) {
      case Status.Running: {
        // Green button with "Running" text
        setIdleText('Running');
        setIdleIntent(Intent.SUCCESS);
        // Red button with "Stop" text to suggest users that they can stop the given item
        setActiveText('Stop');
        setActiveIntent(Intent.DANGER);

        break;
      }
      case Status.Stopped: {
        // Neutral button with "Stopped" text
        setIdleText('Stopped');
        setIdleIntent(Intent.NONE);
        // Green button with "Start" text to suggest users that they can start the given item
        setActiveText('Start');
        setActiveIntent(Intent.SUCCESS);

        break;
      }
    }
  }, [item.status]);

  const handleClick = (item: any) => {
    switch (item.status) {
      case Status.Running: {
        handleStop(item.id);

        break;
      }
      case Status.Stopped: {
        handleStart(item.id);

        break;
      }
    }
  };

  return (
    <>
      <Button
        className={className}
        intent={isIdle ? idleIntent : activeIntent}
        text={isIdle ? idleText : activeText}
        onClick={() => handleClick(item)}
        onMouseEnter={() => setIdle(false)}
        onMouseLeave={() => setIdle(true)}
        small={true}
      />
    </>
  );
}
