import { Menu, Icon, Button, MenuDivider, MenuItem, Dialog, Classes } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { AuthContext } from '../../../contexts/auth';
import { UnderContructionContext } from '../../../contexts/under-construction';
import { useToken } from '../../auth/use-token';
import { UserPreferences } from '../../user/preferences/preferences';

export function NavigationUserMenu() {
  const [token, setToken] = useToken();
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { setUnderContruction } = useContext(UnderContructionContext);
  const [isShowUserPreferences, setIsShowUserPreferences] = useState(false);

  const handleLogOut = () => {
    setToken('');
  };

  const handleClickSettings = () => {
    navigate('/bot-settings');
  };

  useEffect(() => {
    if (!token) {
      window.location.href = '/login';
    }
  }, [token]);

  const userDropdownMenu = (
    <Menu>
      <MenuDivider title={`${currentUser?.email}`} />
      <li>
        <Link to={'/user'} className="bp4-menu-item bp4-popover-dismiss">
          <Icon icon="mugshot" />
          <div className="bp4-fill bp4-text-overflow-ellipsis">Profile</div>
        </Link>
      </li>
      <MenuItem
        icon="map"
        text="Preferences"
        onClick={() => {
          setIsShowUserPreferences(true);
        }}
      />
      <MenuDivider title="App" />
      <MenuItem icon="cog" text="Settings" onClick={handleClickSettings} />
      <MenuItem
        icon="info-sign"
        text="About"
        onClick={() => {
          setUnderContruction && setUnderContruction(true);
        }}
      />
      <MenuDivider />
      <MenuItem icon="log-out" text="Log out" onClick={handleLogOut} />
    </Menu>
  );

  return (
    <>
      <Popover2 content={userDropdownMenu} placement="bottom-start">
        <Button icon="user" text={currentUser?.display_name} />
      </Popover2>

      <Dialog
        portalContainer={document.getElementById('app') || undefined}
        isOpen={isShowUserPreferences}
        onClose={() => setIsShowUserPreferences(false)}
        title="User Preferences"
      >
        <div className={Classes.DIALOG_BODY}>
          <UserPreferences />
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={() => {
                setIsShowUserPreferences(false);
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
