import { useContext } from 'react';

import { AuthContext } from '../../contexts/auth';

import { Footer } from '../../components/footer/footer';
import Navigation from '../../components/navigation/navigation';
import { UserProfile } from '../../components/user/profile/profile';

export function UserRoutes() {
  // path refers to the parent route
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) {
    return <></>;
  }

  return (
    <>
      <div className="App-header">
        <Navigation />
      </div>

      <div className="App-body h-full">
        <div className="mt-10 w-4/12 m-auto">
          <UserProfile user={currentUser}></UserProfile>
        </div>
      </div>

      <div className="App-footer fixed z-100 bottom-0 left-0 right-0 text-sm">
        <Footer />
      </div>
    </>
  );
}
