import { createContext } from 'react';

import { ThemeMode } from '../shared/interfaces/bot';

export interface Theme {
  mode: ThemeMode;
  setMode?(mode: ThemeMode): void;
}

const defaultState: Theme = {
  mode: ThemeMode.Light,
};

export const ThemeContext = createContext<Theme>(defaultState);
