import { useCallback, useContext, useEffect, useState } from 'react';
import { Button, ButtonGroup, Callout, Intent, Tab, Tabs, Tag } from '@blueprintjs/core';

import axios from '../../../shared/custom-axios';
import { ScheduledJob, TradingPair } from '../../../shared/interfaces/bot';
import { AppToaster } from '../../../shared/app-toaster';
import { TimezoneContext, UtcTimeFormater } from '../../../contexts/timezone';

interface Props {
  pair: TradingPair;
}

export function WickCatcherFundingJobs({ pair }: Props) {
  const handlePause = useCallback(() => {
    axios
      .post<{ data: ScheduledJob[] }>(`/api/trading_pairs/${pair.id}/pause`)
      .then((response) => {
        const message = 'The pair was manually paused successfully';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, []);

  const handleResume = useCallback(() => {
    axios
      .post(`/api/trading_pairs/${pair.id}/resume`)
      .then((response) => {
        const message = 'The pair was manually resumed successfully';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, []);

  return (
    <div className="mt-2">
      <div>
        <ButtonGroup fill={true}>
          <Button intent={Intent.WARNING} onClick={() => handlePause()}>
            Pause
          </Button>

          <Button intent={Intent.PRIMARY} onClick={() => handleResume()}>
            Resume
          </Button>
        </ButtonGroup>

        <Callout intent="primary" className="mt-2">
          You can also manually pause or resume given pair here.
        </Callout>
      </div>

      <div className="mt-4">
        <h2 className="font-medium text-base">List of scheduled jobs:</h2>

        <Tabs className="mt-1" animate={true} id="scheduled-jobs" renderActiveTabPanelOnly={true} vertical={false}>
          <Tab id="pair" title="Current pair" panel={<WickCatcherPairFundingJobs pair={pair} />} />

          <Tab id="all" title="All pairs" panel={<WickCatcherAllFundingJobs />} />
        </Tabs>
      </div>
    </div>
  );
}

export function WickCatcherPairFundingJobs({ pair }: Props) {
  const { timeFormater } = useContext(TimezoneContext);
  const [items, setItems] = useState<ScheduledJob[]>([]);
  const [triggerTimestamps, setTriggerTimestamps] = useState<{ [index: string]: Date }>();
  const [nextJob, setNextJob] = useState<ScheduledJob | undefined>();

  useEffect(() => {
    axios
      .get<{ data: ScheduledJob[] }>(`/api/funding_jobs/by_pair/${pair.id}`)
      .then((response) => {
        const newData = response.data.data;
        setItems(newData);
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, [pair]);

  useEffect(() => {
    items.forEach((item) => {
      setTriggerTimestamps((prevState) => {
        let [hour, minute, second] = item.name.split('--')[1].split(':');
        let time = new Date(Date.UTC(2000, 1, 1, Number(hour), Number(minute), Number(second)));

        return { ...prevState, [item.name]: time };
      });
    });
  }, [items]);

  useEffect(() => {
    let timer = setInterval(() => {
      if (!triggerTimestamps) return;

      let timeNow = new Date();

      let nextJob =
        items.find((item) => {
          let currentFundingTime = triggerTimestamps[item.name];

          return (
            currentFundingTime.getUTCHours() > timeNow.getUTCHours() ||
            (currentFundingTime.getUTCHours() === timeNow.getUTCHours() && currentFundingTime.getUTCMinutes() > timeNow.getUTCMinutes())
          );
        }) || items[0];

      setNextJob(nextJob);
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, [items, triggerTimestamps]);

  return (
    <>
      <p className="font-medium">{items.length} jobs:</p>

      <ul className="mt-3">
        {items.map((item, index) => {
          let scheduledTimeString;

          if (triggerTimestamps) {
            let time = triggerTimestamps[item.name];
            scheduledTimeString = `${timeFormater.format(time)} (${UtcTimeFormater.format(time)} UTC)`;
          }

          return (
            <li key={item.name} className="mb-3 pb-2 border-b border-gray-300">
              <div className="mb-1">
                <span className="font-mono">{index + 1}</span>. Scheduled at <code>{scheduledTimeString}</code>
                {nextJob === item && (
                  <span className="float-right">
                    <Tag>Next</Tag>
                  </span>
                )}
              </div>

              <div className="mb-1">Job name: {item.name}</div>

              <div className="mb-1">Cron expression: {item.schedule}</div>

              <div className="mb-1">Function call: {item.task}</div>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export function WickCatcherAllFundingJobs() {
  const { timeFormater } = useContext(TimezoneContext);
  const [items, setItems] = useState<ScheduledJob[]>([]);
  const [triggerTimestamps, setTriggerTimestamps] = useState<{ [index: string]: Date }>();

  useEffect(() => {
    axios
      .get<{ data: ScheduledJob[] }>(`/api/funding_jobs`)
      .then((response) => {
        const newData = response.data.data;
        setItems(newData);
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, []);

  useEffect(() => {
    items.forEach((item) => {
      setTriggerTimestamps((prevState) => {
        let [hour, minute, second] = item.name.split('--')[1].split(':');
        let time = new Date(Date.UTC(2000, 1, 1, Number(hour), Number(minute), Number(second)));

        return { ...prevState, [item.name]: time };
      });
    });
  }, [items]);

  return (
    <>
      <p className="font-medium">{items.length} jobs:</p>

      <ul className="mt-3">
        {items.map((item, index) => {
          let scheduledTimeString;

          if (triggerTimestamps) {
            let time = triggerTimestamps[item.name];
            scheduledTimeString = `${timeFormater.format(time)} (${UtcTimeFormater.format(time)} UTC)`;
          }

          return (
            <li key={item.name} className="mb-3 pb-2 border-b border-gray-300">
              <div className="mb-1">
                <span className="font-mono">{index + 1}</span>. Scheduled at <code>{scheduledTimeString}</code>
              </div>

              <div className="mb-1">Job name: {item.name}</div>

              <div className="mb-1">Cron expression: {item.schedule}</div>

              <div className="mb-1">Function call: {item.task}</div>
            </li>
          );
        })}
      </ul>
    </>
  );
}
