import { useEffect } from 'react';
import { Account, AccountsListResponse, ExchangeType, Instrument } from '../../../../shared/interfaces/bot';

import axios from '../../../../shared/custom-axios';

interface Props {
  mainExchange: string;
  subExchange: string;
  selectedInstrument: Instrument | null;
  onDataReady: (items: Account[]) => void;
}

export function useReloadAccounts({ mainExchange, subExchange, selectedInstrument, onDataReady }: Props) {
  useEffect(() => {
    if (!mainExchange || !subExchange || !selectedInstrument) return;

    if (selectedInstrument?.exchange_type === ExchangeType.CEX) {
      axios.get<AccountsListResponse>(`/api/accounts?main_exchange=${mainExchange}&sub_exchange=${subExchange}`).then((response) => {
        onDataReady(response.data.data);
      });
    }

    if (selectedInstrument?.exchange_type === ExchangeType.DEX && selectedInstrument.dex_network) {
      axios.get<AccountsListResponse>(`/api/accounts?blockchain=${selectedInstrument.dex_network.blockchain}`).then((response) => {
        onDataReady(response.data.data);
      });
    }
  }, [selectedInstrument, mainExchange, subExchange]);
}
