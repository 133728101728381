import { BotList } from '../../components/bot/list/list';
import { TerminalRedisClientRestartButton } from '../../components/common/redis-client-restart-button/terminal-redis-client-restart-button';

export function BotsIndexPage() {
  return (
    <>
      <div>
        <h2 className="text-lg font-bold my-1">Terminal</h2>

        <div className="mt-2">
          <TerminalRedisClientRestartButton></TerminalRedisClientRestartButton>
        </div>
      </div>

      <div className="mt-5">
        <h2 className="text-lg font-bold my-1">Spread Bots</h2>

        <BotList />
      </div>
    </>
  );
}
