import { useCallback, useContext, useEffect, useState } from 'react';
import axios from '../../shared/custom-axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Dialog, Intent } from '@blueprintjs/core';

import { TradingPair, TradingPairResponse } from '../../shared/interfaces/bot';
import { AppToaster } from '../../shared/app-toaster';
import { BotsManagerContext } from '../../contexts/bots-manager';

import { TradingPairEditor } from '../../components/trading-pair/editor/editor';
import styles from './show.module.css';

export const TradingPairsShowPage = (props: any) => {
  const { currentBot } = useContext(BotsManagerContext);
  let navigate = useNavigate();
  const location = useLocation();
  let { id } = useParams<{ id: string }>();

  const [item, setItem] = useState<TradingPair | null>(null);

  useEffect(() => {
    axios
      .get<TradingPairResponse>(`/api/trading_pairs/${id}`)
      .then((response) => {
        const newData = response.data.data;
        setItem(newData);
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, [id]);

  const handleStart = useCallback(
    (tradingPairId: number, mode: object) => {
      axios
        .put<TradingPairResponse>(`/api/trading_pairs/${tradingPairId}/start`, mode)
        .then((response) => {
          const newData = response.data.data;
          setItem(newData);

          const message = 'Trading Pair started';
          AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
        })
        .catch((error) => {
          const error_message = error.response.data.error_message;
          AppToaster.show({ message: error_message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
        });
    },
    [setItem],
  );

  const handleStop = useCallback(
    (tradingPairId: number, mode: object) => {
      axios
        .put<TradingPairResponse>(`/api/trading_pairs/${tradingPairId}/stop`, mode)
        .then((response) => {
          const newData = response.data.data;
          setItem(newData);

          const message = 'Trading Pair stopped';
          AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
        })
        .catch((error) => {
          const message = JSON.stringify(error.response.data);
          AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
        });
    },
    [setItem],
  );

  const handleDelete = useCallback(
    (tradingPairId: number) => {
      axios
        .delete<TradingPair>(`/api/trading_pairs/${tradingPairId}`)
        .then(() => {
          setItem(null);

          navigate('trading_pairs');

          const message = 'Trading Pair deleted successfully!';
          AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
        })
        .catch((error) => {
          const message = JSON.stringify(error.response.data);
          AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
        });
    },
    [setItem, navigate],
  );

  const handleClose = useCallback(() => {
    setItem(null);

    // Go back to the previous URL if possible
    if (location.state) {
      const { from } = location.state as { from: Location };

      if (from) {
        return navigate(from);
      }
    }

    // Otherwise go to trading pairs listing page
    navigate(`/bots/${currentBot?.app_id}/trading_pairs`);
  }, [setItem, navigate, currentBot?.app_id, location.state]);

  const handleReload = useCallback(
    (tradingPairId: number) => {
      axios
        .get<any>(`/api/trading_pairs/${tradingPairId}`)
        .then((response) => {
          const newData = response.data.data;
          setItem(newData);
        })
        .catch((error) => {
          const message = JSON.stringify(error.response.data);

          AppToaster.show({
            message: message,
            icon: 'warning-sign',
            intent: Intent.DANGER,
            timeout: 3500,
          });
        });
    },
    [setItem],
  );

  if (!id) {
    return <></>;
  }

  return (
    <>
      <Dialog
        portalContainer={document.getElementById('app') || undefined}
        title={`${item?.name}`}
        transitionDuration={500}
        isOpen={!!item}
        onClose={() => {
          handleClose();
        }}
        className={styles.dialog}
      >
        {item && (
          <TradingPairEditor tradingPair={item} onStart={handleStart} onStop={handleStop} onDelete={handleDelete} onReload={handleReload} />
        )}
      </Dialog>
    </>
  );
};
