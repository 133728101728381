import { Button, ButtonGroup, Classes, H5, Intent, Popover, PopoverPosition, Tab, Tabs } from '@blueprintjs/core';
import axios from '../../../shared/custom-axios';
import _ from 'lodash';
import { useCallback, useContext, useState } from 'react';

import { AppToaster } from '../../../shared/app-toaster';
import { ExchangeType, OpportunitiesListResponse, TradingPair } from '../../../shared/interfaces/bot';

import { EmergencyCancelsList } from '../../emergency-cancel/list/list';
import { TradingPairEditorApiKeysCheck } from './api-keys-check';
import { BotsManagerContext } from '../../../contexts/bots-manager';
import { OpportunitiesList } from '../../opportunity/list/list';
import { TradingPairEditorOrderBooks } from './order-book';
import { TradingPairEditorPulserLogs } from './pulser-logs';
import { InstrumentDetailPopup } from '../../instrument/detail-popup/detail-popup';

interface Props {
  tradingPair: TradingPair;
  onStart: (tradingPairId: number, mode: object) => void;
  onStop: (tradingPairId: number, mode: object) => void;
  onReload?: (tradingPairId: number) => void;
  onDelete: (tradingPairId: number) => void;
}

export function TradingPairEditor({ tradingPair, onStart, onStop, onDelete, onReload }: Props) {
  const { bots } = useContext(BotsManagerContext);
  const currentBot = bots.find((bot) => bot.id === tradingPair.bot_id);

  const [viewedOpportunity, setViewedOpportunity] = useState<number>();

  const handleOpportunitiesUpdate = useCallback(
    (tradingPairId: number, payload: any) => {
      axios
        .post<OpportunitiesListResponse>(`/api/trading_pairs/${tradingPairId}/opportunities/update_all`, { opportunities: payload })
        .then(() => {
          onReload && onReload(tradingPairId);

          const message = 'Opportunities updated successfully!';
          AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
        })
        .catch((error) => {
          const message = JSON.stringify(error.response.data);
          AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
        });
    },
    [onReload],
  );

  const { primary_instrument, secondary_instrument, primary_account, secondary_account } = tradingPair;

  return (
    <>
      {tradingPair && (
        <div className={Classes.DIALOG_BODY}>
          <div className="grid grid-cols-2 gap-10">
            <div>
              <p>{tradingPair.description ? tradingPair.description : ''}</p>
              {/* <p>Currency: {tradingPair.currency}</p> */}

              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-1">
                  <h3 className="mb-3 font-bold">Primary</h3>
                  {primary_instrument.exchange_type === ExchangeType.CEX && <InstrumentDetailPopup item={primary_instrument} />}

                  {primary_instrument.exchange_type === ExchangeType.DEX && (
                    <p>
                      {_.upperFirst(primary_instrument.main_exchange)} ({primary_instrument.symbol})
                    </p>
                  )}

                  {/* <p>Base currency: {primary_instrument.base_currency}</p> */}
                  {/* <p>Quote currency: {primary_instrument.quote_currency}</p> */}
                  <p>
                    <span>Account: </span>
                    <TradingPairEditorApiKeysCheck bot={currentBot} item={primary_account} />
                  </p>
                </div>

                <div className="col-span-1">
                  <h3 className="mb-3 font-bold">Secondary</h3>
                  {secondary_instrument.exchange_type === ExchangeType.CEX && <InstrumentDetailPopup item={secondary_instrument} />}

                  {secondary_instrument.exchange_type === ExchangeType.DEX && (
                    <p>
                      {_.upperFirst(secondary_instrument.main_exchange)} ({secondary_instrument.symbol})
                    </p>
                  )}

                  {/* <p>Base currency: {secondary_instrument.base_currency}</p> */}
                  {/* <p>Quote currency: {secondary_instrument.quote_currency}</p> */}
                  <p>
                    <span>Account: </span>
                    <TradingPairEditorApiKeysCheck bot={currentBot} item={secondary_account} />
                  </p>
                </div>
              </div>

              <div className="">
                <div className="mt-2">
                  <ButtonGroup fill={true}>
                    {tradingPair.short_mode === 'stopped' && (
                      <Button
                        className="w-32"
                        large={true}
                        onClick={(e) => {
                          e.stopPropagation();
                          onStart(tradingPair.id, { short_mode: 'open_short' });
                        }}
                        onDoubleClick={(e) => e.stopPropagation()}
                        intent={Intent.NONE}
                        text="Short"
                      />
                    )}

                    {tradingPair.short_mode === 'open_short' && (
                      <Button
                        className="w-32"
                        large={true}
                        onClick={(e) => {
                          e.stopPropagation();
                          onStop(tradingPair.id, { short_mode: 'stopped' });
                        }}
                        onDoubleClick={(e) => e.stopPropagation()}
                        intent={Intent.DANGER}
                        text="Short"
                      />
                    )}

                    {tradingPair.long_mode === 'stopped' && (
                      <Button
                        className="w-32"
                        large={true}
                        onClick={(e) => {
                          e.stopPropagation();
                          onStart(tradingPair.id, { long_mode: 'open_long' });
                        }}
                        onDoubleClick={(e) => e.stopPropagation()}
                        intent={Intent.NONE}
                        text="Long"
                      />
                    )}

                    {tradingPair.long_mode === 'open_long' && (
                      <Button
                        className="w-32"
                        large={true}
                        onClick={(e) => {
                          e.stopPropagation();
                          onStop(tradingPair.id, { long_mode: 'stopped' });
                        }}
                        onDoubleClick={(e) => e.stopPropagation()}
                        intent={Intent.SUCCESS}
                        text="Long"
                      />
                    )}
                  </ButtonGroup>
                </div>

                <div className="mt-5">
                  <OpportunitiesList
                    tradingPair={tradingPair}
                    viewedOpportunity={viewedOpportunity}
                    onUpdate={handleOpportunitiesUpdate}
                    onReloadPair={async (id) => onReload && onReload(id)}
                  />
                </div>
              </div>
            </div>

            <div>
              <Tabs defaultSelectedTabId="emergency-cancel" renderActiveTabPanelOnly={true} large={true}>
                {/* <Tab
                  id="trade-feed"
                  title={<span className="font-medium">TradeFeed</span>}
                  panel={<TradeFeedList tradingPair={tradingPair} onViewOpportunity={handleViewOpportunity} />}
                /> */}

                <Tab
                  id="emergency-cancel"
                  title={<span className="font-medium">Emergency Cancel</span>}
                  panel={<EmergencyCancelsList tradingPair={tradingPair} />}
                />

                <Tab
                  id="order-books"
                  title={<span className="font-medium">Order Books</span>}
                  panel={<TradingPairEditorOrderBooks tradingPair={tradingPair} />}
                />

                <Tab
                  id="pulser-logs"
                  title={<span className="font-medium">Pulser Logs</span>}
                  panel={<TradingPairEditorPulserLogs tradingPair={tradingPair} />}
                />
              </Tabs>
            </div>
          </div>
        </div>
      )}
      <div className={Classes.DIALOG_FOOTER}>
        {tradingPair && (
          <Popover popoverClassName={Classes.POPOVER_CONTENT_SIZING} position={PopoverPosition.RIGHT}>
            <Button intent={Intent.DANGER} text="Delete" small={true} />
            <div key="text">
              <H5>Confirm deletion</H5>
              <p>Are you sure you want to delete this account?</p>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                <Button className={Classes.POPOVER_DISMISS} style={{ marginRight: 10 }}>
                  Cancel
                </Button>
                <Button onClick={() => onDelete(tradingPair.id)} intent={Intent.DANGER} className={Classes.POPOVER_DISMISS}>
                  Delete
                </Button>
              </div>
            </div>
          </Popover>
        )}
      </div>
    </>
  );
}
