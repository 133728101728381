import { FormEvent, useState } from 'react';
import { Button, ControlGroup, FormGroup, HTMLSelect, InputGroup, Intent } from '@blueprintjs/core';
import axios from '../../../shared/custom-axios';
import { Navigate } from 'react-router-dom';

import { AppToaster } from '../../../shared/app-toaster';
import { Network } from '../../../shared/interfaces/bot';

export function NetworkForm() {
  const supportedBlockChains = [
    { id: 'ethereum', name: 'Ethereum' },
    { id: 'binance_smart_chain', name: 'Binance Smart Chain' },
    { id: 'polygon', name: 'Polygon' },
    { id: 'hyperliquid-l1', name: 'HyperLiquid L1' },
  ];

  const initForm = {
    blockchain: '',
    name: '',
    currency_symbol: '',
    block_explorer_url: '',
    http_url: '',
    ws_url: '',
    chain_id: '',
  };

  const [formState, setFormState] = useState(initForm);
  const [successRedirect, setSuccessRedirect] = useState(false);

  const handleInputChange = (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.currentTarget;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    axios
      .post<Network[]>('/api/networks', { network: formState })
      .then(() => {
        setSuccessRedirect(true);
        const message = 'Network created successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  return (
    <>
      {successRedirect && <Navigate to="/networks" replace />}
      <h2 className="text-lg font-bold my-1">New network</h2>

      <FormGroup label="Network name" labelFor="name" labelInfo="(required)">
        <InputGroup id="text-input" name="name" value={formState.name} onChange={handleInputChange} placeholder="E.g. Ethereum Mainnet" />
      </FormGroup>

      <FormGroup label="Blockchain" labelFor="blockchain" labelInfo="(required)">
        <ControlGroup>
          <HTMLSelect name="blockchain" value={formState.blockchain} onChange={handleInputChange}>
            <option>Choose a blockchain...</option>
            {supportedBlockChains.map(({ id, name }) => (
              <option value={id} key={id}>
                {name}
              </option>
            ))}
          </HTMLSelect>
        </ControlGroup>
      </FormGroup>

      <FormGroup label={'Chain ID'} labelFor="chain_id">
        <InputGroup
          id="chain_id"
          name="chain_id"
          value={formState.chain_id}
          onChange={handleInputChange}
          placeholder="Chain ID of the network"
          autoComplete="off"
        />
      </FormGroup>

      <FormGroup label={'Currency Symbol'} labelFor="currency_symbol">
        <InputGroup
          id="currency_symbol"
          name="currency_symbol"
          value={formState.currency_symbol}
          onChange={handleInputChange}
          placeholder="E.g ETH or BNB"
          autoComplete="off"
        />
      </FormGroup>

      <FormGroup label={'Block Explorer URL'} labelFor="block_explorer_url" helperText="Block Explorer URL of the network">
        <InputGroup
          id="block_explorer_url"
          name="block_explorer_url"
          value={formState.block_explorer_url}
          onChange={handleInputChange}
          placeholder="E.g. https://etherscan.io/"
          autoComplete="off"
        />
      </FormGroup>

      <FormGroup
        label={'HTTP Endpoint URL'}
        labelFor="http_url"
        helperText="Node provider's REST API endpoint for connecting to the network"
      >
        <InputGroup id="http_url" name="http_url" value={formState.http_url} onChange={handleInputChange} autoComplete="off" />
      </FormGroup>

      <FormGroup
        label={'WebSocket Endpoint URL'}
        labelFor="ws_url"
        helperText="Node provider's WebSocket API endpoint for connecting to the network"
      >
        <InputGroup id="ws_url" name="ws_url" value={formState.ws_url} onChange={handleInputChange} autoComplete="off" />
      </FormGroup>

      <Button intent="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </>
  );
}
