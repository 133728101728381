import { FormEvent, useContext, useEffect, useState } from 'react';
import axios from '../../../shared/custom-axios';
import { Button, Collapse, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { Navigate } from 'react-router-dom';

import { AppToaster } from '../../../shared/app-toaster';
import { Exchange, Instrument, OrderBook } from '../../../shared/interfaces/bot';
import { ExchangeSelect } from '../../common/exchange-select/exchange-select';
import { InstrumentSelect } from '../../common/instrument-select/instrument-select';
import { BotsManagerContext } from '../../../contexts/bots-manager';

import { useInstruments } from '../../instrument/use-instruments';

interface OrderBookFormState {
  main_exchange: string;
  sub_exchange: string;
  id?: number;
  name: string;
  description: string;
  instrument_id: string;
  bot_id: number | undefined;
  artificial_depth: string;
  artificial_depth_distance: string;
  status: string;
}

export function OrderBookForm() {
  const { currentBot } = useContext(BotsManagerContext);

  const initForm: OrderBookFormState = {
    main_exchange: '',
    sub_exchange: '',
    name: '',
    description: '',
    instrument_id: '',
    bot_id: currentBot?.id,
    artificial_depth: '',
    artificial_depth_distance: '',
    status: '',
  };

  const [formState, setFormState] = useState(initForm);
  const [successRedirect, setSuccessRedirect] = useState(false);
  const [selectedInstrument, setSelectedInstrument] = useState<Instrument | null>(null);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [query, setQuery] = useState('');

  const { instrument_id, main_exchange, sub_exchange } = formState;

  const instruments = useInstruments({ main_exchange, sub_exchange, query });

  const handleInputChange = (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.currentTarget;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    axios
      .post<OrderBook[]>(`/api/order_books`, { order_book: formState })
      .then(() => {
        setSuccessRedirect(true);
        const message = 'Order Book created successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  useEffect(() => {
    setFormState((prevState) => ({
      ...prevState,
      instrument_id: '',
    }));
  }, [instruments]);

  useEffect(() => {
    // Auto prefill a suggest name for the order book based on selected instrument
    const selectedInstrument = instruments.find(({ id }) => id === +instrument_id);

    setFormState((prevState) => ({
      ...prevState,
      name: selectedInstrument ? selectedInstrument.symbol : '',
    }));
  }, [instrument_id, instruments]);

  return (
    <>
      {successRedirect && <Navigate to={`/bots/${currentBot?.app_id}/order_books`} replace />}
      <h2 className="text-lg font-bold my-1">New order book</h2>

      <FormGroup label="Exchange" labelInfo="(required)">
        <ExchangeSelect
          mainExchange={formState.main_exchange}
          subExchange={formState.sub_exchange}
          handleSelect={(item: Exchange) => {
            setFormState((prevState) => ({
              ...prevState,
              main_exchange: item.main_exchange,
              sub_exchange: item.sub_exchange,
            }));
          }}
        />
      </FormGroup>

      <FormGroup label="Instrument" labelFor="instrument_id" labelInfo="(required)" helperText="">
        <InstrumentSelect
          selectedItem={selectedInstrument}
          items={instruments}
          disabled={!formState.main_exchange || !formState.sub_exchange}
          handleSelect={(item: Instrument) => {
            setFormState((prevState) => ({
              ...prevState,
              instrument_id: String(item.id),
            }));

            setSelectedInstrument(item);
          }}
          query={query}
          handleQueryChange={(v) => {
            setQuery(v);
          }}
        />
      </FormGroup>

      <FormGroup label="Name" labelFor="name" labelInfo="(required)">
        <InputGroup id="name" name="name" value={formState.name} onChange={handleInputChange} placeholder="E.g. BTCUSDT or XBTUSD" />
      </FormGroup>

      <FormGroup label="Description" labelFor="description">
        <InputGroup id="description" name="description" value={formState.description} onChange={handleInputChange} placeholder="" />
      </FormGroup>

      <p onClick={() => setShowAdvancedSettings(!showAdvancedSettings)} className="font-normal cursor-pointer">
        ({showAdvancedSettings ? 'Hide' : 'Show'} advanced settings)
      </p>

      <Collapse isOpen={showAdvancedSettings}>
        <FormGroup
          label="Artificial Depth"
          labelFor="artificial_depth"
          helperText="How many artificial price levels (tickers) we want to add?"
        >
          <InputGroup id="artificial_depth" name="artificial_depth" value={formState.artificial_depth} onChange={handleInputChange} />
        </FormGroup>

        <FormGroup
          label="Artificial Depth distance"
          labelFor="artificial_depth_distance"
          helperText="Volume difference in percentage (%) between price levels"
        >
          <InputGroup
            id="artificial_depth_distance"
            name="artificial_depth_distance"
            value={formState.artificial_depth_distance}
            onChange={handleInputChange}
          />
        </FormGroup>
      </Collapse>
      <Button intent="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </>
  );
}
