import { useEffect } from 'react';
import { ContractStream, ExchangeType, Instrument } from '../../../../shared/interfaces/bot';

import axios from '../../../../shared/custom-axios';
import { AppToaster } from '../../../../shared/app-toaster';
import { Intent } from '@blueprintjs/core';

interface Props {
  botId: number | undefined;
  instrumentId: string;
  loadedInstruments: Instrument[];
  loadedContractStreams: ContractStream[];
  onAfterSelectDefault: (item: ContractStream) => void;
  onAfterCreateDefault: () => void;
}

export function useDefaultContractStream({
  botId,
  instrumentId,
  loadedInstruments,
  loadedContractStreams,
  onAfterSelectDefault,
  onAfterCreateDefault,
}: Props) {
  useEffect(() => {
    if (!instrumentId) {
      return;
    }

    if (loadedContractStreams.length) {
      const defaultItem = loadedContractStreams.find((item) => item.is_default);

      if (defaultItem !== undefined) {
        return onAfterSelectDefault(defaultItem);
      }
    }

    // Create a new order book and set it as default when no default one is found
    const selectedInstrument = loadedInstruments.find(({ id }) => id === Number(instrumentId));

    const isContractStreamSupported = selectedInstrument?.exchange_type === ExchangeType.DEX && selectedInstrument.main_exchange !== 'blur';

    if (!selectedInstrument || !isContractStreamSupported) {
      return;
    }

    const formData = {
      name: `${selectedInstrument?.symbol} (${selectedInstrument?.dex_network.network?.name})`,
      description: '',
      main_exchange: selectedInstrument?.main_exchange,
      sub_exchange: selectedInstrument?.sub_exchange,
      instrument_id: instrumentId,
      is_default: true,
      bot_id: botId,
    };

    axios
      .post<ContractStream[]>(`/api/contract_streams`, { contract_stream: formData })
      .then(() => {
        onAfterCreateDefault();
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, [loadedContractStreams]);
}
