import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, MenuItem } from '@blueprintjs/core';
import { ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select';

import axios from '../../../shared/custom-axios';
import { Account, AccountsListResponse } from '../../../shared/interfaces/bot';

interface Props {
  mainExchange?: string;
  selectedItem: Account | 'all' | null;
  handleSelect: (item: Account | 'all') => void;
}

const InnerSelect = Select.ofType<Account | 'all'>();

const renderItem: ItemRenderer<Account | 'all'> = (item, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  if (item === 'all') {
    return (
      <MenuItem
        active={modifiers.active}
        key={'all'}
        onClick={handleClick}
        text={
          <>
            <span className="font-medium block">All Accounts</span>
            <span className="text-xs block">All Exchanges</span>
          </>
        }
      ></MenuItem>
    );
  }

  return (
    <MenuItem
      active={modifiers.active}
      key={item.id}
      onClick={handleClick}
      text={
        <>
          <span className="font-medium block">
            {item.name} <span className="text-xs opacity-30">#{item.id}</span>
          </span>
          <span className="text-xs block">{_.capitalize(item.main_exchange)}</span>
        </>
      }
    ></MenuItem>
  );
};

const filterItem: ItemPredicate<Account | 'all'> = (query, item) => {
  if (item === 'all') {
    return true;
  }

  return `${item.name.toLowerCase()}`.indexOf(query.toLowerCase()) >= 0;
};

export function AccountSelect({ mainExchange, selectedItem, handleSelect }: Props) {
  const [items, setItems] = useState<(Account | 'all')[]>(['all']);

  useEffect(() => {
    if (mainExchange && mainExchange !== '') {
      axios.get<AccountsListResponse>(`/api/accounts?main_exchange=${mainExchange}`).then((response) => {
        setItems(['all' as Account | 'all'].concat(response.data.data));
      });
    } else {
      axios.get<AccountsListResponse>(`/api/accounts`).then((response) => {
        setItems(['all' as Account | 'all'].concat(response.data.data));
      });
    }
  }, [mainExchange, selectedItem]);

  return (
    <>
      <InnerSelect itemRenderer={renderItem} itemPredicate={filterItem} onItemSelect={handleSelect} activeItem={selectedItem} items={items}>
        {selectedItem && selectedItem !== 'all' && <Button text={selectedItem.name} rightIcon="double-caret-vertical" />}

        {selectedItem && selectedItem === 'all' && <Button text={'All Accounts'} rightIcon="double-caret-vertical" />}

        {!selectedItem && <Button text={'Choose an account'} rightIcon="double-caret-vertical" />}
      </InnerSelect>
    </>
  );
}
