import { Button, Intent } from '@blueprintjs/core';
import { useCallback, useState } from 'react';

import axios from '../../../shared/custom-axios';
import { AppToaster } from '../../../shared/app-toaster';

export function TerminalRedisClientRestartButton() {
  const [isLoading, setIsLoading] = useState(false);

  const handleRestart = useCallback(() => {
    setIsLoading(true);

    axios
      .post<any>(`/api/redis_client/restart`, {})
      .then(() => {
        setIsLoading(false);

        const message = 'RedisClient restarted';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        setIsLoading(false);

        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, []);

  return (
    <>
      <Button
        loading={isLoading}
        onClick={(e) => {
          e.stopPropagation();

          handleRestart();
        }}
      >
        Restart RedisClient
      </Button>
    </>
  );
}
