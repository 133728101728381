import { useCallback } from 'react';
import axios from '../../shared/custom-axios';
import { useNavigate } from 'react-router-dom';
import { Intent } from '@blueprintjs/core';

import { Account } from '../../shared/interfaces/bot';
import { AppToaster } from '../../shared/app-toaster';
import { AccountForm } from '../../components/account/form/form';

export const AccountNewPage = (props: any) => {
  let navigate = useNavigate();

  const handleCreate = useCallback(
    (formState: object) => {
      axios
        .post<Account[]>('/api/accounts', { account: formState })
        .then(() => {
          navigate('/accounts');
          const message = 'Account created successfully!';
          AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
        })
        .catch((error) => {
          const message = JSON.stringify(error.response.data);
          AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
        });
    },
    [navigate],
  );

  return (
    <>
      <h2 className="text-lg font-bold my-1">New account</h2>

      <AccountForm onCreate={handleCreate} />
    </>
  );
};
