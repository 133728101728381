import { useCallback, useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from '../shared/custom-axios';
import socket from '../socket';
import { Channel } from 'phoenix';
import { FocusStyleManager, HotkeysProvider } from '@blueprintjs/core';

import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import './app.css';

import { BinanceRateLimit, Bot, ThemeMode, TimezoneOption, User, UserResponse } from '../shared/interfaces/bot';
import { ExchangesInfoContext } from '../contexts/exchanges-info';
import { AuthContext } from '../contexts/auth';
import { useToken } from '../components/auth/use-token';
import { BotsManagerContext } from '../contexts/bots-manager';
import { ThemeContext } from '../contexts/theme';
import { ActiveOrderContextProvider } from '../contexts/active-order';
import { OpportunitySessionContextProvider } from '../contexts/opportunity-session';
import { BaliDateFormater, BaliDateTimeFormater, BaliTimeFormater, TimezoneContext } from '../contexts/timezone';
import { RateLimitContext } from '../contexts/rate-limit-info';
import { BotsHealthContextProvider } from '../contexts/bots-health';
import { UnderContructionContext } from '../contexts/under-construction';

// Routes
import { AccountsRoutes } from './routes/accounts';
import { InstrumentsRoutes } from './routes/instruments';
import { OrderBooksRoutes } from './routes/order_books';
import { TradingPairsRoutes } from './routes/trading_pairs';
import { BotsRoutes } from './routes/bots';
import { PrivateStreamsRoutes } from './routes/private_streams';
import { ContractStreamsRoutes } from './routes/contract_streams';
import { UserRoutes } from './routes/user';

// Pages
import { HomePage } from '../pages/home/page';
import { LoginPage } from '../pages/login/page';

import { PageHeader } from '../components/common/page-header';
import { useExchanges } from '../components/exchanges/use-exchanges';
import { RequireAuth } from '../components/auth/require-auth';
import { NetworksRoutes } from './routes/networks';
import { DexesRoutes } from './routes/dexes';
import { TradeFeedRoutes } from './routes/trade_feed';
import orderService from '../shared/order-service';
import { BotSettingsRoutes } from './routes/bot-settings';
import { ActiveSummary } from '../components/trading-pair/active-summary/active-summary';
import { WickCatcherRoutes } from './routes/wick_catchers';
import { MetricsRoutes } from './routes/metrics';

// Remove outline style when focusing on a link/button
FocusStyleManager.onlyShowFocusOnTabs();

export default function App() {
  const [token, setToken] = useToken();
  const exchangesInfo = useExchanges();
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [currentBot, setCurrentBot] = useState<Bot | null>(null);
  const [bots, setBots] = useState<Array<Bot>>([]);
  const [isUnderContruction, setUnderConstruction] = useState<boolean>(false);
  const [timezone, setTimezone] = useState<TimezoneOption>(TimezoneOption.Bali);
  const [dateFormater, setDateFormater] = useState<Intl.DateTimeFormat>(BaliDateFormater);
  const [timeFormater, setTimeFormater] = useState<Intl.DateTimeFormat>(BaliTimeFormater);
  const [dateTimeFormater, setDateTimeFormater] = useState<Intl.DateTimeFormat>(BaliDateTimeFormater);
  const [themeMode, setThemeMode] = useState<ThemeMode>(ThemeMode.Light);
  const [accountRateLimits, setAccountRateLimits] = useState<Array<BinanceRateLimit>>([]);

  let channel = useRef<Channel | null>(null);
  let tradeFeedChannel = useRef<Channel | null>(null);

  const activePairSummaryRef = useRef<any>();

  const handleActivePairsUpdate = useCallback(() => {
    activePairSummaryRef?.current.reload();
  }, [activePairSummaryRef]);

  useEffect(() => {
    if (!token) {
      return;
    }

    axios.get<UserResponse>('/auth/user').then((response) => {
      if (setCurrentUser) {
        setCurrentUser(response.data.data);
      }
    });
  }, [token]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    if (currentUser.preferences.theme) {
      setThemeMode(currentUser.preferences.theme);
    }

    orderService.subscribeTradeFeed(tradeFeedChannel);

    orderService.updateActivePairEvent.on('active_pairs:update', handleActivePairsUpdate);

    return () => {
      orderService.unsubscribeTradeFeed(tradeFeedChannel);

      orderService.updateActivePairEvent.off('active_pairs:update', handleActivePairsUpdate);
    };
  }, [currentUser, handleActivePairsUpdate]);

  useEffect(() => {
    channel.current = socket.channel('bot:lobby', {});

    channel.current
      ?.join()
      .receive('ok', () => {
        channel.current
          ?.push('list_bots', {})
          .receive('ok', (payload) => {
            let bots: Bot[] = payload.data;
            bots.sort((a, b) => a.name.localeCompare(b.name));

            setBots(bots);
          })
          .receive('timeout', () => {
            alert('Timeout! Please try to reload the page.');
          });
      })
      .receive('error', (resp) => {
        console.log('Unable to join', resp);
      });

    channel.current?.on('bots_updates', (data) => {
      setBots(data.data);
    });

    return () => {
      // Make sure we leave the current WS channels when switching to another bot
      channel.current?.leave();
    };
  }, [currentBot?.app_id]);

  return (
    <HotkeysProvider>
      <AuthContext.Provider value={{ currentUser, setCurrentUser }}>
        <TimezoneContext.Provider
          value={{
            timezone,
            setTimezone,
            dateFormater,
            setDateFormater,
            timeFormater,
            setTimeFormater,
            dateTimeFormater,
            setDateTimeFormater,
          }}
        >
          <ThemeContext.Provider value={{ mode: themeMode, setMode: setThemeMode }}>
            <UnderContructionContext.Provider value={{ isUnderContruction: isUnderContruction, setUnderContruction: setUnderConstruction }}>
              <ExchangesInfoContext.Provider value={exchangesInfo}>
                <BotsManagerContext.Provider value={{ bots, currentBot, setCurrentBot }}>
                  <BotsHealthContextProvider>
                    <ActiveOrderContextProvider>
                      <OpportunitySessionContextProvider>
                        <RateLimitContext.Provider value={{ accountRateLimits, setAccountRateLimits }}>
                          <PageHeader />

                          <Router>
                            <div id="app" className={`App min-h-screen ${themeMode === ThemeMode.Dark ? 'bp4-dark' : ''}`}>
                              <Routes>
                                <Route path="/login" element={<LoginPage setToken={setToken} />} />

                                <Route
                                  path="/trade-feed"
                                  element={
                                    <RequireAuth>
                                      <TradeFeedRoutes />
                                    </RequireAuth>
                                  }
                                />

                                <Route path="/bots">
                                  <Route
                                    path="metrics/*"
                                    element={
                                      <RequireAuth>
                                        <MetricsRoutes />
                                      </RequireAuth>
                                    }
                                  />

                                  <Route
                                    path=":appId/wick_catchers/*"
                                    element={
                                      <RequireAuth>
                                        <WickCatcherRoutes />
                                      </RequireAuth>
                                    }
                                  />

                                  <Route
                                    path=":appId/trading_pairs/*"
                                    element={
                                      <RequireAuth>
                                        <TradingPairsRoutes />
                                      </RequireAuth>
                                    }
                                  />

                                  <Route
                                    path=":appId/order_books/*"
                                    element={
                                      <RequireAuth>
                                        <OrderBooksRoutes />
                                      </RequireAuth>
                                    }
                                  />

                                  <Route
                                    path=":appId/private_streams/*"
                                    element={
                                      <RequireAuth>
                                        <PrivateStreamsRoutes />
                                      </RequireAuth>
                                    }
                                  />

                                  <Route
                                    path=":appId/contract_streams/*"
                                    element={
                                      <RequireAuth>
                                        <ContractStreamsRoutes />
                                      </RequireAuth>
                                    }
                                  />

                                  <Route
                                    path=""
                                    element={
                                      <RequireAuth>
                                        <BotsRoutes />
                                      </RequireAuth>
                                    }
                                  />
                                </Route>

                                <Route
                                  path="/accounts/*"
                                  element={
                                    <RequireAuth>
                                      <AccountsRoutes />
                                    </RequireAuth>
                                  }
                                />

                                <Route
                                  path="/instruments/*"
                                  element={
                                    <RequireAuth>
                                      <InstrumentsRoutes />
                                    </RequireAuth>
                                  }
                                />

                                <Route
                                  path="/networks/*"
                                  element={
                                    <RequireAuth>
                                      <NetworksRoutes />
                                    </RequireAuth>
                                  }
                                />

                                <Route
                                  path="/dexes/*"
                                  element={
                                    <RequireAuth>
                                      <DexesRoutes />
                                    </RequireAuth>
                                  }
                                />

                                <Route
                                  path="/user/*"
                                  element={
                                    <RequireAuth>
                                      <UserRoutes />
                                    </RequireAuth>
                                  }
                                />

                                <Route
                                  path="/bot-settings/*"
                                  element={
                                    <RequireAuth>
                                      <BotSettingsRoutes />
                                    </RequireAuth>
                                  }
                                />

                                <Route path="/" element={<HomePage />} />
                              </Routes>
                              {currentBot ? <ActiveSummary ref={activePairSummaryRef} isOpen={false} /> : null}
                            </div>
                          </Router>
                        </RateLimitContext.Provider>
                      </OpportunitySessionContextProvider>
                    </ActiveOrderContextProvider>
                  </BotsHealthContextProvider>
                </BotsManagerContext.Provider>
              </ExchangesInfoContext.Provider>
            </UnderContructionContext.Provider>
          </ThemeContext.Provider>
        </TimezoneContext.Provider>
      </AuthContext.Provider>
    </HotkeysProvider>
  );
}
