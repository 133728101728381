import { useEffect, useState } from 'react';
import axios from '../../../shared/custom-axios';
import { Button, Classes, Code, H5, Icon, Intent, Popover, PopoverPosition } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

import { AppToaster } from '../../../shared/app-toaster';
import { Network, NetworksListResponse } from '../../../shared/interfaces/bot';

export function NetworkList() {
  const [networks, setNetworks] = useState<Network[]>([]);

  useEffect(() => {
    axios.get<NetworksListResponse>('/api/networks').then((response) => {
      setNetworks(response.data.data);
    });
  }, []);

  const handleDelete = (networkId: number) => {
    axios
      .delete<NetworksListResponse>(`/api/networks/${networkId}`)
      .then(() => {
        setNetworks((prevState) => {
          const newState = prevState.filter(({ id }) => id !== networkId);
          return newState;
        });

        const message = 'Network deleted successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  return (
    <>
      <h2 className="text-lg font-bold my-1">Networks</h2>

      <Link to={'/networks/new'} className="mt-1 bp4-button">
        <Icon icon="add" />
        <div>New</div>
      </Link>

      <table className="mt-5 bp4-html-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Blockchain</th>
            <th>Chain ID</th>
            <th>HTTP URL</th>
            <th>WS URL</th>
            <th>Currency Symbol</th>
            <th>Block Explorer URL</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {networks.map((network) => {
            return (
              <tr key={network.id}>
                <td>{network.id}</td>
                <td>{network.name}</td>
                <td>
                  <Code>{network.blockchain}</Code>
                </td>
                <td>{network.chain_id}</td>
                <td>{network.http_url}</td>
                <td>{network.ws_url}</td>
                <td>{network.currency_symbol}</td>
                <td>{network.block_explorer_url}</td>
                <td>
                  <Popover popoverClassName={Classes.POPOVER_CONTENT_SIZING} position={PopoverPosition.RIGHT}>
                    <Button intent={Intent.NONE} text="Delete" small={true} />
                    <div key="text">
                      <H5>Confirm deletion</H5>
                      <p>Are you sure you want to delete this network?</p>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                        <Button className={Classes.POPOVER_DISMISS} style={{ marginRight: 10 }}>
                          Cancel
                        </Button>
                        <Button onClick={() => handleDelete(network.id)} intent={Intent.DANGER} className={Classes.POPOVER_DISMISS}>
                          Delete
                        </Button>
                      </div>
                    </div>
                  </Popover>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
