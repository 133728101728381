import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { Alignment, Button, Classes, Navbar, NavbarDivider, NavbarGroup, Icon, NavbarHeading, Dialog, Intent } from '@blueprintjs/core';
import { Channel } from 'phoenix';
import axios from '../../shared/custom-axios';
import socket from '../../socket';

import { critialErrorSound } from '../../sounds/index';
import { BotsManagerContext } from '../../contexts/bots-manager';
import { AuthContext } from '../../contexts/auth';
import { UnderContructionContext } from '../../contexts/under-construction';
import { RateLimitContext } from '../../contexts/rate-limit-info';

import { NavigationBotsMenu } from './bots-menu/bots-menu';
import { TradingPairsMenu } from './trading-pairs-menu/trading-pairs-menu';
import { NavigationUserMenu } from './user-menu/user-menu';

import { ThemeContext } from '../../contexts/theme';
import { BinanceRateLimit, ThemeMode, UserResponse } from '../../shared/interfaces/bot';
import { AppRightToaster, AppToaster } from '../../shared/app-toaster';
import { TradingPairOmnibar } from '../common/trading-pair-omnibar/trading-pair-omnibar';
import orderService from '../../shared/order-service';
import { matchPath } from 'react-router';
import { CancelAllButton } from '../cancel-all/button';

export interface Params {
  appId: string;
}

export default function Navigation(props: any) {
  let { appId } = useParams<keyof Params>();
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const { bots, currentBot, setCurrentBot } = useContext(BotsManagerContext);
  const { isUnderContruction, setUnderContruction } = useContext(UnderContructionContext);
  const { mode, setMode } = useContext(ThemeContext);
  const { accountRateLimits, setAccountRateLimits } = useContext(RateLimitContext);

  let notificationChannel = useRef<Channel | null>(null);
  let location = useLocation();

  const [newOrderFills, setNewOrderFills] = useState<number>(0);

  const handleNewOrderFill = useCallback(() => {
    const match = matchPath(location.pathname, '/trade-feed');

    if (!match) {
      setNewOrderFills(orderService.orderFills.length);
    }
  }, [location, setNewOrderFills]);

  useEffect(() => {
    notificationChannel.current = socket.channel('notification:lobby', {});

    notificationChannel.current
      ?.join()
      .receive('ok', () => {
        console.log(`[Notification] Joined "notification:lobby" channel for notification real-time updates`);
      })
      .receive('error', (resp) => {
        console.log('Unable to join', resp);
      });

    notificationChannel.current?.on('notification:system_monitor', ({ data }) => {
      const { app, exchange, account, error } = data;

      // Play the sound non-stop until users click dismiss the toaster below
      critialErrorSound.loop = false;
      critialErrorSound.volume = 1;
      critialErrorSound.play().then();

      const message = (
        <div>
          <div className="mb-2">{app && <h5>Bot: {app.name}</h5>}</div>
          <div className="mb-2">Auto stopping pairs and cancel all orders due to a critical error:</div>

          <div className="mb-2">Exchange: {exchange}</div>
          <div className="mb-2">
            Account triggered: <span className="font-bold">{account['name']}</span> [#{account['id']}]
          </div>

          <span>{JSON.stringify(error)}</span>
        </div>
      );

      AppRightToaster.show({
        message: message,
        icon: 'warning-sign',
        intent: Intent.DANGER,
        timeout: 0,
        isCloseButtonShown: true,
        onDismiss: () => {
          critialErrorSound.pause();
        },
      });
    });

    notificationChannel.current?.on('notification:error_message', ({ data }) => {
      const { type, app, wick_catcher_id, trading_pair_id, trading_pair, error } = data;

      switch (type) {
        case 'order_book_worker': {
          const { order_book } = data;
          let message = `${JSON.stringify(error)}`;

          AppRightToaster.show({
            message: (
              <div>
                <div className="mb-2">{app && <h5>Bot: {app.name}</h5>}</div>
                <div className="mb-2">
                  {order_book && <h5>Order Book ID: #{order_book.id}</h5>}
                  {order_book && (
                    <h5>
                      Instrument: #{order_book.instrument?.id} {order_book.instrument?.symbol}
                    </h5>
                  )}
                </div>

                <p>Order Book worker got a WebSocket error:</p>

                <p>{message}</p>
              </div>
            ),
            icon: 'warning-sign',
            intent: Intent.DANGER,
            timeout: 10000,
          });

          break;
        }

        case 'bbo_worker': {
          const { order_book } = data;
          let message = `${JSON.stringify(error)}`;

          AppRightToaster.show({
            message: (
              <div>
                <div className="mb-2">{app && <h5>Bot: {app.name}</h5>}</div>
                <div className="mb-2">
                  {order_book && <h5>Order Book ID: #{order_book.id}</h5>}
                  {order_book && (
                    <h5>
                      Instrument: #{order_book.instrument?.id} {order_book.instrument?.symbol}
                    </h5>
                  )}
                </div>

                <p>BBO worker got a WebSocket error:</p>

                <p>{message}</p>
              </div>
            ),
            icon: 'warning-sign',
            intent: Intent.DANGER,
            timeout: 10000,
          });

          break;
        }

        case 'mark_price_worker': {
          let message = `${JSON.stringify(error)}`;

          AppRightToaster.show({
            message: (
              <div>
                <div className="mb-2">{app && <h5>Bot: {app.name}</h5>}</div>

                <p>Mark Price worker got a WebSocket error:</p>

                <p>{message}</p>
              </div>
            ),
            icon: 'warning-sign',
            intent: Intent.DANGER,
            timeout: 10000,
          });

          break;
        }

        case 'index_price_worker': {
          let message = `${JSON.stringify(error)}`;

          AppRightToaster.show({
            message: (
              <div>
                <div className="mb-2">{app && <h5>Bot: {app.name}</h5>}</div>

                <p>Index Price worker got a WebSocket error:</p>

                <p>{message}</p>
              </div>
            ),
            icon: 'warning-sign',
            intent: Intent.DANGER,
            timeout: 10000,
          });

          break;
        }

        default: {
          // TODO: Migrate following if statement to a dedicated case clause in the switch case above
          // Need to support "type" field for this case on Spread side
          if (trading_pair_id) {
            let message = `${JSON.stringify(error)}`;

            if (error === 'exceed_order_volume_threshold') {
              orderService.handleOpportunityExceedVolumeThreshold(data['opportunity_id']);
              message = `Opportunity ${data['opportunity_id']} exceed the volume threshold`;
            }

            if (error === 'exceed_max_volume') {
              orderService.handleSkipHedging(trading_pair_id, data['opportunity_id']);
              message = `Skip hedging because of exceeding the max volume`;
            }

            if (orderService.isRateLimitError(error)) {
              orderService.handleOpportunityExceedRateLimit({
                tradingPairId: trading_pair_id,
                opportunityId: data['opportunity_id'],
                error: error,
              });
              return;
            } else if (data['opportunity_id']) {
              orderService.clearOpportunityExceedRateLimit([data['opportunity_id']]);
            }

            AppRightToaster.show({
              message: (
                <div className="clickable" onClick={() => props.onClickError(trading_pair_id)}>
                  <div className="mb-2">{app && <h5>Bot: {app.name}</h5>}</div>
                  <div className="mb-2">
                    {wick_catcher_id && <h5>Wick Catcher: #{wick_catcher_id}</h5>}
                    {trading_pair && (
                      <h5>
                        Pair: #{trading_pair.id} {trading_pair.name}
                      </h5>
                    )}
                  </div>

                  <span>{message}</span>
                </div>
              ),
              icon: 'warning-sign',
              intent: Intent.DANGER,
              timeout: 7000,
              // action: {
              //   onClick: () => {
              //     props.onClickError(trading_pair_id);
              //   },
              //   text: 'Open trading pair',
              // },
            });

            return;
          }

          const message = JSON.stringify(error);

          AppRightToaster.show({
            message: message,
            icon: 'warning-sign',
            intent: Intent.DANGER,
            timeout: 7000,
          });
        }
      }
    });

    notificationChannel.current?.on('notification:rate_limit_info', ({ data }) => {
      const { message } = data;

      if (message.main_exchange === 'binance' && setAccountRateLimits) {
        let updatedRateLimits = [];
        const index = accountRateLimits.findIndex((ratelimit: BinanceRateLimit) => ratelimit.account_id === message.account_id);
        if (index >= 0) {
          updatedRateLimits = accountRateLimits.splice(index, 1, message);
        } else {
          updatedRateLimits = accountRateLimits.concat(message);
        }
        setAccountRateLimits(updatedRateLimits);
      }
    });

    orderService.hedgeEvent.on('primary:order_fill:init', handleNewOrderFill);

    return () => {
      notificationChannel.current?.leave();

      orderService.hedgeEvent.off('primary:order_fill:init', handleNewOrderFill);
    };
  }, []);

  useEffect(() => {
    // Select current bot based on URL when reloading the page
    const bot = bots.find((bot) => bot.app_id === appId);

    bot && setCurrentBot && setCurrentBot(bot);
  }, [appId, bots, setCurrentBot]);

  const handleChangeThemeMode = (mode: ThemeMode) => {
    if (!currentUser) {
      return;
    }

    setMode && setMode(mode);

    axios
      .put<UserResponse>(`/api/users/${currentUser.id}/preferences`, { user_preferences: { theme: mode } })
      .then((response) => {
        const newData = response.data.data;
        setCurrentUser && setCurrentUser(newData);
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  return (
    <>
      <Navbar>
        <NavbarGroup align={Alignment.LEFT}>
          <NavbarHeading className="font-bold">
            <NavigationBotsMenu />
          </NavbarHeading>

          <CancelAllButton></CancelAllButton>

          {currentBot && <TradingPairsMenu bot={currentBot} />}
        </NavbarGroup>

        <NavbarGroup align={Alignment.RIGHT}>
          <TradingPairOmnibar />

          <NavbarDivider />

          <NavLink
            to={'/trade-feed'}
            className={({ isActive }) => `${isActive ? 'bp4-active' : ''} bp4-menu-item ${Classes.POPOVER_DISMISS}`}
          >
            <Icon icon="th-filtered" />
            <div style={{ position: 'relative' }}>
              Trade Feed
              {newOrderFills && newOrderFills > 0 ? <span className="badge-content -top-3.5 -right-5">{newOrderFills}</span> : null}
            </div>
          </NavLink>

          <NavLink
            to={'/accounts'}
            className={({ isActive }) => `${isActive ? 'bp4-active' : ''} bp4-menu-item ${Classes.POPOVER_DISMISS}`}
          >
            <Icon icon="key" />
            <div>Accounts</div>
          </NavLink>

          <NavLink
            to={'/instruments'}
            className={({ isActive }) => `${isActive ? 'bp4-active' : ''} bp4-menu-item ${Classes.POPOVER_DISMISS}`}
          >
            <Icon icon="book" />
            <div>Instruments</div>
          </NavLink>

          <NavLink
            to={'/networks'}
            className={({ isActive }) => `${isActive ? 'bp4-active' : ''} bp4-menu-item ${Classes.POPOVER_DISMISS}`}
          >
            <Icon icon="globe-network" />
            <div>Networks</div>
          </NavLink>

          <NavLink to={'/dexes'} className={({ isActive }) => `${isActive ? 'bp4-active' : ''} bp4-menu-item ${Classes.POPOVER_DISMISS}`}>
            <Icon icon="exchange" />
            <div>DEXes</div>
          </NavLink>

          {currentUser && (
            <>
              <NavbarDivider />

              {mode === ThemeMode.Light && (
                <Button
                  title="Switch to dark mode"
                  icon="moon"
                  onClick={() => {
                    handleChangeThemeMode(ThemeMode.Dark);
                  }}
                />
              )}

              {mode === ThemeMode.Dark && (
                <Button
                  title="Switch to light mode"
                  icon="moon"
                  onClick={() => {
                    handleChangeThemeMode(ThemeMode.Light);
                  }}
                />
              )}

              <NavbarDivider />

              <Button
                icon="notifications"
                onClick={() => {
                  setUnderContruction && setUnderContruction(true);
                }}
              />

              <NavbarDivider />

              <NavigationUserMenu />
            </>
          )}
        </NavbarGroup>
      </Navbar>

      <Dialog
        portalContainer={document.getElementById('app') || undefined}
        isOpen={isUnderContruction}
        onClose={() => {
          setUnderContruction && setUnderContruction(false);
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <h3 className="text-1xl">
            <strong>Coming soon</strong>
          </h3>
          <p className="mt-5">This feature is under construction.</p>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={() => {
                setUnderContruction && setUnderContruction(false);
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
