import { createContext } from 'react';

import { ActiveOrder, BinanceRateLimit, OpportunitySession } from '../shared/interfaces/bot';

export interface RateLimitInfoStore {
  accountRateLimits: BinanceRateLimit[];
  setAccountRateLimits?(items: BinanceRateLimit[]): void;
}

const defaultState: RateLimitInfoStore = {
  accountRateLimits: [],
};

export const RateLimitContext = createContext<RateLimitInfoStore>(defaultState);
