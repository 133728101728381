import React from 'react';
import { useTable, UseTableCellProps } from 'react-table';

import { BotSetting } from '../../../shared/interfaces/bot';

interface Props {
  columns: any;
  data: any;
}

export function BotSettingTable({ columns, data }: Props) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <>
      <div className="mt-5 w-full overflow-auto">
        <table {...getTableProps()} className="border table-fixed text-xs min-w-full">
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th {...column.getHeaderProps()} className="border px-4 py-1 text-light-blue-600">
                    <div className="flex items-center justify-center">{column.render('Header')}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="text-center">
            {rows.map((row: any, i: number) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="hover:bg-gray-100">
                  {row.cells.map((cell: UseTableCellProps<BotSetting>) => {
                    return (
                      <td {...cell.getCellProps()} className="border px-4 py-2 text-light-blue-600">
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
