import { ButtonGroup, Card, H5 } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';

import { Bot, BotState } from '../../../shared/interfaces/bot';
import { ConnectivityIndicator } from '../connectivity-indicator';
import { BotsManagerContext } from '../../../contexts/bots-manager';
import { BotsHealthContext } from '../../../contexts/bots-health';
import { BotRedisClientRestartButton } from '../../common/redis-client-restart-button/bot-redis-client-restart-button';
import { BotStopButton } from '../../common/bot-stop-button/bot-redis-client-restart-button';
import { BotIpAddressButton } from '../../common/bot-ip-address-button/bot-ip-address-button';

const getBotTradingPairsPath = (bot: Bot) => {
  return `/bots/${bot.app_id}/trading_pairs/active`;
};

export function BotList() {
  const navigate = useNavigate();
  const { bots } = useContext(BotsManagerContext);
  const { botsHealthChecks } = useContext(BotsHealthContext);

  return (
    <>
      <div className="bots grid gap-10 grid-cols-4 mt-5">
        {bots.map((item: Bot) => {
          return (
            <Card
              interactive={true}
              elevation={2}
              key={item.id}
              onDoubleClick={() => {
                navigate(getBotTradingPairsPath(item));
              }}
            >
              <div key={item.id} className="trading-pair">
                <div className="card-header relative">
                  <H5>{item.name}</H5>

                  <span className="absolute -top-2 -right-2">
                    <ConnectivityIndicator active={botsHealthChecks[item.id]?.connectivity_status === BotState.Connected} iconSize={25} />
                  </span>
                </div>
                <div className="card-body">
                  <p>ID: {item.id}</p>
                  <p>App ID: {item.app_id}</p>
                  <p>API base url: {item.current_api_endpoint?.base_url}</p>
                  <p>Activated at: {item.activated_at}</p>
                  <p>Created at: {item.inserted_at}</p>
                  <p>Last response: {botsHealthChecks[item.id]?.last_pong_response_at}</p>
                </div>

                <div className="card-footer">
                  <div className="flex flex-row-reverse">
                    <ButtonGroup fill={true}>
                      <BotRedisClientRestartButton botId={item.id}></BotRedisClientRestartButton>

                      <BotStopButton botId={item.id} />
                    </ButtonGroup>
                  </div>

                  <div className="flex flex-row-reverse mt-2">
                    <ButtonGroup fill={true}>
                      <BotIpAddressButton botId={item.id} />
                    </ButtonGroup>
                  </div>
                </div>
              </div>
            </Card>
          );
        })}
      </div>
    </>
  );
}
