import { useState, useEffect, useCallback } from 'react';
import { H5, H6, Intent, Pre } from '@blueprintjs/core';

import axios from '../../../../shared/custom-axios';
import {
  ExchangeType,
  StrategyType,
  TradeFeedHedgeOrder,
  TradeFeedOrderFill,
  TradingPair,
  TradingPairResponse,
} from '../../../../shared/interfaces/bot';

import { TradeFeedOrderFillsList } from '../../order-fill/list/list';
import { TradeFeedHedgeOrdersList } from '../../hedge-order/list/list';
import { getTxUrl } from '../../../../shared/utils';
import { AppToaster } from '../../../../shared/app-toaster';

interface Props {
  item: TradeFeedOrderFill | null;
}

export function TradeFeedOrderFillItem({ item }: Props) {
  const [tradingPair, setTradingPair] = useState<TradingPair | null>(null);
  const [entryOrderFills, setEntryOrderFills] = useState<TradeFeedOrderFill[]>([]);
  const [exitOrderFills, setExitOrderFills] = useState<TradeFeedOrderFill[]>([]);
  const [hedgeOrders, setHedgeOrders] = useState<TradeFeedHedgeOrder[]>([]);
  const isDefiTaking = tradingPair?.primary_instrument.exchange_type === ExchangeType.DEX;
  const network = tradingPair?.primary_instrument?.dex_network?.network;

  let txUrl;

  switch (tradingPair?.primary_instrument.exchange_type) {
    case 'cex': {
      if (tradingPair?.primary_instrument.main_exchange === 'hyperliquid') {
        txUrl = `https://app.hyperliquid.xyz/explorer/tx/${item?.trade_id}`;
      }

      break;
    }

    case 'dex': {
      txUrl = getTxUrl(network, item?.internal_id);
    }
  }

  const reloadEntryOrders = useCallback((item: TradeFeedOrderFill) => {
    const path = `/api/trade_feed/order_fills/${item.id}/entries?trading_pair_id=${item.trading_pair_id}`;
    const url = `${path}`;

    axios.get<{ entries: TradeFeedOrderFill[] }>(url).then((response) => {
      setEntryOrderFills(response.data.entries);
    });
  }, []);

  const reloadExitOrders = useCallback((item: TradeFeedOrderFill) => {
    const path = `/api/trade_feed/order_fills/${item.id}/exits?trading_pair_id=${item.trading_pair_id}`;
    const url = `${path}`;

    axios.get<{ entries: TradeFeedOrderFill[] }>(url).then((response) => {
      setExitOrderFills(response.data.entries);
    });
  }, []);

  const reloadHedgeOrders = useCallback((item: TradeFeedOrderFill) => {
    const path = `/api/trade_feed/order_fills/${item.id}/?trading_pair_id=${item.trading_pair_id}`;
    const url = `${path}`;

    axios.get<{ data: TradeFeedOrderFill }>(url).then((response) => {
      setHedgeOrders(response.data.data?.hedge_orders);
    });
  }, []);

  const loadTradingPair = (item: TradeFeedOrderFill) => {
    axios
      .get<TradingPairResponse>(`/api/trading_pairs/${item.trading_pair_id}`)
      .then((response) => {
        const newData = response.data.data;

        setTradingPair(newData);
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  useEffect(() => {
    if (!item) {
      return setHedgeOrders([]);
    }

    loadTradingPair(item);

    if (item.opportunity.direction === 'entry') {
      reloadExitOrders(item);
    }

    if (item.opportunity.direction === 'exit') {
      reloadEntryOrders(item);
    }

    reloadHedgeOrders(item);
  }, [item, reloadHedgeOrders]);

  if (!item || !tradingPair) {
    return <></>;
  }

  return (
    <>
      <div>
        {tradingPair.strategy_type === StrategyType.WickCatcher && <H5 className="my-3">Entry order fill</H5>}

        <TradeFeedOrderFillsList items={[item]} tradingPair={tradingPair} />

        {(isDefiTaking || tradingPair.primary_instrument.main_exchange === 'hyperliquid') && (
          <p className="mt-2">
            <a href={txUrl} target="_blank" rel="noreferrer">
              View on explorer
            </a>
          </p>
        )}
      </div>

      <div className="mt-5">
        <H5 className="my-3">Secondary hedge orders</H5>

        {item.secondary_hedge_decision && !item.secondary_hedge_decision.should_hedge && (
          <div>
            <H6>Skipped hedging reason</H6>
            <Pre className="whitespace-pre-wrap">{item.secondary_hedge_decision.message}</Pre>
          </div>
        )}

        <TradeFeedHedgeOrdersList
          items={hedgeOrders}
          onReloadItems={reloadHedgeOrders}
          orderFill={item}
          itemDetailsViewable={true}
          tradingPair={tradingPair}
        />
      </div>

      {tradingPair.strategy_type === StrategyType.WickCatcher && item.opportunity.direction === 'entry' && (
        <div className="mt-5">
          <H5 className="my-3">Exit order fills</H5>

          <TradeFeedOrderFillsList items={exitOrderFills} tradingPair={tradingPair} />
        </div>
      )}

      {tradingPair.strategy_type === StrategyType.WickCatcher && item.opportunity.direction === 'exit' && (
        <div className="mt-5">
          <H5 className="my-3">Entry order fills</H5>

          <TradeFeedOrderFillsList items={entryOrderFills} tradingPair={tradingPair} />
        </div>
      )}
    </>
  );
}
