import { useEffect } from 'react';
import { ExchangeType, Instrument, PrivateStream, PrivateStreamsListResponse } from '../../../../shared/interfaces/bot';

import axios from '../../../../shared/custom-axios';

interface Props {
  botId: number | undefined;
  instrumentId: string;
  accountId: string;
  selectedInstrument: Instrument | null;
  onDataReady: (items: PrivateStream[]) => void;
}

export function useReloadPrivateStreams({ botId, instrumentId, accountId, selectedInstrument, onDataReady }: Props) {
  useEffect(() => {
    const isPrivateStreamSupported =
      selectedInstrument?.exchange_type === ExchangeType.CEX ||
      (selectedInstrument?.exchange_type === ExchangeType.DEX && selectedInstrument.main_exchange === 'blur');

    if (isPrivateStreamSupported) {
      if (instrumentId && accountId) {
        axios
          .get<PrivateStreamsListResponse>(`/api/private_streams?instrument_id=${instrumentId}&account_id=${accountId}&bot_id=${botId}`)
          .then((response) => {
            onDataReady(response.data.data);
          });
      }
    }
  }, [selectedInstrument, instrumentId, accountId, botId]);
}
