import { FormEvent, useEffect, useMemo, useState } from 'react';
import { Button, ControlGroup, FormGroup, HTMLSelect, InputGroup } from '@blueprintjs/core';
import axios from '../../../../shared/custom-axios';

import { DexNetwork, DexType, Network, NetworksListResponse } from '../../../../shared/interfaces/bot';
import { ExchangeInfo } from '../../../../contexts/exchanges-info';
import { blockchainMappings, dexTypes, supportedBlockChains } from '../../../../shared/supported-blockchains';

interface Props {
  exchange: ExchangeInfo;
  item?: DexNetwork | null;
  onCreate?: (formData: any) => void;
  onUpdate?: (formData: any) => void;
}

export function DexNetworkForm({ exchange, item, onCreate, onUpdate }: Props) {
  const [supportedNetworks, setSupportedNetworks] = useState<Network[]>([]);

  const initForm = useMemo(() => {
    return {
      id: undefined,
      type: '' as DexType,
      blockchain: '',
      main_exchange: exchange.id,
      router_address: '',
      subgraph_url: '',
      network_id: '',
    };
  }, []);

  const [formState, setFormState] = useState<DexNetwork>(initForm);

  useEffect(() => {
    if (item) {
      setFormState(item);
    } else {
      setFormState(initForm);
    }
  }, [item, initForm]);

  useEffect(() => {
    axios.get<NetworksListResponse>(`/api/networks?blockchain=${formState.blockchain}`).then((response) => {
      setSupportedNetworks(response.data.data);
    });
  }, [formState.blockchain]);

  const handleInputChange = (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.currentTarget;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    onCreate?.(formState);
  };

  const handleUpdate = () => {
    onUpdate?.(formState);
  };

  return (
    <>
      <FormGroup label="DEX Type" labelFor="type" labelInfo="(required)">
        <ControlGroup>
          <HTMLSelect name="type" value={formState.type} onChange={handleInputChange}>
            <option>Choose a DEX type...</option>
            {dexTypes.map(({ id, name }) => (
              <option value={id} key={id}>
                {name}
              </option>
            ))}
          </HTMLSelect>
        </ControlGroup>
      </FormGroup>

      <FormGroup label="Blockchain" labelFor="blockchain" labelInfo="(required)">
        <ControlGroup>
          <HTMLSelect name="blockchain" value={formState.blockchain} onChange={handleInputChange}>
            <option>Choose a blockchain...</option>
            {supportedBlockChains.map(({ id, name }) => (
              <option value={id} key={id}>
                {name}
              </option>
            ))}
          </HTMLSelect>
        </ControlGroup>
      </FormGroup>

      {formState.blockchain && formState.type === DexType.DeFi && (
        <FormGroup
          label={'Router address'}
          labelFor="router_address"
          helperText={`The contract address of ${formState.main_exchange} on ${
            blockchainMappings[formState.blockchain].name
          } that our account will interact to`}
        >
          <InputGroup
            id="router_address"
            name="router_address"
            value={formState.router_address}
            onChange={handleInputChange}
            autoComplete="off"
          />
        </FormGroup>
      )}

      <div className="mt-7">
        <FormGroup
          label="Network"
          labelFor="network_id"
          labelInfo="(required)"
          helperText="Which network (MetaMask-like network) to use to trade on this exchange"
        >
          <ControlGroup>
            <HTMLSelect name="network_id" value={formState.network_id} onChange={handleInputChange}>
              <option>Choose a blockchain...</option>
              {supportedNetworks.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </HTMLSelect>
          </ControlGroup>
        </FormGroup>
      </div>

      <FormGroup
        label={'Subgraph URL'}
        labelFor="subgraph_url"
        helperText="TheGraph endpoint for syncing instruments data of given exchange and blockchain to our system"
      >
        <InputGroup id="subgraph_url" name="subgraph_url" value={formState.subgraph_url} onChange={handleInputChange} autoComplete="off" />
      </FormGroup>

      {item && (
        <Button intent="primary" onClick={handleUpdate}>
          Update
        </Button>
      )}

      {!item && (
        <Button intent="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )}
    </>
  );
}
