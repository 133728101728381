import { Button, Classes, H5, Intent, Popover, PopoverInteractionKind, PopoverPosition } from '@blueprintjs/core';
import { Instrument } from '../../../shared/interfaces/bot';
import _ from 'lodash';
import { useContext } from 'react';
import { TimezoneContext } from '../../../contexts/timezone';
import { getTradingUrl } from '../utils';

interface Props {
  item: Instrument;
}

export function InstrumentDetailPopup({ item }: Props) {
  const { dateTimeFormater } = useContext(TimezoneContext);
  const supportedExchanges = ['binance', 'bybit'];

  const getExchangeUrl = (instrument: Instrument) => {
    return getTradingUrl(instrument);
  };

  const getContent = (instrument: Instrument) => {
    let contractType = null;
    if (supportedExchanges.includes(instrument.main_exchange)) {
      if (instrument.sub_exchange === 'futures' || instrument.sub_exchange === 'swap') {
        contractType = instrument.expiry_type || 'perpeptual';
      }
    }
    let subExchange = instrument.sub_exchange;
    if (supportedExchanges.includes(instrument.main_exchange) && instrument.contract_type === 'linear') {
      subExchange = 'T-Swap';
    }

    if (supportedExchanges.includes(instrument.main_exchange) && instrument.contract_type === 'inverse') {
      subExchange = 'C-Swap';
    }

    return `${instrument.symbol} ${_.upperFirst(item.main_exchange)} ${_.upperFirst(subExchange)} ${
      contractType ? '(' + _.upperFirst(contractType) + ')' : ''
    }`;
  };

  const formatDate = (date: string) => {
    const dateTime = new Date(date + 'Z');
    return dateTimeFormater.format(dateTime);
  };

  return (
    <Popover
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      position={PopoverPosition.BOTTOM}
      interactionKind={PopoverInteractionKind.HOVER}
      hoverOpenDelay={50}
      hoverCloseDelay={100}
    >
      <p className="font-semibold cursor-pointer underline">
        {_.upperFirst(item.main_exchange)} {_.upperFirst(item.sub_exchange)} ({item.symbol})
      </p>

      <div key="text">
        <H5>
          <a
            title="Click to open the market on exchange frontend"
            className="font-semibold text-sm"
            href={getExchangeUrl(item)}
            target="_blank"
            rel="noreferrer"
          >
            {getContent(item)}
          </a>
        </H5>

        <div className="mb-1m mt-1 flex justify-between">
          <span>ID:</span> <span className="font-bold">{item.id}</span>
        </div>

        <div className=" border-t my-2"></div>

        <div className="mb-1m mt-1 flex justify-between">
          <span>Tick Size:</span> <span className="font-bold">{item.tick_size}</span>
        </div>

        <div className="mb-1m mt-1 flex justify-between">
          <span>Lot Size:</span> <span className="font-bold">{item.lot_size}</span>
        </div>

        {item.updated_at && (
          <div className="mb-1m mt-1">
            <span>Last update:</span> <span className="font-bold">{formatDate(item.updated_at)}</span>
          </div>
        )}
      </div>
    </Popover>
  );
}
