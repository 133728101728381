import { FormEvent, useEffect, useMemo, useState } from 'react';
import { Button, ControlGroup, FormGroup, HTMLSelect, InputGroup, Radio, RadioGroup } from '@blueprintjs/core';
import axios from '../../../shared/custom-axios';

import {
  ContractType,
  CurrencyType,
  DexNetwork,
  DexNetworksListResponse,
  Exchange,
  ExchangeType,
  Instrument,
} from '../../../shared/interfaces/bot';
import { ExchangeSelect } from '../../common/exchange-select/exchange-select';

const CURRENCY_TYPES = [
  { id: CurrencyType.Standard, name: 'Coin/Token' },
  { id: CurrencyType.StableCoin, name: 'Stablecoin' },
  { id: CurrencyType.WrappedToken, name: 'Wrapped Token' },
  { id: CurrencyType.MultiplierToken, name: 'Multiplier Token' },
  { id: CurrencyType.NFT, name: 'NFT' },
];

interface InstrumentFormState {
  id?: number;
  name: string;
  symbol: string;
  exchange_type: 'cex' | 'dex';
  main_exchange: string;
  sub_exchange: string;
  margin_mode: string;
  base_currency: string;
  base_currency_type: string;
  base_currency_multiplier: string;
  backing_base_currency: string;
  quote_currency: string;
  quote_currency_type: string;
  quote_currency_multiplier: string;
  backing_quote_currency: string;
  volume_currency: string;
  contract_type: ContractType | undefined;
  contract_size: string;
  tick_size: string;
  lot_size: string;
  pair_address: string;
  token0_address: string;
  token0_decimals: string;
  token1_address: string;
  token1_decimals: string;
  dex_network_id: string;
}

const isLeveragedMarket = (subExchange: string) => {
  return ['margin', 'futures', 'swap'].indexOf(subExchange) > -1;
};

interface Props {
  item?: Instrument | null;
  onCreate?: (data: any) => void;
  onUpdate?: (instrumentId: number, data: any) => void;
}

export function InstrumentForm({ item, onCreate, onUpdate }: Props) {
  const initForm: InstrumentFormState = useMemo(() => {
    return {
      name: item?.name || '',
      symbol: item?.symbol || '',
      exchange_type: item?.exchange_type || 'cex',
      main_exchange: item?.main_exchange || '',
      sub_exchange: item?.sub_exchange || '',
      margin_mode: item?.margin_mode || 'cross',
      base_currency: item?.base_currency || '',
      base_currency_type: item?.base_currency_type || '',
      base_currency_multiplier: item?.base_currency_multiplier || '',
      backing_base_currency: item?.backing_base_currency || '',
      quote_currency: item?.quote_currency || '',
      quote_currency_type: item?.quote_currency_type || '',
      quote_currency_multiplier: item?.quote_currency_multiplier || '',
      backing_quote_currency: item?.backing_quote_currency || '',
      volume_currency: item?.volume_currency || '',
      is_base_currency_stablecoin: !!item?.backing_base_currency,
      is_quote_currency_stablecoin: !!item?.backing_quote_currency,
      contract_type: item?.contract_type,
      contract_size: item ? String(item.contract_size) : '',
      tick_size: item ? String(item.tick_size) : '',
      lot_size: item ? String(item?.lot_size) : '',
      pair_address: item?.pair_address || '',
      token0_address: item?.token0_address || '',
      token0_decimals: item ? String(item.token0_decimals) : '',
      token1_address: item ? String(item.token1_address) : '',
      token1_decimals: item ? String(item.token1_decimals) : '',
      dex_network_id: item ? String(item.dex_network_id) : '',
    };
  }, [item]);

  const [formState, setFormState] = useState(initForm);
  const [volumeCurrencyInfo, setVolumeCurrencyInfo] = useState('');
  const [supportedDexNetworks, setSupportedDexNetworks] = useState<DexNetwork[]>([]);

  const {
    exchange_type,
    main_exchange,
    sub_exchange,
    base_currency,
    quote_currency,
    base_currency_type,
    quote_currency_type,
    contract_type,
    contract_size,
  } = formState;

  const handleInputChange = (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.currentTarget;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCreate = () => {
    onCreate && onCreate(formState);
  };

  const handleUpdate = () => {
    if (!item) {
      return;
    }

    onUpdate && onUpdate(item.id, formState);
  };

  useEffect(() => {
    setFormState(initForm);
  }, [initForm]);

  useEffect(() => {
    axios.get<DexNetworksListResponse>(`/api/dex_networks?exchange=${main_exchange}`).then((response) => {
      const dexNetworks = response.data.data;
      setSupportedDexNetworks(dexNetworks);
    });
  }, [main_exchange]);

  useEffect(() => {
    if (['spot', 'margin', 'defi_swap'].includes(sub_exchange)) {
      setFormState((prevState) => ({
        ...prevState,
        volume_currency: base_currency,
      }));

      setVolumeCurrencyInfo('');

      return;
    }

    if (['futures', 'swap'].includes(sub_exchange)) {
      // Linear contract
      if (contract_type === ContractType.Linear && Number.parseFloat(contract_size) === 1) {
        setFormState((prevState) => ({
          ...prevState,
          volume_currency: base_currency,
        }));

        setVolumeCurrencyInfo('');

        return;
      }

      if (contract_type === ContractType.Linear && Number.parseFloat(contract_size) !== 1) {
        setFormState((prevState) => ({
          ...prevState,
          volume_currency: 'contracts',
        }));

        if (!contract_size) {
          setVolumeCurrencyInfo('Cannot be determined (Contract size is required)');

          return;
        }

        const contractSize = Number.parseFloat(contract_size);

        setVolumeCurrencyInfo(`1 contract = ${contractSize} ${base_currency}`);

        return;
      }

      // Inverse contract
      if (contract_type === ContractType.Inverse) {
        setFormState((prevState) => ({
          ...prevState,
          volume_currency: 'contracts',
        }));

        if (!contract_size) {
          setVolumeCurrencyInfo('Cannot be determined (Contract size is required)');

          return;
        }

        const contractSize = Number.parseFloat(contract_size);

        setVolumeCurrencyInfo(`1 contract = ${contractSize} ${quote_currency}`);
        return;
      }
    }

    if (['blur'].includes(main_exchange)) {
      setFormState((prevState) => ({
        ...prevState,
        volume_currency: base_currency,
        base_currency_type: CurrencyType.NFT,
        quote_currency_type: CurrencyType.NFT,
        quote_currency: 'ETH',
        backing_quote_currency: 'ETH',
        tick_size: '0.01',
        lot_size: '1',
      }));

      setVolumeCurrencyInfo('');

      return;
    }
  }, [base_currency, quote_currency, contract_size, sub_exchange, contract_type]);

  useEffect(() => {
    if (exchange_type === ExchangeType.DEX) {
      const symbol = base_currency && quote_currency ? `${base_currency}/${quote_currency}` : '';

      setFormState((prevState) => ({
        ...prevState,
        name: symbol,
        symbol: symbol,
      }));
    }
  }, [base_currency, quote_currency, exchange_type]);

  useEffect(() => {
    if ([CurrencyType.StableCoin, CurrencyType.WrappedToken].indexOf(base_currency_type as CurrencyType) > -1) {
      setFormState((prevState: InstrumentFormState) => {
        return {
          ...prevState,
          base_currency_multiplier: '1',
        };
      });
    }
  }, [base_currency_type]);

  useEffect(() => {
    if ([CurrencyType.StableCoin, CurrencyType.WrappedToken].indexOf(quote_currency_type as CurrencyType) > -1) {
      setFormState((prevState: InstrumentFormState) => {
        return {
          ...prevState,
          quote_currency_multiplier: '1',
        };
      });
    }
  }, [quote_currency_type]);

  useEffect(() => {
    if (main_exchange === 'blur') {
      setFormState((prevState: InstrumentFormState) => {
        return {
          ...prevState,
          backing_base_currency: base_currency,
        };
      });
    }
  }, [main_exchange, base_currency]);

  return (
    <>
      <FormGroup label="Exchange" labelInfo="(required)">
        <ExchangeSelect
          mainExchange={main_exchange}
          subExchange={sub_exchange}
          handleSelect={(item: Exchange) => {
            setFormState((prevState) => ({
              ...prevState,
              exchange_type: item.type,
              main_exchange: item.main_exchange,
              sub_exchange: item.sub_exchange,
            }));
          }}
        />
      </FormGroup>

      {exchange_type === ExchangeType.DEX && (
        <FormGroup
          label="Blockchain"
          labelFor="dex_network_id"
          labelInfo="(required)"
          helperText="Which blockchain for chosen exchange to trade on"
        >
          <ControlGroup>
            <HTMLSelect name="dex_network_id" value={formState.dex_network_id} onChange={handleInputChange}>
              <option>Choose a network...</option>
              {supportedDexNetworks.map(({ id, network }) => (
                <option value={id} key={id}>
                  {network?.name}
                </option>
              ))}
            </HTMLSelect>
          </ControlGroup>
        </FormGroup>
      )}

      {exchange_type === ExchangeType.CEX && isLeveragedMarket(formState.sub_exchange) && (
        <FormGroup label="" labelFor="name" labelInfo="(required)">
          <RadioGroup name="margin_mode" label="Margin mode" onChange={handleInputChange} selectedValue={formState.margin_mode}>
            <Radio label="Cross" value="cross" />
            <Radio label="Isolated" value="isolated" />
          </RadioGroup>
        </FormGroup>
      )}

      {exchange_type === ExchangeType.DEX && (
        <FormGroup
          label="Pair Address"
          labelFor="pair_address"
          labelInfo="(required)"
          helperText="Smart contract address for this symbol/pair"
        >
          <InputGroup id="pair_address" name="pair_address" value={formState.pair_address} onChange={handleInputChange} />
        </FormGroup>
      )}

      <FormGroup label="Instrument Name (Symbol)" labelFor="symbol" labelInfo="(required)">
        <InputGroup
          id="symbol"
          name="symbol"
          value={formState.symbol}
          onChange={handleInputChange}
          placeholder={exchange_type === ExchangeType.CEX ? 'E.g. BTCUSDT or XBTUSD' : ''}
          disabled={exchange_type === ExchangeType.DEX}
        />
      </FormGroup>

      <div className="grid grid-cols-6 gap-4">
        <div className="col-span-2">
          <FormGroup label="Base Currency">
            <HTMLSelect
              disabled={formState.main_exchange === 'blur'}
              className="mb-5"
              name="base_currency_type"
              value={formState.base_currency_type}
              onChange={handleInputChange}
            >
              <option>Choose an currency type...</option>
              {CURRENCY_TYPES.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </HTMLSelect>

            {formState.base_currency_type && (
              <ControlGroup>
                <InputGroup
                  id="base_currency"
                  name="base_currency"
                  value={formState.base_currency}
                  onChange={handleInputChange}
                  placeholder="E.g. BTC"
                />

                <InputGroup
                  id="backing_base_currency"
                  disabled={formState.main_exchange === 'blur'}
                  name="backing_base_currency"
                  value={formState.backing_base_currency}
                  onChange={handleInputChange}
                  placeholder="E.g. USD"
                />
              </ControlGroup>
            )}

            {formState.base_currency_type &&
              [CurrencyType.Standard, CurrencyType.NFT].indexOf(formState.base_currency_type as CurrencyType) === 0 && (
                <ControlGroup>
                  <InputGroup value="1" disabled={true} onChange={handleInputChange} placeholder="1" />

                  <InputGroup
                    id="base_currency_multiplier"
                    name="base_currency_multiplier"
                    value={formState.base_currency_multiplier}
                    disabled={[CurrencyType.StableCoin, CurrencyType.WrappedToken].indexOf(base_currency_type as CurrencyType) > -1}
                    onChange={handleInputChange}
                    placeholder="1"
                  />
                </ControlGroup>
              )}

            {exchange_type === ExchangeType.DEX && sub_exchange !== 'nft' && (
              <FormGroup
                label="Base currency address"
                labelFor="token0_address"
                labelInfo="(required)"
                helperText="Smart contract address (token address) for token base currency"
              >
                <InputGroup id="token0_address" name="token0_address" value={formState.token0_address} onChange={handleInputChange} />
              </FormGroup>
            )}

            {exchange_type === ExchangeType.DEX && sub_exchange !== 'nft' && (
              <FormGroup
                label="Base currency decimals"
                labelFor="token0_decimals"
                labelInfo="(required)"
                helperText="Number of decimals of base currency token"
              >
                <InputGroup id="token0_decimals" name="token0_decimals" value={formState.token0_decimals} onChange={handleInputChange} />
              </FormGroup>
            )}
          </FormGroup>
        </div>

        <div className="col-span-2">
          <FormGroup label="Quote Currency">
            <HTMLSelect
              disabled={formState.main_exchange === 'blur'}
              className="mb-5"
              name="quote_currency_type"
              value={formState.quote_currency_type}
              onChange={handleInputChange}
            >
              <option>Choose an currency type...</option>
              {CURRENCY_TYPES.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </HTMLSelect>

            {formState.quote_currency_type && (
              <ControlGroup>
                <InputGroup
                  id="quote_currency"
                  disabled={formState.main_exchange === 'blur'}
                  name="quote_currency"
                  value={formState.quote_currency}
                  onChange={handleInputChange}
                  placeholder="E.g. USDT"
                />

                <InputGroup
                  id="backing_quote_currency"
                  disabled={formState.main_exchange === 'blur'}
                  name="backing_quote_currency"
                  value={formState.backing_quote_currency}
                  onChange={handleInputChange}
                  placeholder="E.g. USD"
                />
              </ControlGroup>
            )}

            {formState.quote_currency_type && formState.quote_currency_type !== CurrencyType.Standard && (
              <ControlGroup>
                <InputGroup value="1" disabled={true} onChange={handleInputChange} placeholder="1" />

                <InputGroup
                  id="quote_currency_multiplier"
                  name="quote_currency_multiplier"
                  value={formState.quote_currency_multiplier}
                  disabled={
                    formState.main_exchange === 'blur' ||
                    [CurrencyType.StableCoin, CurrencyType.WrappedToken].indexOf(quote_currency_type as CurrencyType) > -1
                  }
                  onChange={handleInputChange}
                  placeholder="1"
                />
              </ControlGroup>
            )}

            {exchange_type === ExchangeType.DEX && sub_exchange !== 'nft' && (
              <FormGroup
                label="Quote currency address"
                labelFor="token1_address"
                labelInfo="(required)"
                helperText="Smart contract address (token address) for token quote currency"
              >
                <InputGroup id="token1_address" name="token1_address" value={formState.token1_address} onChange={handleInputChange} />
              </FormGroup>
            )}

            {exchange_type === ExchangeType.DEX && sub_exchange !== 'nft' && (
              <FormGroup
                label="Quote currency decimals"
                labelFor="token1_decimals"
                labelInfo="(required)"
                helperText="Number of decimals of quote currency token"
              >
                <InputGroup id="token1_decimals" name="token1_decimals" value={formState.token1_decimals} onChange={handleInputChange} />
              </FormGroup>
            )}
          </FormGroup>
        </div>
      </div>

      {['futures', 'swap'].includes(formState.sub_exchange) && (
        <FormGroup label="Contract Type" labelInfo="(required)">
          <RadioGroup name="contract_type" onChange={handleInputChange} selectedValue={formState.contract_type}>
            <Radio label="Linear" value={ContractType.Linear} />
            <Radio label="Inverse" value={ContractType.Inverse} />

            {/* TODO: Will support Quanto */}
            {/* <Radio label="Quanto" value={ContractType.Quanto} /> */}
          </RadioGroup>
        </FormGroup>
      )}

      {['futures', 'swap'].includes(formState.sub_exchange) && (
        <FormGroup label="Contract Size" labelFor="contract_size" labelInfo="(required)">
          <InputGroup id="contract_size" type="number" name="contract_size" value={formState.contract_size} onChange={handleInputChange} />
        </FormGroup>
      )}

      <FormGroup label="Volume Currency" helperText={volumeCurrencyInfo}>
        <InputGroup id="volume_currency" name="volume_currency" value={formState.volume_currency} placeholder="" disabled={true} />
      </FormGroup>

      {(exchange_type === ExchangeType.CEX || sub_exchange === 'nft') && (
        <FormGroup label="Tick Size" labelFor="tick_size" labelInfo="(required)" helperText="Minimum increment change for price">
          <InputGroup id="tick_size" name="tick_size" value={formState.tick_size} onChange={handleInputChange} />
        </FormGroup>
      )}

      {(exchange_type === ExchangeType.CEX || sub_exchange === 'nft') && (
        <FormGroup
          label="Lot Size"
          labelFor="lot_size"
          labelInfo="(required)"
          helperText="Minimum increment change for quantity/volume/amount"
        >
          <InputGroup id="lot_size" name="lot_size" value={formState.lot_size} onChange={handleInputChange} />
        </FormGroup>
      )}

      {!item && (
        <Button intent="primary" onClick={handleCreate}>
          Submit
        </Button>
      )}

      {item && (
        <Button intent="primary" onClick={handleUpdate}>
          Update
        </Button>
      )}
    </>
  );
}
