import { Helmet } from 'react-helmet';

import { usePageTitle } from './use-page-title';
import { APP_ENV } from '../../shared/configs';

export function PageHeader() {
  const [pageTitle] = usePageTitle('Loading');

  return (
    <Helmet>
      <meta charSet="utf-8" />

      {APP_ENV === 'production' && <link rel="icon" href="/favicon.svg" />}
      {APP_ENV === 'staging' && <link rel="icon" href="/favicon-staging.svg" />}
      {APP_ENV === 'development' && <link rel="icon" href="/favicon-development.svg" />}

      <title>{pageTitle}</title>
    </Helmet>
  );
}
