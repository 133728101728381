import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Classes, Dialog, HTMLSelect, Intent, Label, Menu, MenuDivider, MenuItem, Spinner } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import axios from '../../../shared/custom-axios';
import { Bot, MovingPairResponse, WickCatcher } from '../../../shared/interfaces/bot';
import { AppToaster } from '../../../shared/app-toaster';
import { BotsManagerContext } from '../../../contexts/bots-manager';

// TODO: Non-consistent function signature below
interface ItemActionsProps {
  item: WickCatcher;
  onSelectItem: (item: WickCatcher) => void;
  onEditItem: (item: WickCatcher) => void;
  onResumeItem: (itemId: number) => void;
  onCloneItem: (itemId: number) => void;
  onDeleteItem: (itemId: number) => void;
  onTransferItem: (itemId: number) => void;
  onCopyItem: (itemId: number) => void;
}

const ItemActions = ({
  item,
  onSelectItem,
  onEditItem,
  onResumeItem,
  onCloneItem,
  onDeleteItem,
  onTransferItem,
  onCopyItem,
}: ItemActionsProps) => (
  <Menu>
    <MenuItem text="View Details" icon="folder-open" onClick={() => onSelectItem(item)} />
    <MenuItem text="Edit" icon="annotation" onClick={() => onEditItem(item)} />
    <MenuItem text="Resume All" icon="play" onClick={() => onResumeItem(item.id)} />
    <MenuDivider />
    <MenuItem text="Duplicate" icon="duplicate" onClick={() => onCloneItem(item.id)} />
    <MenuItem text="Move To" icon="swap-horizontal" onClick={() => onTransferItem(item.id)} />
    <MenuItem text="Copy To" icon="clipboard" onClick={() => onCopyItem(item.id)} />
    <MenuDivider />
    <MenuItem intent="danger" text="Delete" icon="trash" onClick={() => onDeleteItem(item.id)} />
  </Menu>
);

enum MovePairType {
  TRANSFER = 'transfer',
  COPY_PASTE = 'copy?_paste',
}

interface Props {
  item: WickCatcher;
  openItem: (item: WickCatcher) => void;
  editItem: (item: WickCatcher) => void;
  resumeItem: (itemId: number) => Promise<any>;
  cloneItem: (itemId: number) => Promise<any>;
  deleteItem: (itemId: number) => Promise<any>;
  onClose: (needToRefresh?: boolean) => void;
}

export function WickCatcherAction({ item, openItem, editItem, resumeItem, cloneItem, deleteItem, onClose }: Props) {
  const navigate = useNavigate();
  const [openSelectBotPopup, setOpenSelectBotPopup] = useState<boolean>(false);
  const [selectedBot, setSelectedBot] = useState<Bot>();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [actionCompleted, setActionCompleted] = useState<any>(null);
  const [movePairType, setMovePairType] = useState<MovePairType>();
  const { bots } = useContext(BotsManagerContext);

  const handleDelete = (id: number) => {
    deleteItem(id);
    navigate(-1);
  };

  const beforeTransfer = () => {
    setOpenSelectBotPopup(true);
    setMovePairType(MovePairType.TRANSFER);
  };

  const beforeCopy = () => {
    setOpenSelectBotPopup(true);
    setMovePairType(MovePairType.COPY_PASTE);
  };

  const handleBotChange = (evt: any) => {
    const bot = bots.find((bot) => bot.id === Number(evt.target.value));
    setSelectedBot(bot);
  };

  const movePair = () => {
    setIsProcessing(true);
    if (movePairType === MovePairType.COPY_PASTE) {
      copyPastePair();
    } else {
      transferPair();
    }
  };

  const handleClosePopup = () => {
    setOpenSelectBotPopup(false);
    if (actionCompleted) {
      onClose(true);
    } else {
      onClose(false);
    }
  };

  const transferPair = () => {
    axios
      .post<MovingPairResponse>(`/api/wick_catchers/${item.id}/transfer`, { bot: selectedBot?.id })
      .then((res) => {
        setActionCompleted(res.data);
        setIsProcessing(false);
        const message = 'Transferred successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const copyPastePair = () => {
    axios
      .post<MovingPairResponse>(`/api/wick_catchers/${item.id}/copy`, { bot: selectedBot?.id })
      .then((res) => {
        setActionCompleted(res.data);
        setIsProcessing(false);
        const message = 'Copied successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  return (
    <div
      onDoubleClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Popover2
        content={
          <ItemActions
            item={item}
            onSelectItem={openItem}
            onEditItem={editItem}
            onResumeItem={resumeItem}
            onCloneItem={cloneItem}
            onDeleteItem={handleDelete}
            onTransferItem={beforeTransfer}
            onCopyItem={beforeCopy}
          />
        }
        placement={'bottom-start'}
      >
        <Button icon="more" minimal={true} />
      </Popover2>

      <Dialog
        title={movePairType === MovePairType.TRANSFER ? 'Transfer Wick Catcher' : 'Copy Wick Catcher'}
        isOpen={openSelectBotPopup}
        onClose={handleClosePopup}
        style={{ minWidth: '300px' }}
      >
        <div className={Classes.DIALOG_BODY}>
          {actionCompleted ? (
            <div>
              <p>
                This trading pair has been {movePairType === MovePairType.TRANSFER ? 'transferred' : 'copied'} to {selectedBot?.name} bot.{' '}
              </p>
              <p>
                <Link to={`/bots/${selectedBot?.app_id}/trading_pairs/${actionCompleted?.id}`}>
                  Click here to check it out on the destination
                </Link>
              </p>
            </div>
          ) : (
            <Label>
              Destination bot:
              <HTMLSelect name="bot" onChange={handleBotChange}>
                <option>Choose a bot</option>
                {bots
                  .filter((b) => b.id !== item.bot_id)
                  .map((bot) => (
                    <option value={bot.id} key={`bot_${bot.id}`}>
                      {bot.name}
                    </option>
                  ))}
              </HTMLSelect>
            </Label>
          )}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={handleClosePopup}>Close</Button>

            {isProcessing ? (
              <Button disabled={!selectedBot || actionCompleted} intent="primary" onClick={movePair}>
                {movePairType === MovePairType.TRANSFER ? 'Transferring...' : 'Copying...'}
                <Spinner className="button-spinner" size={10}></Spinner>
              </Button>
            ) : (
              <Button disabled={!selectedBot || actionCompleted} intent="primary" onClick={movePair}>
                {movePairType === MovePairType.TRANSFER ? 'Transfer' : 'Copy'}
              </Button>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
