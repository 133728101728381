import { Code, H6, Pre } from '@blueprintjs/core';

import { HedgeState, TradeFeedHedgeOrder, TradeFeedOrderFill, TradingPair } from '../../../../shared/interfaces/bot';

import { TradeFeedHedgeOrdersList } from '../../hedge-order/list/list';
import { TradeFeedHedgeOrderHedgeUpdatesList } from '../hedge-update/list/list';

interface Props {
  item: TradeFeedHedgeOrder | null;
  orderFill?: TradeFeedOrderFill;
  tradingPair: TradingPair;
}

export function TradeFeedHedgeOrderItem({ orderFill, item, tradingPair }: Props) {
  if (!item) {
    return <></>;
  }

  return (
    <>
      <div className="mt-8">
        {item.hedge_state === HedgeState.Failed && (
          <div className="mt-5">
            <H6>Failed reason</H6>
            <Pre className="whitespace-pre-wrap">{JSON.stringify(item.failed_reason)}</Pre>
            {item.non_retryable_reason && <p>Retry skipped. Reason: {<Code>{item.non_retryable_reason || 'not_supported_error'}</Code>}</p>}
          </div>
        )}
      </div>

      {item.hedge_updates.length > 0 && (
        <div className="mt-3">
          <H6>Hedge updates</H6>

          <div className="mt-3">
            <TradeFeedHedgeOrderHedgeUpdatesList items={item.hedge_updates} />
          </div>
        </div>
      )}

      {item.child_orders.length > 0 && (
        <div className="mt-5">
          <H6>Child orders</H6>

          <div className="mt-3">
            {item.child_orders.length > 0 && (
              <TradeFeedHedgeOrdersList items={item.child_orders} orderNumbering="index" tradingPair={tradingPair} />
            )}
          </div>
        </div>
      )}
    </>
  );
}
