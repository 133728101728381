import { AccountList } from '../../components/account/list/list';

export const AccountsIndexPage = (props: any) => {
  return (
    <>
      <h2 className="text-lg font-bold my-1">Accounts</h2>

      <AccountList />
    </>
  );
};
