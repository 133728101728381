import { Route, Routes } from 'react-router-dom';

import Navigation from '../../components/navigation/navigation';
import { Footer } from '../../components/footer/footer';
import { TradeFeedPage } from '../../pages/trade_feed/show';

export function TradeFeedRoutes() {
  return (
    <>
      <div className="App-header">
        <Navigation />
      </div>

      <div className="App-body h-full">
        <Routes>
          <Route index element={<TradeFeedPage />} />
        </Routes>
      </div>

      <div className="App-footer fixed z-100 bottom-0 left-0 right-0 text-sm">
        <Footer />
      </div>
    </>
  );
}
