import { Route, Routes } from 'react-router-dom';

import { Footer } from '../../components/footer/footer';
import Navigation from '../../components/navigation/navigation';
import { BotsIndexPage } from '../../pages/bots';
import { BotsShowPage } from '../../pages/bots/show';

export function BotsRoutes() {
  return (
    <>
      <div className="App-header">
        <Navigation />
      </div>

      <div className="App-body h-full">
        <Routes>
          <Route path=":appId" element={<BotsShowPage />} />
          <Route index element={<BotsIndexPage />} />
        </Routes>
      </div>

      <div className="App-footer fixed z-100 bottom-0 left-0 right-0 text-sm">
        <Footer />
      </div>
    </>
  );
}
