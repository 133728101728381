import { Socket } from 'phoenix';
import { WS_API_BASE_URL } from './shared/configs';

declare global {
  interface Window {
    userToken: string;
  }
}

let socket = new Socket(`${WS_API_BASE_URL}/socket`, { params: { token: window.userToken } });

socket.connect();

export default socket;
