import { useNavigate } from 'react-router-dom';

import { BinanceRateLimit, RateLimitItem, RateLimitUsageInfo, TradingPair } from '../../../shared/interfaces/bot';
import { TradingPairEditor } from '../editor/editor';
import { useContext, useEffect, useState } from 'react';
import socket from '../../../socket';
import orderService from '../../../shared/order-service';
import { matchPath } from 'react-router';
import internal from 'stream';
import { UnderContructionContext } from '../../../contexts/under-construction';
import { RateLimitContext } from '../../../contexts/rate-limit-info';

export enum RateLimitType {
  WEIGHT,
  ORDER,
}

interface Props {
  tradingPair: TradingPair;
}

export function RateLimitInfo({ tradingPair }: Props) {
  const [rateLimitInfo, setRateLimitInfo] = useState<string | null>(null);
  const { accountRateLimits } = useContext(RateLimitContext);
  const spotMarginMarkets = ['spot', 'margin'];
  const futureMarkets = ['swap', 'futures'];
  const tenSecondsInterval = 10;
  const minuteInterval = 60;
  const dayInterval = 86400;

  useEffect(() => {
    const primaryAccount = tradingPair?.primary_account;
    if (!primaryAccount) {
      return;
    }
    const rateLimitInfo = accountRateLimits.find((limit) => limit.account_id === primaryAccount.id);
    if (rateLimitInfo) {
      const instrument = tradingPair?.primary_instrument;
      if (!instrument) {
        return;
      }
      let weightLimit: string = '';
      let orderLimits: string[] = [];
      if (rateLimitInfo.spot_margin && instrument.main_exchange === 'binance' && spotMarginMarkets.indexOf(instrument.sub_exchange) >= 0) {
        weightLimit = buildRateLimitMessage(rateLimitInfo.spot_margin.weight_limit, RateLimitType.WEIGHT);
        orderLimits = rateLimitInfo.spot_margin.order_limits.map((rateLimit) => buildRateLimitMessage(rateLimit, RateLimitType.ORDER));
      } else if (
        rateLimitInfo.futures_linear &&
        instrument.main_exchange === 'binance' &&
        futureMarkets.indexOf(instrument.sub_exchange) >= 0 &&
        instrument.contract_type === 'linear'
      ) {
        weightLimit = buildRateLimitMessage(rateLimitInfo.futures_linear.weight_limit, RateLimitType.WEIGHT);
        orderLimits = rateLimitInfo.futures_linear.order_limits.map((rateLimit) => buildRateLimitMessage(rateLimit, RateLimitType.ORDER));
      } else if (
        rateLimitInfo.futures_inverse &&
        instrument.main_exchange === 'binance' &&
        futureMarkets.indexOf(instrument.sub_exchange) >= 0 &&
        instrument.contract_type === 'inverse'
      ) {
        weightLimit = buildRateLimitMessage(rateLimitInfo.futures_inverse.weight_limit, RateLimitType.WEIGHT);
        orderLimits = rateLimitInfo.futures_inverse.order_limits.map((rateLimit) => buildRateLimitMessage(rateLimit, RateLimitType.ORDER));
      }
      setRateLimitInfo(`(${weightLimit}, ${orderLimits.join(', ')})`);
    }
  }, [accountRateLimits]);

  const buildRateLimitMessage = (rateLimit: RateLimitUsageInfo, type: RateLimitType) => {
    if (!rateLimit) {
      return '';
    }
    const isUsedTooMuch = haveUsedTooMuchRateLimit(rateLimit);
    const prefix = getPrefix(type, rateLimit);
    if (isUsedTooMuch) {
      return `${prefix} <strong class="text-red-700">${rateLimit.used}</strong>/<strong>${rateLimit.limit}</strong>`;
    } else {
      return `${prefix} <strong>${rateLimit.used}</strong>/<strong>${rateLimit.limit}</strong>`;
    }
  };

  const haveUsedTooMuchRateLimit = (rateLimit: RateLimitUsageInfo) => {
    return rateLimit.used + rateLimit.buffer * 1.5 >= rateLimit.limit;
  };

  const getPrefix = (type: RateLimitType, rateLimit: RateLimitUsageInfo) => {
    switch (type) {
      case RateLimitType.WEIGHT:
        return 'W used:';
      case RateLimitType.ORDER:
        let prefix = 'O(10S) used:';
        if (rateLimit.interval === dayInterval) {
          prefix = 'O(D) used:';
        } else if (rateLimit.interval === minuteInterval) {
          prefix = 'O(M) used:';
        }
        return prefix;
    }
  };

  return <>{rateLimitInfo && <div className="bg-gray-100 text-xs text-black p-1" dangerouslySetInnerHTML={{ __html: rateLimitInfo }} />}</>;
}
